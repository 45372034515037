import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { User } from '../@types/user';
// @types
import { ActionMap, AuthState, JWTContextType } from '../@types/authentication';
// utils
import config from '../config';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Loading = 'LOADING',
  Login = 'LOGIN',
  Refresh = 'REFRESH',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  Error = 'ERROR',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: User | null;
    authToken: string | null;
    isLoading: boolean;
  };
  [Types.Loading]: undefined;
  [Types.Login]: {
    user: User | null;
    authToken: string;
  };
  [Types.Refresh]: {
    user: User | null;
  };
  [Types.Logout]: undefined;
  isLoading: boolean;
  [Types.Register]: {
    user: User | null;
  };
  [Types.Error]: {
    error: any;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isLoading: false,
  user: null,
  error: null,
  authToken: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        authToken: action.payload.authToken,
        isLoading: action.payload.isLoading,
      };
    case 'LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        isLoading: false,
        authToken: action.payload.authToken,
      };
    case 'REFRESH':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isLoading: false,
        authToken: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        isLoading: false,
      };

    case 'ERROR':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        error: action.payload.error,
        isLoading: false,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const authToken = window.localStorage.getItem('authToken');
        const departmentContext =
          window.localStorage.getItem('departmentContext');

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            authToken,
            isLoading: true,
          },
        });

        if (authToken && isValidToken(authToken)) {
          setSession(authToken);

          const response = await axios.get(`${config.api_url}/me`, {
            headers: {
              'x-department': departmentContext
                ? departmentContext.replace(/"/g, '')
                : '',
            },
          });
          const user = response.data;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
              authToken,
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              authToken: null,
              isLoading: false,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            authToken: null,
            isLoading: false,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string) => {
    try {
      dispatch({
        type: Types.Loading,
      });

      const response = await axios.post(`${config.api_url}/me/login`, {
        username,
        password,
      });

      const { authToken } = response.data;
      setSession(authToken);

      const meResponse = await axios.get(`${config.api_url}/me`);
      const user = meResponse.data;

      dispatch({
        type: Types.Login,
        payload: {
          user,
          authToken,
        },
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: Types.Error,
        payload: {
          error: err,
        },
      });
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  const refreshUser = async () => {
    try {
      const departmentContext =
        window.localStorage.getItem('departmentContext');

      const response = await axios.get(`${config.api_url}/me`, {
        headers: {
          'x-department': departmentContext
            ? departmentContext.replace(/"/g, '')
            : '',
        },
      });
      const user = response.data;

      dispatch({
        type: Types.Refresh,
        payload: {
          user,
        },
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: Types.Error,
        payload: {
          error: err,
        },
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        resetPassword,
        updateProfile,
        refreshUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
