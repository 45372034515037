import {
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Tab,
  TabList,
  Tabs,
  Typography,
} from '@mui/joy';
import { useState } from 'react';
// Slices
import { faAwardSimple } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { User } from '../../../../../@types/user';
import AgentsCertificationsCenterCertificates from './AgentsCertificationsCenterCertificates';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flex: '1 1 0px' }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

type AgentsCertificationsCenterModalProps = {
  open: boolean;
  onClose: (refreshRanks?: boolean) => void;
  agentFile?: User;
  certifications?: any;
  isLoading?: boolean;
  onRefresh?: () => void;
};

export default function AgentsCertificationsCenterModal({
  open,
  onClose,
  agentFile,
  certifications,
  isLoading,
  onRefresh,
}: AgentsCertificationsCenterModalProps) {
  const [selectedTab, setSelectedTab] = useState<string>('0');

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | number | boolean
  ) => {
    setSelectedTab(String(newValue));
  };

  const certificationsCenterTabs = [
    {
      tabTitle: 'Certifications',
      component: (
        <AgentsCertificationsCenterCertificates
          agentFile={agentFile}
          certifications={certifications}
          isLoading={isLoading}
          onRefresh={onRefresh}
        />
      ),
    },
    { tabTitle: 'Examens', component: <Stack /> },
  ];

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="lg"
        sx={{
          minWidth: '50%',
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        {/* {error && (
          <Alert variant="soft" color="danger">
            {error}
          </Alert>
        )} */}
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<FontAwesomeIcon icon={faAwardSimple} />}
        >
          Centre de certifications
        </Typography>
        <Divider />
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Tabs
            variant="plain"
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="certifications tabs"
          >
            <TabList>
              {certificationsCenterTabs.map((tab, tabIndex) => {
                return (
                  <Tab key={tabIndex} value={`${tabIndex}`}>
                    {tab.tabTitle}
                  </Tab>
                );
              })}
            </TabList>
          </Tabs>
          {certificationsCenterTabs.map((tab, tabIndex) => {
            return (
              <TabPanel
                value={selectedTab}
                index={`${tabIndex}`}
                key={tabIndex}
              >
                {tab.component}
              </TabPanel>
            );
          })}
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
