import { faArrowLeft, faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  FormControl,
  Input,
  Stack,
  Typography,
} from '@mui/joy';
import { useColorScheme } from '@mui/joy/styles';
import { linkAgentFile } from 'api/departments/agentFiles';
import useAuth from 'hooks/useAuth';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apply } from 'translations';
import { useLocalStorage } from 'usehooks-ts';
import ApplyHeader from './common/ApplyHeader';

/**
 * This template uses [`Inter`](https://fonts.google.com/specimen/Inter?query=inter) font.
 */
export default function LinkController() {
  const navigate = useNavigate();
  const { setMode } = useColorScheme();
  const { refreshUser } = useAuth();

  const [lang] = useLocalStorage<'fr' | 'en'>('lang', 'fr');

  useEffect(() => {
    setMode('dark');
  }, [setMode]);

  const [linkingCallsign, setLinkingCallsign] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  const handleLink = useCallback(async () => {
    try {
      setLoading(true);
      await linkAgentFile(linkingCallsign);
      await refreshUser();
      navigate('/app');
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  }, [linkingCallsign, refreshUser, navigate]);

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100dvh',
        width:
          'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
        maxWidth: '100%',
        px: 2,
      }}
    >
      <ApplyHeader />
      <Box
        component="main"
        sx={{
          my: 'auto',
          p: 5,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '100%',
          mx: 'auto',
          borderRadius: 'sm',
          backdropFilter: 'blur(10px)',
        }}
      >
        {error && <Alert color="danger">{error.message}</Alert>}
        <Stack spacing={2}>
          <Stack>
            <Typography level="h6">
              {apply[lang].enterCallSignToLink}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControl sx={{ flex: 1 }}>
              <Input
                placeholder={apply[lang].callsign}
                value={linkingCallsign}
                onChange={(e) => {
                  setLinkingCallsign(e.target.value.toUpperCase());
                }}
              />
            </FormControl>
            <Button
              variant="solid"
              onClick={handleLink}
              loading={loading}
              disabled={loading}
              startDecorator={<FontAwesomeIcon icon={faLink} />}
            >
              {apply[lang].btnActionLink}
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Box component="footer" sx={{ py: 3 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="center">
            <Button
              variant="plain"
              color="neutral"
              onClick={() => navigate(-1)}
              startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
            >
              {apply[lang].btnBack}
            </Button>
          </Stack>
          <Typography level="body3" textAlign="center">
            © FivePD MTL {new Date().getFullYear()}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}
