import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import * as React from 'react';

type VideoModalProps = {
  open: boolean;
  onClose: () => void;
  videoId: string;
};

export default function VideoModal({
  open,
  onClose,
  videoId,
}: VideoModalProps) {
  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
          sx={{ width: '75vw', height: '75vh', padding: 0, borderRadius: 0 }}
        >
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
