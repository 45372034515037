import {
  faAdd,
  faChevronRight,
  faHouse,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Breadcrumbs, Button, Link, Typography } from '@mui/joy';
import DepartmentsRanksCreateModal from 'pages/app/components/Rank/DepartmentsRanksCreateModal';
import DepartmentsRanksEditModal from 'pages/app/components/Rank/DepartmentsRanksEditModal';
import RanksTable from 'pages/app/components/Rank/RanksTable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FPRank } from '../../../../@types/department';
import { getDepartmentsRanks } from '../../../../reducers/slices/departmentsRanks';
import {
  RootState,
  useDispatch,
  useSelector,
} from '../../../../reducers/store';
import ColorSchemeToggle from '../../components/Main/ColorSchemeToggle';

export default function RanksList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ranksOrder, setRanksOrder] = useState<'asc' | 'desc'>('asc');
  const [ranksPage, setRanksPage] = useState(1);

  const { ranks, isLoading } = useSelector(
    (state: RootState) => state.departmentsRanks
  );

  const [selectedRank, setSelectedRank] = useState<FPRank | null>(null);
  const [editRankModalOpen, setEditRankModalOpen] = useState(false);
  const [createRankModalOpen, setCreateRankModalOpen] = useState(false);

  // Modal opening handlers
  const handleEditRankModalOpen = (rank: FPRank) => {
    setSelectedRank(rank);
    setEditRankModalOpen(true);
  };

  const handleEditRankModalClose = (refreshRanks?: boolean) => {
    setSelectedRank(null);
    setEditRankModalOpen(false);
    if (refreshRanks) dispatch(getDepartmentsRanks());
  };

  const handleCreateRankModalOpen = () => {
    setCreateRankModalOpen(true);
  };

  const handleCreateRankModalClose = (refreshRanks?: boolean) => {
    setCreateRankModalOpen(false);
    if (refreshRanks) dispatch(getDepartmentsRanks());
  };

  useEffect(() => {
    dispatch(getDepartmentsRanks());
  }, [dispatch]);

  const handleRowClick = (id: string) => {
    handleEditRankModalOpen(
      ranks?.find((rank) => rank.objectId === id) as FPRank
    );
  };

  return (
    <Box
      component="main"
      className="MainContent"
      sx={(theme) => ({
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
          sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100dvh',
        gap: 1,
        bgcolor: 'background.body',
      })}
    >
      <DepartmentsRanksEditModal
        open={editRankModalOpen}
        onClose={handleEditRankModalClose}
        rank={selectedRank}
        ranks={ranks}
      />
      <DepartmentsRanksCreateModal
        open={createRankModalOpen}
        onClose={handleCreateRankModalClose}
        ranks={ranks}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<FontAwesomeIcon icon={faChevronRight} />}
          sx={{
            '--Breadcrumbs-gap': '1rem',
            '--Icon-fontSize': '16px',
            fontWeight: 'lg',
            color: 'neutral.400',
            px: 0,
          }}
        >
          <Link
            underline="none"
            color="neutral"
            fontSize="inherit"
            onClick={() => navigate('/app')}
            aria-label="Home"
          >
            <FontAwesomeIcon icon={faHouse} />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            fontSize="inherit"
            onClick={() => navigate('/app')}
          >
            Tableau de bord
          </Link>
          <Typography fontSize="inherit" variant="soft" color="primary">
            Grades
          </Typography>
        </Breadcrumbs>
        <ColorSchemeToggle
          sx={{ ml: 'auto', display: { xs: 'none', md: 'inline-flex' } }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          my: 1,
          gap: 1,
          flexWrap: 'wrap',
          '& > *': {
            minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
            flexGrow: 1,
          },
        }}
      >
        <Typography level="h1" fontSize="xl4">
          Grades
        </Typography>
        <Box sx={{ flex: 999 }} />
        <Box sx={{ display: 'flex', gap: 1, '& > *': { flexGrow: 1 } }}>
          <Button
            variant="outlined"
            color="neutral"
            startDecorator={<FontAwesomeIcon icon={faAdd} />}
            onClick={handleCreateRankModalOpen}
            disabled={isLoading}
          >
            Créer un grade
          </Button>
        </Box>
      </Box>
      <RanksTable
        order={ranksOrder}
        setOrder={setRanksOrder}
        isLoading={isLoading}
        ranks={ranks || []}
        currentPage={ranksPage}
        setCurrentPage={setRanksPage}
        onRowClick={handleRowClick}
      />
    </Box>
  );
}
