import axios from 'axios';
// ----------------------------------------------------------------------

const axiosInstance = axios.create();

const departmentContext = window.localStorage.getItem('departmentContext');

if (departmentContext) {
  axiosInstance.defaults.headers.common['x-department'] =
    departmentContext.replace(/"/g, '');
}
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    return Promise.reject(
      (error.response &&
        error.response.data &&
        (error.response.data.error ||
          error.response.data.err ||
          error.response.data.message)) ||
        'Something went wrong'
    );
  }
);

export default axiosInstance;
