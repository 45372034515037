import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  List,
  ListItem,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import config from '../../../../config';
import axios from '../../../../utils/axios';
// Slices
import { faEdit } from '@fortawesome/pro-duotone-svg-icons';
import { faSave, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack } from '@mui/system';
import {
  FPDepartmentRessourceType,
  FPPermissionType,
  FPRank,
} from '../../../../@types/department';

type DepartmentsRanksEditModalProps = {
  open: boolean;
  onClose: (refreshRanks?: boolean) => void;
  rank: FPRank | null;
  ranks?: FPRank[] | null;
};

export default function DepartmentsRanksEditModal({
  open,
  onClose,
  rank,
  ranks,
}: DepartmentsRanksEditModalProps) {
  const [name, setName] = useState(rank?.name || '');
  const [shortCode, setShortCode] = useState(rank?.shortCode || '');
  const [weight, setWeight] = useState<number>(rank?.weight || 0);
  const [discordRoleId, setDiscordRoleId] = useState<string>(
    rank?.discordRoleId || ''
  );
  const [subordinates, setSubordinates] = useState(rank?.subordinates || []);
  const [permissions, setPermissions] = useState<FPPermissionType>(
    rank?.permissions || {}
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setName(rank?.name || '');
    setShortCode(rank?.shortCode || '');
    setWeight(rank?.weight || 0);
    setSubordinates(rank?.subordinates || []);
    setPermissions(rank?.permissions || {});
    setDiscordRoleId(rank?.discordRoleId || '');
  }, [rank]);

  const handleUpdateRank = useCallback(async () => {
    setIsUpdating(true);
    try {
      await axios.post(
        `${config.api_url}/departments/ranks/${rank?.objectId}`,
        {
          updates: {
            name,
            subordinates,
            shortCode,
            weight,
            permissions,
            discordRoleId,
          },
        }
      );
      setIsUpdating(false);
      onClose(true);
    } catch (error: any) {
      setIsUpdating(false);
      console.error(error);
      setError(error as string);
    }
  }, [
    rank,
    name,
    onClose,
    subordinates,
    shortCode,
    weight,
    permissions,
    discordRoleId,
  ]);

  const handleDeleteRank = useCallback(async () => {
    setIsUpdating(true);
    try {
      await axios.delete(
        `${config.api_url}/departments/ranks/${rank?.objectId}`
      );
      onClose(true);
      setIsUpdating(false);
    } catch (error: any) {
      setIsUpdating(false);
      console.error(error);
      setError(error as string);
    }
  }, [rank, onClose]);

  const handleChangeRankSubordinates = (rankId: string) => {
    if (subordinates.includes(rankId)) {
      setSubordinates((prev) => prev.filter((id) => id !== rankId));
    } else {
      setSubordinates((prev) => [...prev, rankId]);
    }
  };

  const handleChangeManagedResourcesKey = (
    ressourceKey: FPDepartmentRessourceType
  ) => {
    if (permissions[ressourceKey]) {
      console.log('delete');
      setPermissions((prev) => ({ ...prev, [ressourceKey]: undefined }));
    } else {
      setPermissions((prev) => ({
        ...prev,
        [ressourceKey]: 'cred',
      }));
    }
  };
  return rank ? (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="lg"
        sx={{
          minWidth: '50%',
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        {error && (
          <Alert variant="soft" color="danger">
            {error}
          </Alert>
        )}
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<FontAwesomeIcon icon={faEdit} />}
        >
          Modifier un grade
        </Typography>
        <Divider />
        <Stack spacing={3} sx={{ mt: 2, overflow: 'scroll' }}>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Nom du grade</FormLabel>
              <Input
                placeholder="Capitaine"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Symbole</FormLabel>
              <Input
                placeholder="CPT, SGT, LT, etc."
                value={shortCode}
                onChange={(e) => {
                  setShortCode(e.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Poids</FormLabel>
              <Input
                type="number"
                value={weight}
                onChange={(e) => {
                  setWeight(Number(e.target.value));
                }}
              />
              <FormHelperText>
                Le poids détermine l'ordre des grades dans la liste (plus le
                poids est élevé, plus le grade est haut).
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>ID du Rôle Discord</FormLabel>
              <Input
                value={discordRoleId}
                onChange={(e) => {
                  setDiscordRoleId(e.target.value);
                }}
              />
            </FormControl>
            <Box>
              <FormLabel>Ressources administrées</FormLabel>
              <FormHelperText>
                Ce grade peux gérer ces ressources administratives.
              </FormHelperText>
              <Box role="group" aria-labelledby="sandwich-ressources-group">
                <List size="sm">
                  {(
                    [
                      { name: 'files', label: 'Dossiers' },
                      { name: 'applications', label: 'Applications' },
                      { name: 'ranks', label: 'Grades' },
                      { name: 'certifications', label: 'Certifications' },
                      {
                        name: 'organizationalUnits',
                        label: 'Unités organisationnelles',
                      },
                    ] as { name: FPDepartmentRessourceType; label: string }[]
                  ).map(({ name, label }) => (
                    <ListItem key={name}>
                      <List size="sm">
                        <Checkbox
                          checked={!!permissions[name]}
                          onChange={() => handleChangeManagedResourcesKey(name)}
                          name={name}
                          label={label}
                        />
                        {permissions[name] &&
                          [
                            { code: 'c', label: 'Créer' },
                            { code: 'r', label: 'Lire' },
                            { code: 'e', label: 'Modifier' },
                            { code: 'd', label: 'Supprimer' },
                          ].map(({ code, label }) => (
                            <ListItem key={`${name}_${code}`}>
                              <Checkbox
                                checked={
                                  permissions[name]?.indexOf(code) !== -1
                                }
                                onChange={() =>
                                  setPermissions((prev) => ({
                                    ...prev,
                                    [name]:
                                      permissions[name]?.indexOf(code) !== -1
                                        ? permissions[name]?.replace(code, '')
                                        : permissions[name] + code,
                                  }))
                                }
                                label={label}
                              />
                            </ListItem>
                          ))}
                      </List>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
            {permissions['files'] && (
              <Box>
                <FormLabel>Grades subordonnés</FormLabel>
                <FormHelperText>
                  Ce grade a accès aux dossiers des agents qui détiennent ces
                  rangs.
                </FormHelperText>
                <Box role="group" aria-labelledby="sandwich-subordinates-group">
                  <List size="sm">
                    {ranks?.map((r) => {
                      return (
                        r.objectId !== rank.objectId && (
                          <ListItem key={r.objectId}>
                            <Checkbox
                              checked={subordinates.includes(r.objectId)}
                              onChange={() =>
                                handleChangeRankSubordinates(r.objectId)
                              }
                              name={r.objectId}
                              label={r.name}
                            />
                          </ListItem>
                        )
                      );
                    })}
                  </List>
                </Box>
              </Box>
            )}
          </Stack>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack
          direction="row"
          justifyContent="end"
          alignItems={'center'}
          spacing={2}
        >
          <Button
            color="neutral"
            variant="plain"
            onClick={() => onClose()}
            disabled={isUpdating}
          >
            Annuler
          </Button>
          <Button
            color="danger"
            onClick={handleDeleteRank}
            disabled={isUpdating}
            startDecorator={<FontAwesomeIcon icon={faTrash} />}
          >
            Supprimer ce grade
          </Button>
          <Button
            variant="solid"
            color="primary"
            loading={isUpdating}
            onClick={handleUpdateRank}
            startDecorator={<FontAwesomeIcon icon={faSave} />}
          >
            Enregistrer
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  ) : (
    <></>
  );
}
