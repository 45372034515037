import { useCallback, useEffect, useState } from 'react';
import config from '../../../../config';
import axios from '../../../../utils/axios';
// Slices
import { faAdd, faSave } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import { Stack } from '@mui/system';
import { getDepartmentsOrganizationalUnits } from 'reducers/slices/departmentsOrganizationalUnits';
import { dispatch, RootState, useSelector } from 'reducers/store';

type OrganizationalUnitCreateModalProps = {
  open: boolean;
  onClose: (refreshRanks?: boolean) => void;
};

export default function OrganizationalUnitCreateModal({
  open,
  onClose,
}: OrganizationalUnitCreateModalProps) {
  const [name, setName] = useState<string>('');
  const [pathPrefix, setPathPrefix] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { departmentsOrganizationalUnits, isLoading: ousLoading } = useSelector(
    (state: RootState) => state.departmentsOrganizationalUnits
  );

  useEffect(() => {
    dispatch(getDepartmentsOrganizationalUnits());
  }, []);

  const handleCreateRank = useCallback(async () => {
    setIsUpdating(true);
    try {
      await axios.post(`${config.api_url}/departments/organizationalUnits`, {
        params: { name, pathPrefix },
      });
      onClose(true);
    } catch (error) {
      console.error(error);
      setError(error as string);
    }
    setIsUpdating(false);
  }, [name, onClose, pathPrefix]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="lg"
        sx={{
          minWidth: '50%',
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        {error && (
          <Alert variant="soft" color="danger">
            {error}
          </Alert>
        )}
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<FontAwesomeIcon icon={faAdd} />}
        >
          Créer une unité organizationnelle
        </Typography>
        <Divider />
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Nom de l'unité organizationnelle</FormLabel>
              <Input
                placeholder="Nom complet"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Unité organizationelle parente</FormLabel>
              <Select
                placeholder="Sélectionner l'unité parente"
                slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                onChange={(e, value) => {
                  if (e && value) setPathPrefix(value);
                }}
                value={pathPrefix}
                disabled={ousLoading}
              >
                {departmentsOrganizationalUnits?.map((ou) => (
                  <Option
                    key={ou.id}
                    value={ou.path}
                    sx={{ pl: `${20 * ou.path.split('.').length}px` }}
                  >
                    {ou.name}
                  </Option>
                ))}
              </Select>
              <FormHelperText>
                Cette unité organizationelle sera créée sous l'unité
                sélectionnée ci-dessus. Les grades ayant accès à l'unité parente
                ont aussi accès aux unités enfants.
              </FormHelperText>
            </FormControl>
          </Stack>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack
          direction="row"
          justifyContent="end"
          alignItems={'center'}
          spacing={2}
        >
          <Button
            color="neutral"
            variant="plain"
            onClick={() => onClose()}
            disabled={isUpdating}
          >
            Annuler
          </Button>
          <Button
            variant="solid"
            color="primary"
            loading={isUpdating}
            onClick={handleCreateRank}
            startDecorator={<FontAwesomeIcon icon={faSave} />}
          >
            Enregistrer
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
