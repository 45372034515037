import {
  Alert,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import config from '../../../../config';
import axios from '../../../../utils/axios';
// Slices
import { faEdit } from '@fortawesome/pro-duotone-svg-icons';
import { faSave, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack } from '@mui/system';
import { FPOrganizationalNode } from '../../../../@types/department';

type OrganizationalUnitEditModalProps = {
  open: boolean;
  onClose: (refreshRanks?: boolean) => void;
  organizationalNode: FPOrganizationalNode | null;
};

export default function OrganizationalUnitEditModal({
  open,
  onClose,
  organizationalNode,
}: OrganizationalUnitEditModalProps) {
  const [name, setName] = useState(organizationalNode?.name || '');
  const [discordRoleId, setDiscordRoleId] = useState(
    organizationalNode?.discordRoleId || ''
  );
  const [callsignPrefix, setCallsignPrefix] = useState<string>('');
  const [callsignSuffix, setCallsignSuffix] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setName(organizationalNode?.name || '');
    setDiscordRoleId(organizationalNode?.discordRoleId || '');
    setCallsignPrefix(organizationalNode?.callsignPrefix || '');
    setCallsignSuffix(organizationalNode?.callsignSuffix || '');
  }, [organizationalNode]);

  const handleUpdateOrganizationalNode = useCallback(async () => {
    setIsUpdating(true);
    try {
      await axios.post(
        `${config.api_url}/departments/organizationalUnits/${organizationalNode?.path}`,
        {
          updates: { name, discordRoleId, callsignPrefix, callsignSuffix },
        }
      );
      setIsUpdating(false);
      onClose(true);
    } catch (error: any) {
      setIsUpdating(false);
      console.error(error);
      setError(error as string);
    }
  }, [
    organizationalNode,
    name,
    onClose,
    discordRoleId,
    callsignPrefix,
    callsignSuffix,
  ]);

  const handleDeleteOrganizationalNode = useCallback(async () => {
    setIsUpdating(true);
    try {
      await axios.delete(
        `${config.api_url}/departments/organizationalUnits/${organizationalNode?.path}`
      );
      onClose(true);
      setIsUpdating(false);
    } catch (error: any) {
      setIsUpdating(false);
      console.error(error);
      setError(error as string);
    }
  }, [organizationalNode, onClose]);

  return organizationalNode ? (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="lg"
        sx={{
          minWidth: '50%',
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        {error && (
          <Alert variant="soft" color="danger">
            {error}
          </Alert>
        )}
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<FontAwesomeIcon icon={faEdit} />}
        >
          Modifier une unité organizationnelle
        </Typography>
        <Divider />
        <Stack spacing={3} sx={{ mt: 2, overflow: 'scroll' }}>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Nom de l'unité organizationnelle</FormLabel>
              <Input
                placeholder="Nom complet"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid md={6}>
                <FormControl>
                  <FormLabel>ID Rôle Discord</FormLabel>
                  <Input
                    placeholder="ID Discord"
                    value={discordRoleId}
                    onChange={(e) => {
                      setDiscordRoleId(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid md={3}>
                <FormControl>
                  <FormLabel>Préfix de matricule</FormLabel>
                  <Input
                    placeholder="Préfixe"
                    value={callsignPrefix}
                    onChange={(e) => {
                      setCallsignPrefix(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid md={3}>
                <FormControl>
                  <FormLabel>Suffixe de matricule</FormLabel>
                  <Input
                    placeholder="Suffixe"
                    value={callsignSuffix}
                    onChange={(e) => {
                      setCallsignSuffix(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack
          direction="row"
          justifyContent="end"
          alignItems={'center'}
          spacing={2}
        >
          <Button
            color="neutral"
            variant="plain"
            onClick={() => onClose()}
            disabled={isUpdating}
          >
            Annuler
          </Button>
          <Button
            color="danger"
            onClick={handleDeleteOrganizationalNode}
            disabled={isUpdating}
            startDecorator={<FontAwesomeIcon icon={faTrash} />}
          >
            Supprimer cette unité
          </Button>
          <Button
            variant="solid"
            color="primary"
            loading={isUpdating}
            onClick={handleUpdateOrganizationalNode}
            startDecorator={<FontAwesomeIcon icon={faSave} />}
          >
            Enregistrer
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  ) : (
    <></>
  );
}
