import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack, Typography } from '@mui/joy';
import { useColorScheme, useTheme } from '@mui/joy/styles';
import { useMediaQuery } from '@mui/material';
import { departments } from 'data';
import DepartmentTile from 'pages/app/components/Apply/DepartmentTile';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apply } from 'translations';
import { useLocalStorage } from 'usehooks-ts';
import RulesView from './RulesView';
import ApplyHeader from './common/ApplyHeader';

export default function ApplyController() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [acceptedRules, setAcceptedRules] = useState(false);

  const [lang] = useLocalStorage<'fr' | 'en'>('lang', 'fr');

  const navigate = useNavigate();

  const { setMode } = useColorScheme();

  useEffect(() => {
    setMode('dark');
  }, [setMode]);

  if (!acceptedRules)
    return (
      <RulesView
        onAccept={() => setAcceptedRules(true)}
        onDecline={() => navigate(-1)}
      />
    );

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100dvh',
        width:
          'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
        maxWidth: '100%',
        px: 2,
      }}
    >
      <ApplyHeader />
      <Box
        component="main"
        sx={{
          my: 'auto',
          p: 5,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '100%',
          mx: 'auto',
          borderRadius: 'sm',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Stack spacing={2}>
          <Typography level="h5">{apply[lang].selectDptToApply}</Typography>
          <Stack sx={{ flex: '1 1 0px' }}>
            <Stack
              direction={smallScreen ? 'column' : 'row'}
              sx={{ width: '100%', flex: '1 1 0px' }}
            >
              {Object.keys(departments).map((departmentKey: string) => {
                return (
                  <DepartmentTile
                    department={
                      departments[departmentKey as keyof typeof departments]
                    }
                  />
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Box component="footer" sx={{ py: 3 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="center">
            <Button
              variant="plain"
              color="neutral"
              onClick={() => navigate(-1)}
              startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
            >
              {apply[lang].btnBack}
            </Button>
          </Stack>
          <Typography level="body3" textAlign="center">
            © FivePD MTL {new Date().getFullYear()}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}
