import AddIcon from '@mui/icons-material/Add';
import {
  Alert,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/joy';
import { useCallback, useState } from 'react';
import config from '../../../../config';
import axios from '../../../../utils/axios';
// Slices
import { Stack } from '@mui/system';
import { certificationTypeNameForType } from 'utils/strings';
import { CertificationType } from '../../../../@types/department';

type CertificationCreateModalProps = {
  open: boolean;
  onClose: (refreshRanks?: boolean) => void;
};

export default function CertificationCreateModal({
  open,
  onClose,
}: CertificationCreateModalProps) {
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [description, setDescription] = useState<string>('');
  const [certificationType, setCertificationType] =
    useState<CertificationType>('initial');
  const [isUpdating, setIsUpdating] = useState(false);

  const handleCreateCertificate = useCallback(async () => {
    setIsUpdating(true);
    try {
      await axios.post(
        `${config.api_url}/departments/ressources/certifications`,
        {
          params: { code, certificationType, description },
        }
      );
      onClose(true);
    } catch (error) {
      setError(error as string);
    }
    setIsUpdating(false);
  }, [code, onClose, certificationType, description]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="lg"
        sx={{
          minWidth: '50%',
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        {error && (
          <Alert variant="soft" color="danger">
            {error}
          </Alert>
        )}
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<AddIcon />}
        >
          Ajouter une certification
        </Typography>
        <Divider />
        <Stack spacing={1} sx={{ mt: 2 }}>
          <FormControl>
            <FormLabel>Type</FormLabel>
            <RadioGroup
              value={certificationType}
              onChange={(e) =>
                setCertificationType(e.target.value as CertificationType)
              }
              name="radio-buttons-group"
              sx={{ my: 1 }}
            >
              <Radio value="initial" label="Certificat initial" />
              <Radio value="renewal" label="Reconduction de certificat" />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>
              Code de {certificationTypeNameForType(certificationType)}
            </FormLabel>
            <Input
              placeholder="Code"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Description de {certificationTypeNameForType(certificationType)}
            </FormLabel>
            <Input
              placeholder={`Description de ${certificationTypeNameForType(
                certificationType
              )}`}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </FormControl>
        </Stack>
        <Stack
          spacing={2}
          direction="row"
          justifyContent="end"
          sx={{ paddingTop: '24px' }}
        >
          <Button
            color="danger"
            variant="soft"
            onClick={() => onClose()}
            disabled={isUpdating}
          >
            Annuler
          </Button>
          <Button
            variant="solid"
            color="primary"
            loading={isUpdating}
            onClick={handleCreateCertificate}
          >
            Enregistrer
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
