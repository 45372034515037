import AspectRatio, { AspectRatioProps } from '@mui/joy/AspectRatio';
import useAuth from 'hooks/useAuth';
import { useLocalStorage } from 'usehooks-ts';
import {
  ai,
  ipiq,
  mtq,
  rcmp,
  sq,
  us,
} from '../../../../assets/images/departments/logos';

export default function DeptLogo({ sx, ...props }: AspectRatioProps) {
  const { user } = useAuth();

  const [departmentContext] = useLocalStorage<string>('departmentContext', '');

  const departmentLogoForUser = (department: string) => {
    switch (department) {
      case 'sq':
        return sq;
      case 'mtq':
        return mtq;
      case 'rcmp':
        return rcmp;
      case 'ssi':
        return ipiq;
      case 'us':
        return us;
      case 'ai':
        return ai;
      case 'TLZQZVw6sZ':
        return sq;
      case 'UnRyhq5nba':
        return mtq;
      // case 'rcmp':
      //   return rcmp;
      case 'nnfVW1C8e9':
        return ipiq;
      case '8Abc5cDMdZ':
        return us;
      case 'C4CwnNiln6':
        return ai;
      default:
        return sq;
    }
  };

  return (
    <AspectRatio
      ratio="1"
      variant="plain"
      {...props}
      sx={[
        {
          width: 36,
          borderRadius: 'sm',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <div>
        <img
          src={departmentLogoForUser(
            departmentContext || user?.agentFile?.currentDepartment?.shortName
          )}
          style={{ width: '90%', height: '100%' }}
          alt="Department Logo"
        />
      </div>
    </AspectRatio>
  );
}
