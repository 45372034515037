import config from '../../config';
import axios from '../../utils/axios';

export async function fetchAgentFiles(
  page: number,
  limit: number,
  rankFilter?: string,
  ouFilter?: string,
  order: 'asc' | 'desc' = 'asc'
): Promise<{ total: number; list: any[] }> {
  const { data } = await axios.get(
    `${
      config.api_url
    }/departments/agents/files?page=${page}&limit=${limit}&rank=${
      rankFilter || ''
    }&ou=${ouFilter || ''}&orderBy=lastName&order=${order}`
  );
  return data;
}

export async function fetchAgentFile(fileId: string) {
  try {
    const { data } = await axios.get(
      `${config.api_url}/departments/agents/files/${fileId}`
    );
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function fetchAgentFileHistory(fileId: string) {
  try {
    const { data } = await axios.get(
      `${config.api_url}/departments/agents/files/${fileId}/history`
    );
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function fetchAgentFileCertifications(fileId: string) {
  const { data } = await axios.get(
    `${config.api_url}/departments/agents/files/${fileId}/certifications`
  );
  return data;
}

export async function fetchAgentFileAvailableCertifications(fileId: string) {
  const { data } = await axios.get(
    `${config.api_url}/departments/agents/files/${fileId}/availableCertifications`
  );
  return data;
}

export async function fetchAgentFileDutyLogs(fileId: string) {
  const { data } = await axios.get(
    `${config.api_url}/departments/agents/files/${fileId}/dutyLogs`
  );
  return data;
}

export async function linkAgentFile(callsign: string) {
  try {
    const { data } = await axios.post(
      `${config.api_url}/departments/agents/files/link`,
      { callsign }
    );
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
}
