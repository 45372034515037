import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';
import {
  faBadgeCheck,
  faChevronRight,
  faCircleXmark,
  faHouse,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardOverflow,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/joy';
import { fetchApplication } from 'api/departments/applications';
import AlertModal from 'pages/app/components/common/AlertModal';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  colorForApplicationStatus,
  iconForApplicationStatus,
  labelForApplicationFormTitle,
  labelForApplicationStatus,
} from 'utils/strings';
import ApplicationAcceptModal from './ApplicationAcceptModal';
import ApplicationDenyModal from './ApplicationDenyModal';
import ApplicationReopenModal from './ApplicationReopenModal';

export default function ApplicationViewer() {
  const navigate = useNavigate();
  const { applicationId } = useParams();

  const [application, setApplication] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>('');

  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [denyModalOpen, setDenyModalOpen] = useState(false);
  const [reopenModalOpen, setReopenModalOpen] = useState(false);

  const getApplication = useCallback(async () => {
    if (applicationId) {
      setIsLoading(true);
      try {
        const application = await fetchApplication(applicationId);
        setApplication(application);
      } catch (error: any) {
        setError(error.message);
      }
    }
    setIsLoading(false);
  }, [applicationId]);

  useEffect(() => {
    if (applicationId) {
      if (applicationId) getApplication();
    }
  }, [getApplication, applicationId]);

  return (
    <Box
      component="main"
      className="MainContent"
      sx={(theme) => ({
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
          sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100dvh',
        gap: 1,
        bgcolor: 'background.body',
      })}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<FontAwesomeIcon icon={faChevronRight} />}
              sx={{
                '--Breadcrumbs-gap': '1rem',
                '--Icon-fontSize': '16px',
                fontWeight: 'lg',
                color: 'neutral.400',
                px: 0,
              }}
            >
              <Link
                underline="none"
                color="neutral"
                fontSize="inherit"
                onClick={() => navigate('/app')}
                aria-label="Home"
              >
                <FontAwesomeIcon icon={faHouse} />
              </Link>
              <Link
                underline="hover"
                color="neutral"
                fontSize="inherit"
                onClick={() => navigate('/app')}
              >
                Tableau de bord
              </Link>
              <Link
                underline="hover"
                color="neutral"
                fontSize="inherit"
                onClick={() => navigate('/app/department/applications')}
              >
                Applications
              </Link>
              <Typography fontSize="inherit" variant="soft" color="primary">
                {application
                  ? `${application?.firstName} ${application?.lastName} - #${application?.ticketNumber}`
                  : 'Dossier'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 1,
              gap: 1,
              flexWrap: 'wrap',
              '& > *': {
                minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                flexGrow: 1,
              },
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Chip
                variant="soft"
                startDecorator={
                  <FontAwesomeIcon
                    icon={iconForApplicationStatus(application?.status)}
                  />
                }
                size="lg"
                color={colorForApplicationStatus(application?.status)}
              >
                {labelForApplicationStatus(application?.status)}
              </Chip>

              <Typography level="h1" fontSize="xl4">
                {application
                  ? `${application?.lastName}, ${application?.firstName} `
                  : 'Application'}
              </Typography>

              <Typography level="h3" sx={{ color: '#5e5e5e' }}>
                #{application?.ticketNumber}
              </Typography>
            </Stack>
            <Box sx={{ flex: 999 }} />
            <Box sx={{ display: 'flex', gap: 1, '& > *': { flexGrow: 1 } }}>
              <Stack
                direction="row"
                spacing={2}
                divider={<Divider orientation="vertical" />}
                alignItems="center"
              >
                <Stack></Stack>
              </Stack>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid xs={12} md={9} lg={10}>
              <Stack spacing={1}>
                <Card variant="outlined">
                  <CardOverflow
                    variant="soft"
                    sx={{
                      display: 'flex',
                      gap: 1.5,
                      py: 0.5,
                      px: 'var(--Card-padding)',
                      bgcolor: 'background.level1',
                      justifyContent: 'start',
                      mb: 2,
                    }}
                  >
                    <Typography level="body2">Application</Typography>
                  </CardOverflow>
                  <Stack spacing={2}>
                    <Stack spacing={1}>
                      {application &&
                        Object.keys(application?.form).map((key) => (
                          <Stack>
                            <Typography
                              level="body2"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {labelForApplicationFormTitle(key)}
                            </Typography>
                            <Typography level="body2">
                              {application?.form[key]}
                            </Typography>
                          </Stack>
                        ))}
                    </Stack>
                  </Stack>
                </Card>
                <Card variant="outlined">
                  <CardOverflow
                    variant="soft"
                    sx={{
                      display: 'flex',
                      gap: 1.5,
                      py: 0.5,
                      px: 'var(--Card-padding)',
                      bgcolor: 'background.level1',
                      justifyContent: 'start',
                      mb: 2,
                    }}
                  >
                    <Typography level="body2">
                      Archive des messages Discord
                    </Typography>
                  </CardOverflow>
                  <Stack spacing={2}>
                    <Stack spacing={1}>
                      {application &&
                        application?.messagesArchive
                          ?.reverse()
                          .map((message: any) => (
                            <Stack>
                              <Typography
                                level="body2"
                                sx={{ fontWeight: 'bold' }}
                              >
                                {message.author.username} -{' '}
                                {new Date(message.timestamp).toLocaleString()}
                              </Typography>
                              <Typography level="body2">
                                {message.content}
                              </Typography>
                            </Stack>
                          ))}
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Grid>
            <Grid xs={12} md={3} lg={2}>
              <Card variant="outlined">
                <CardOverflow
                  variant="soft"
                  sx={{
                    display: 'flex',
                    gap: 1.5,
                    py: 0.5,
                    px: 'var(--Card-padding)',
                    bgcolor: 'background.level1',
                    justifyContent: 'start',
                    mb: 2,
                  }}
                >
                  <Typography level="body2">Actions</Typography>
                </CardOverflow>
                <Stack spacing={2} divider={<Divider />}>
                  <Stack spacing={1}>
                    <Button
                      variant="solid"
                      color="success"
                      startDecorator={<FontAwesomeIcon icon={faBadgeCheck} />}
                      onClick={() => setAcceptModalOpen(true)}
                      disabled={application?.status !== 'pending'}
                    >
                      Accepter
                    </Button>
                    <Button
                      variant="solid"
                      color="danger"
                      startDecorator={<FontAwesomeIcon icon={faCircleXmark} />}
                      onClick={() => setDenyModalOpen(true)}
                      disabled={application?.status !== 'pending'}
                    >
                      Refuser
                    </Button>
                    <Button
                      variant="solid"
                      color="warning"
                      startDecorator={<FontAwesomeIcon icon={faRotateRight} />}
                      onClick={() => setReopenModalOpen(true)}
                      disabled={application?.status !== 'rejected'}
                    >
                      Ouvrir à nouveau
                    </Button>
                  </Stack>
                  <Button
                    variant="solid"
                    color="primary"
                    startDecorator={<FontAwesomeIcon icon={faDiscord} />}
                    onClick={() =>
                      window.open(
                        `https://discord.com/channels/775186000560390175/${application.ticketChannelId}`
                      )
                    }
                    disabled={application?.status !== 'pending'}
                  >
                    Ouvrir la discussion
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
          {/* Modals here */}
          <ApplicationAcceptModal
            open={acceptModalOpen}
            onClose={() => setAcceptModalOpen(false)}
            application={application}
          />
          <ApplicationDenyModal
            open={denyModalOpen}
            onClose={() => setDenyModalOpen(false)}
            application={application}
            onRefresh={(application) => {
              setApplication(application);
              setDenyModalOpen(false);
            }}
          />
          <ApplicationReopenModal
            open={reopenModalOpen}
            onClose={() => setReopenModalOpen(false)}
            application={application}
            onRefresh={(application) => {
              setApplication(application);
              setReopenModalOpen(false);
            }}
          />
          <AlertModal
            open={!!error}
            onClose={() => navigate(-1)}
            title="Erreur"
            message={error}
            closeButtonLabel="Retour"
          />
        </>
      )}
    </Box>
  );
}
