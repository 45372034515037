import { AnyAction, createSlice, ThunkDispatch } from '@reduxjs/toolkit';
import { FPAvailableCertification } from '../../@types/department';
// utils
import config from '../../config';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

type DepartmentsCertificationsState = {
  isLoading: boolean;
  error: boolean;
  certifications?: FPAvailableCertification[] | null;
};

const initialState: DepartmentsCertificationsState = {
  isLoading: false,
  error: false,
  certifications: null,
};

const slice = createSlice({
  name: 'certifications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // EMPTY PROFILE
    emptyDepartmentsCertifications(state) {
      state.isLoading = false;
      state.certifications = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getDepartmentsCertificationsSuccess(state, action) {
      state.isLoading = false;
      state.certifications = action.payload;
    },
  },
});

// Actions
// ----------------------------------------------------------------------

export function getDepartmentsCertifications() {
  return async (dispatch: ThunkDispatch<any, undefined, AnyAction>) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get(
        `${config.api_url}/departments/ressources/certifications`
      );
      dispatch(slice.actions.getDepartmentsCertificationsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function emptyDepartmentsCertifications() {
  return async (dispatch: ThunkDispatch<any, undefined, AnyAction>) => {
    dispatch(slice.actions.emptyDepartmentsCertifications());
  };
}

// Reducer
const reducer = slice.reducer;
console.log('Default export cert reducer', reducer);
export default reducer;
