import { Card, Grid, Typography } from '@mui/joy';

type AgentFileWeaponsGridProps = {
  agentFile: any;
};

export default function AgentFileWeaponsGrid({
  agentFile,
}: AgentFileWeaponsGridProps) {
  const weapons: any[] = agentFile?.assignedWeapons;

  return (
    <Grid container spacing={2}>
      {weapons && weapons.length > 0 ? (
        weapons?.map((weapon) => (
          <Grid xs={12} sm={6} md={4} lg={3} key={weapon.id}>
            <Card variant="soft">
              <Typography level="h6">{weapon.name}</Typography>
              <Typography level="body2">#{weapon.serialNumber}</Typography>
              <Typography level="body3">{weapon.assignedOn.iso}</Typography>
            </Card>
          </Grid>
        ))
      ) : (
        <Grid xs={12}>
          <Typography textAlign="center" sx={{ p: 1 }}>
            Aucune arme assignée
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
