import { faFile } from '@fortawesome/pro-duotone-svg-icons';
import {
  faArrowRight,
  faClock,
  faStar,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';
import { Button, CardOverflow, Divider, Sheet, Stack } from '@mui/joy';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import config from 'config';
import { capitalize } from 'lodash';
import {
  certificationTypeNameForType,
  labelForAgentField,
  labelForAgentFileStatus,
} from 'utils/strings';
import { FPFileHistory } from '../../../../@types/user';

type AgentFileHistoryCardProps = {
  history: FPFileHistory;
};

export default function AgentFileHistoryCard({
  history,
}: AgentFileHistoryCardProps) {
  const formattedDate = new Date(history.date.iso).toLocaleDateString('fr-CA', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
  switch (history.eventType) {
    case 'rankChange':
      return (
        <Card variant="plain" sx={{ borderRadius: '0px' }}>
          <CardOverflow
            variant="soft"
            sx={{
              display: 'flex',
              gap: 1.5,
              py: 0.5,
              px: 'var(--Card-padding)',
              bgcolor: 'background.level1',
              borderRadius: '0px',
            }}
          >
            <Typography
              level="body3"
              sx={{ fontWeight: 'md', color: 'text.secondary' }}
            >
              {sourceForHistory(history)}
            </Typography>
            <Divider orientation="vertical" />
            <Typography
              level="body3"
              sx={{ fontWeight: 'md', color: 'text.secondary' }}
            >
              {formattedDate}
            </Typography>
          </CardOverflow>
          <Divider />
          <Box sx={{ mt: '16px' }}>{contentForHistory(history)}</Box>
        </Card>
      );
    case 'warning':
      return (
        <Card variant="plain" sx={{ borderRadius: '0px' }}>
          <CardOverflow
            variant="soft"
            sx={{
              display: 'flex',
              gap: 1.5,
              py: 0.5,
              px: 'var(--Card-padding)',
              bgcolor: '#B31B13',
              borderRadius: '0px',
            }}
          >
            <Typography
              level="body3"
              sx={{ fontWeight: 'md', color: 'text.secondary' }}
            >
              {sourceForHistory(history)}
            </Typography>
            <Divider orientation="vertical" />
            <Typography
              level="body3"
              sx={{ fontWeight: 'md', color: 'text.secondary' }}
            >
              {formattedDate}
            </Typography>
          </CardOverflow>
          <Divider />
          <Box sx={{ mt: '16px' }}>{contentForHistory(history)}</Box>
        </Card>
      );
    case 'addedDutyTimeAfterCitizenIdLink':
      return (
        <Card
          variant="plain"
          sx={{ borderRadius: 0, border: '0.5px solid #ffffff47' }}
        >
          <CardOverflow
            variant="soft"
            sx={{
              display: 'flex',
              gap: 1.5,
              py: 0.5,
              px: 'var(--Card-padding)',
              bgcolor: '#0d6efd',
              borderRadius: '0px',
            }}
          >
            <Typography
              level="body3"
              sx={{ fontWeight: 'bold', color: '#fff' }}
            >
              {sourceForHistory(history)}
            </Typography>
            <Divider orientation="vertical" />
            <Typography level="body3" sx={{ fontWeight: 'md', color: '#fff' }}>
              {formattedDate}
            </Typography>
          </CardOverflow>
          <Divider />
          <Box sx={{ mt: '16px' }}>{contentForHistory(history)}</Box>
        </Card>
      );
    default:
      return (
        <Sheet sx={{ background: 'transparent', p: 1 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              level="body2"
              sx={{ fontWeight: 'lg', color: 'text.primary' }}
            >
              {sourceForHistory(history)}
            </Typography>
            <Typography
              level="body3"
              sx={{ fontWeight: 'md', color: 'text.secondary' }}
            >
              {formattedDate}
            </Typography>
          </Stack>
          <Box>{contentForHistory(history)}</Box>
        </Sheet>
      );
  }
}

const contentForHistory = (history: FPFileHistory) => {
  const { data } = history;

  switch (history.eventType) {
    case 'newCertification':
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography level="body2">
            {capitalize(
              certificationTypeNameForType(
                data?.certificateBase?.certificationType
              )
            )}{' '}
            <strong>{data?.certificateBase?.code}</strong> a été émis(e) par{' '}
            {data?.certificate?.issuedByAgentFile?.firstName}{' '}
            {data?.certificate?.issuedByAgentFile?.lastName} le{' '}
            {new Date(data?.certificate?.date?.iso).toLocaleDateString(
              'fr-CA',
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }
            )}
          </Typography>
          {data?.certificateBase?.certificationType === 'initial' && (
            <VerifiedTwoToneIcon sx={{ color: '#fff' }} />
          )}
          {data?.certificateBase?.certificationType === 'renewal' && (
            <BadgeTwoToneIcon sx={{ color: '#fff' }} />
          )}
        </Stack>
      );
    case 'note':
      return (
        <Stack spacing={1}>
          <Typography level="body2" style={{ whiteSpace: 'pre-line' }}>
            {data?.note}
          </Typography>
          {data?.files && (
            <Stack direction="row" spacing={1}>
              {data.files.map((file: any) => (
                <Button
                  size="sm"
                  variant="soft"
                  onClick={() => {
                    window.open(
                      `${config.api_url}/assets/uploadedFiles/${file?.objectId}`,
                      '_blank',
                      'noreferrer'
                    );
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <FontAwesomeIcon icon={faFile} />
                    <Typography level="body3" sx={{ color: '#fff' }}>
                      <strong>{file?.name}</strong>
                    </Typography>
                  </Stack>
                </Button>
              ))}
            </Stack>
          )}
        </Stack>
      );
    case 'fileUpdate':
      return (
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography level="body2">
              Ces champs ont été mis à jour:
            </Typography>
          </Stack>
          <Stack spacing={1} sx={{ pl: 2 }}>
            {data?.updates?.map((update: any) => (
              <Stack>
                <Typography level="body2" fontWeight="bold">
                  {labelForAgentField(update.field)}:
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography level="body2">
                    {update.field === 'status'
                      ? labelForAgentFileStatus(update.oldValue)
                      : update.oldValue}
                  </Typography>
                  <FontAwesomeIcon icon={faArrowRight} fontSize="14px" />
                  <Typography level="body2">
                    {update.field === 'status'
                      ? labelForAgentFileStatus(update.newValue)
                      : update.newValue}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Typography level="body3">
            <strong>Mis à jour par</strong> {data?.issuedByAgentFile?.firstName}{' '}
            {data?.issuedByAgentFile?.lastName}
          </Typography>
        </Stack>
      );
    case 'rankChange':
      return (
        <Stack direction="row" spacing={2} alignItems="start">
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ width: '24px', height: '24px' }}
          >
            <FontAwesomeIcon icon={faStar} />
          </Stack>
          <Stack spacing={2}>
            <Stack>
              <Typography level="body1">Nouveau grade</Typography>
              <Typography level="h6">{data?.newRank?.name}</Typography>
              <Typography level="body2">
                Précédemment <strong>{data?.oldRank?.name}</strong>
              </Typography>
            </Stack>
            <Typography level="body3">
              <strong>Promu par</strong> {data?.issuedByAgentFile?.firstName}{' '}
              {data?.issuedByAgentFile?.lastName}
            </Typography>
          </Stack>
        </Stack>
      );
    case 'warning':
      return (
        <Stack direction="row" spacing={2} alignItems="start">
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ width: '24px', height: '24px' }}
          >
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </Stack>
          <Stack spacing={2}>
            <Stack>
              <Typography level="body2" fontWeight="bold">
                Nouvel avertissement
              </Typography>
              <Typography level="body2">
                <strong>{data?.warning}</strong>
              </Typography>
            </Stack>
            {data?.files && (
              <Stack direction="row" spacing={1}>
                {data.files.map((file: any) => (
                  <Button
                    size="sm"
                    variant="soft"
                    onClick={() => {
                      window.open(
                        `${config.api_url}/assets/uploadedFiles/${file?.objectId}`,
                        '_blank',
                        'noreferrer'
                      );
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <FontAwesomeIcon icon={faFile} />
                      <Typography level="body3" sx={{ color: '#fff' }}>
                        <strong>{file?.name}</strong>
                      </Typography>
                    </Stack>
                  </Button>
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      );
    case 'addedDutyTimeAfterCitizenIdLink':
      return (
        <Stack direction="row" spacing={2} alignItems="start">
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ width: '24px', height: '24px' }}
          >
            <FontAwesomeIcon icon={faClock} />
          </Stack>
          <Stack spacing={2}>
            <Stack>
              <Typography level="body2" fontWeight="bold">
                Ajout de temps de service
              </Typography>
              <Typography level="body2">
                Après avoir lié son NAS, nous avons trouvé des entrées de temps
                de service pour cet agent.{' '}
                <strong>
                  {Math.round(
                    ((data?.addedTime || 0) / 60 + Number.EPSILON) * 100
                  ) / 100}{' '}
                </strong>
                heures ont été ajoutés.
              </Typography>
            </Stack>
            {data?.files && (
              <Stack direction="row" spacing={1}>
                {data.files.map((file: any) => (
                  <Button
                    size="sm"
                    variant="soft"
                    onClick={() => {
                      window.open(
                        `${config.api_url}/assets/uploadedFiles/${file?.objectId}`,
                        '_blank',
                        'noreferrer'
                      );
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <FontAwesomeIcon icon={faFile} />
                      <Typography level="body3" sx={{ color: '#fff' }}>
                        <strong>{file?.name}</strong>
                      </Typography>
                    </Stack>
                  </Button>
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      );
    default:
      return '';
  }
};

const sourceForHistory = (history: FPFileHistory) => {
  const { data } = history;

  switch (history.eventType) {
    case 'newCertification':
      return 'Système';
    case 'note':
      return `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`;
    case 'warning':
      return `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`;
    default:
      return 'Système';
  }
};
