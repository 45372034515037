import {
  faFolder,
  faUser,
  faUserNurse,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Chip, Stack, Typography } from '@mui/joy';
import { useColorScheme } from '@mui/joy/styles';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apply } from 'translations';
import { useLocalStorage } from 'usehooks-ts';
import ApplyHeader from '../Applications/common/ApplyHeader';

/**
 * This template uses [`Inter`](https://fonts.google.com/specimen/Inter?query=inter) font.
 */
export default function NoLinkController() {
  const { setMode } = useColorScheme();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [lang] = useLocalStorage<'fr' | 'en'>('lang', 'fr');

  useEffect(() => {
    setMode('dark');
  }, [setMode]);

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100dvh',
        width:
          'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
        maxWidth: '100%',
        px: 2,
      }}
    >
      <ApplyHeader />
      <Box
        component="main"
        sx={{
          my: 'auto',
          p: 5,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '100%',
          mx: 'auto',
          borderRadius: 'sm',
          backdropFilter: 'blur(10px)',
        }}
      >
        <div>
          <Typography level="h2">
            {apply[lang].welcome}{' '}
            <span style={{ color: 'rgb(223, 180, 51)', fontWeight: 'bold' }}>
              {user?.username}
            </span>
            !
          </Typography>
          <Typography level="h5">{apply[lang].firstConnection}</Typography>
          <Typography level="body1" sx={{ my: 1, mb: 3 }}>
            {apply[lang].howJoinSelectText}
          </Typography>
        </div>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <Button
              variant="soft"
              color="success"
              sx={{
                p: 2,
                width: 200,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => navigate('/app/nolink/link')}
            >
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <Stack spacing={1}>
                  <FontAwesomeIcon icon={faFolder} size="2x" />
                  <Typography level="h5" textAlign="center">
                    {apply[lang].btnActionLinkFile}
                  </Typography>
                </Stack>
                <Typography
                  level="body3"
                  textAlign="center"
                  sx={{ color: '#fff' }}
                >
                  {apply[lang].btnExplainLinkFile}
                </Typography>
              </Stack>
            </Button>
            <Button
              variant="soft"
              sx={{
                p: 2,
                width: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => navigate('/app/nolink/apply')}
            >
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <Stack spacing={1}>
                  <FontAwesomeIcon icon={faUserNurse} size="2x" />
                  <Typography level="h5" textAlign="center">
                    {apply[lang].btnActionApply}
                  </Typography>
                </Stack>
                <Typography
                  level="body3"
                  textAlign="center"
                  sx={{ color: '#fff' }}
                >
                  {apply[lang].btnExplainApply}
                </Typography>
              </Stack>
            </Button>
            <Button
              variant="plain"
              sx={{
                p: 2,
                width: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              color="neutral"
              disabled
            >
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <Chip color="neutral">Bientôt</Chip>
                <Stack spacing={1}>
                  <FontAwesomeIcon icon={faUser} size="2x" />
                  <Typography
                    level="h5"
                    textAlign="center"
                    sx={{ color: '#666' }}
                  >
                    {apply[lang].btnActionCivilian}
                  </Typography>
                </Stack>
                <Typography
                  level="body3"
                  textAlign="center"
                  sx={{ color: '#666' }}
                >
                  {apply[lang].btnExplainCivilian}
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Box component="footer" sx={{ py: 3 }}>
        <Typography level="body3" textAlign="center">
          © FivePD MTL {new Date().getFullYear()}
        </Typography>
      </Box>
    </Box>
  );
}
