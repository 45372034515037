import { faArrowRightFromBracket } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Divider, Stack, Typography } from '@mui/joy';
import useAuth from 'hooks/useAuth';
import { useLocalStorage } from 'usehooks-ts';
import logoBlack from '../../../../../assets/images/logos/fivepdmtl_black.svg';

export default function ApplyHeader() {
  const { logout } = useAuth();

  const [lang, setLang] = useLocalStorage<'fr' | 'en'>('lang', 'fr');

  const handleLangChange = () => {
    setLang(lang === 'fr' ? 'en' : 'fr');
  };

  return (
    <Box
      component="header"
      sx={{
        py: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        divider={<Divider orientation="vertical" />}
      >
        <Typography
          startDecorator={
            <img src={logoBlack} alt="FivePD MTL" width={30} height={30} />
          }
          fontWeight="lg"
        >
          FivePD MTL
        </Typography>
        <Button variant="soft" color="neutral" onClick={handleLangChange}>
          {lang === 'fr' ? 'English' : 'Français'}
        </Button>
      </Stack>
      <Box sx={{ pl: 1, mt: 'auto', display: 'flex', alignItems: 'center' }}>
        <Button
          variant="plain"
          sx={{ ml: 'auto' }}
          onClick={logout}
          endDecorator={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
        >
          {lang === 'fr' ? 'Se déconnecter' : 'Logout'}
        </Button>
      </Box>
    </Box>
  );
}
