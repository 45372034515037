import { Box } from '@mui/joy';
import { Outlet } from 'react-router-dom';
import Header from '../Main/Header';
import DepartmentSidebar from '../Main/Sidebars/DepartmentSidebar';

export default function DepartmentController() {
  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <Header />
      <DepartmentSidebar />
      <Outlet />
    </Box>
  );
}
