import {
  Alert,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useState } from 'react';
import config from '../../../../../config';
import axios from '../../../../../utils/axios';
// Slices
import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack } from '@mui/system';
import { fetchAgentFileAvailableCertifications } from 'api/departments/agentFiles';
import dayjs, { Dayjs } from 'dayjs';
import { User } from '../../../../../@types/user';
import { JoyDatePicker } from '../../common/JoyDatePicker';

type AgentFileCertificationAddModalProps = {
  open: boolean;
  onClose: (refreshCertificates?: boolean) => void;
  agentFile?: User;
};

export default function AgentFileCertificationAddModal({
  open,
  onClose,
  agentFile,
}: AgentFileCertificationAddModalProps) {
  const [certificationType, setCertificationType] = useState<string>('');
  const [certificationDate, setCertificationDate] = useState<Dayjs | null>(
    dayjs()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [
    agentFileAvailableCertifications,
    setAgentFileAvailableCertifications,
  ] = useState<any[]>([]);

  const handleAddCertification = useCallback(async () => {
    setIsUpdating(true);
    try {
      await axios.post(
        `${config.api_url}/departments/agents/files/${agentFile?.objectId}/certifications`,
        {
          certificationId: certificationType,
          date: certificationDate?.toISOString(),
        }
      );
      onClose(true);
    } catch (error) {
      console.error(error);
      setError(error as string);
    }
    setIsUpdating(false);
  }, [onClose, certificationType, agentFile, certificationDate]);

  const getAgentFileAvailableCertifications = useCallback(async () => {
    if (!agentFile) return;
    setIsLoading(true);
    const certifications = await fetchAgentFileAvailableCertifications(
      agentFile?.objectId
    );
    setAgentFileAvailableCertifications(certifications);
    setIsLoading(false);
  }, [agentFile]);

  useEffect(() => {
    if (agentFile) getAgentFileAvailableCertifications();
  }, [agentFile, getAgentFileAvailableCertifications]);

  // useEffect(() => {
  //   console.log(certificationDate?.toISOString());
  // }, [certificationDate]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="lg"
        sx={{
          minWidth: '50%',
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        {error && (
          <Alert variant="soft" color="danger">
            {error}
          </Alert>
        )}
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<FontAwesomeIcon icon={faAdd} />}
        >
          Ajouter une certification
        </Typography>
        <Divider />
        <Stack spacing={1} sx={{ mt: 2 }}>
          <FormControl>
            <FormLabel>Type</FormLabel>
            <Select
              placeholder="Sélectionner un type"
              slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
              onChange={(e, value) => {
                if (e && value) setCertificationType(value);
              }}
              value={certificationType}
              disabled={isLoading}
            >
              {agentFileAvailableCertifications?.map((certification) => (
                <Option
                  key={certification.objectId}
                  value={certification.objectId}
                >
                  <strong style={{ width: '70px' }}>
                    {certification.code}
                  </strong>{' '}
                  | {certification.description}
                </Option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Date du passage</FormLabel>
            <JoyDatePicker
              value={certificationDate}
              onChange={(e) => setCertificationDate(e)}
            />
          </FormControl>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="center"
          spacing={2}
        >
          <Button
            variant="soft"
            color="danger"
            onClick={() => onClose()}
            disabled={isUpdating}
          >
            Annuler
          </Button>
          <Button
            variant="solid"
            color="primary"
            loading={isUpdating}
            onClick={handleAddCertification}
          >
            Ajouter
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
