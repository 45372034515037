import { sign, verify } from 'jsonwebtoken';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (authToken: string) => {
  if (!authToken) {
    return false;
  }
  return true;
};

const setSession = (authToken: string | null) => {
  console.log('Setting session...: ', authToken);
  if (authToken) {
    localStorage.setItem('authToken', authToken);
    axios.defaults.headers.common['x-auth-token'] = authToken;
  } else {
    localStorage.removeItem('authToken');
    delete axios.defaults.headers.common['x-auth-token'];
  }
};

export { isValidToken, setSession, verify, sign };
