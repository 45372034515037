import { faDiscord, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack, Typography, useColorScheme } from '@mui/joy';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { home } from 'translations';
import { useLocalStorage } from 'usehooks-ts';
import 'video.js/dist/video-js.css';
import logo from '../assets/images/logos/fivepdmtl_black.svg';
import VideoModal from './app/components/common/VideoModal';

const Home = () => {
  const navigate = useNavigate();
  const [videoModalIdToView, setVideoModalIdToView] = useState<string>('');
  const { setMode } = useColorScheme();
  const { user } = useAuth();

  const [lang, setLang] = useLocalStorage<'fr' | 'en'>('lang', 'fr');

  const handleLangChange = () => {
    console.log('lang', lang);
    setLang(lang === 'fr' ? 'en' : 'fr');
  };

  useEffect(() => {
    setMode('dark');
  }, [setMode]);

  return (
    <div className="App-container-backdrop-home">
      <Stack
        sx={{
          position: 'fixed',
          top: '25px',
          left: 50,
          right: 50,
        }}
        spacing={1}
        direction={'row'}
        justifyContent={{ xs: 'center', md: 'start' }}
      >
        <Button variant="plain" onClick={handleLangChange}>
          {lang === 'fr' ? 'English' : 'Français'}
        </Button>
      </Stack>
      <VideoModal
        open={videoModalIdToView !== ''}
        onClose={() => setVideoModalIdToView('')}
        videoId={videoModalIdToView}
      />
      <Stack
        sx={{ width: '75%' }}
        spacing={4}
        alignItems={{ sx: 'center', md: 'start' }}
      >
        <Stack spacing={2} alignItems={{ sx: 'center', md: 'start' }}>
          <Typography level="h1">{home[lang].fivepd2IsHere}</Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Button
              color="danger"
              variant="solid"
              startDecorator={<FontAwesomeIcon icon={faYoutube} />}
              onClick={() => setVideoModalIdToView(home[lang].trailerId)}
            >
              {home[lang].watchTrailer}
            </Button>
          </Stack>
        </Stack>
        <Stack alignItems={{ sx: 'center', md: 'start' }}>
          <Typography level="h5" textAlign="left">
            {home[lang].homePresentationSubtitle}
          </Typography>
          <Typography level="body1" textAlign="left">
            {home[lang].homePresentationText}
          </Typography>
        </Stack>
        <Stack alignItems={{ sx: 'center', md: 'start' }} spacing={2}>
          <Stack>
            <Typography level="h5" textAlign="left">
              {home[lang].homeCTAApplyTag}
            </Typography>
            <Typography level="body1" textAlign="left">
              {home[lang].homeCTAApplyText}
            </Typography>
          </Stack>
          <Button
            color="neutral"
            variant="soft"
            endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
            onClick={() => navigate('/app')}
          >
            {user
              ? home[lang].homeCTAAlreadyLoggedIn
              : home[lang].homeCTAApplyButton}
          </Button>
        </Stack>
      </Stack>
      <Stack
        sx={{
          position: 'fixed',
          bottom: '25px',
          left: 50,
          right: 50,
        }}
        spacing={1}
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={'space-between'}
      >
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent={{ xs: 'center', md: 'start' }}
          alignItems={'center'}
          flex="0.33"
          display={{ xs: 'none', md: 'flex' }}
        >
          <img
            src={logo}
            className="App-logo"
            alt="logo"
            width="25px"
            height="25px"
          />
          <Typography level="body3" sx={{ color: '#fff' }}>
            FIVEPD MTL
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          justifyContent="center"
          alignItems={'center'}
          flex="0.33"
        >
          <Button
            variant="plain"
            startDecorator={<FontAwesomeIcon icon={faDiscord} />}
            onClick={() => window.open('https://discord.gg/fivepdmtl')}
          >
            {home[lang].joinUs}
          </Button>
        </Stack>
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent={{ xs: 'center', md: 'end' }}
          alignItems={'center'}
          flex="0.33"
        >
          <Typography level="body3" sx={{ color: '#fff' }}>
            © {new Date().getFullYear()} FivePD MTL, Tous droits réservés.
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
};

export default Home;
