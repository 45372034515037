import { Alert, CircularProgress, Sheet, Stack, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function AuthResult() {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const authToken = queryParams.get('tkn');
    if (!authToken)
      return setError('Could not retrieve token - Try logging in again');

    localStorage.setItem('authToken', authToken);
    navigate('/app');
  }, [location.search, navigate]);

  return (
    <Stack
      flex="1 1 0px"
      sx={{ background: '#ececec' }}
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Sheet variant="soft" sx={{ width: '25%', textAlign: 'center', p: 3 }}>
        {error ? (
          <Alert color="warning">{error}</Alert>
        ) : (
          <Stack spacing={2} justifyContent="center" alignContent="center">
            <Typography level="body1">Connexion en cours...</Typography>
            <Stack direction="row" justifyContent="center">
              <CircularProgress />
            </Stack>
          </Stack>
        )}
      </Sheet>
    </Stack>
  );
}
