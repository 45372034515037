import { Box, Stack, Typography } from '@mui/joy';
import { formLabelClasses } from '@mui/joy/FormLabel';
import { useColorScheme } from '@mui/joy/styles';
import { useEffect } from 'react';

/**
 * This template uses [`Inter`](https://fonts.google.com/specimen/Inter?query=inter) font.
 */
export default function CitizenHome() {
  const { setMode } = useColorScheme();

  useEffect(() => {
    setMode('dark');
  }, [setMode]);

  return (
    <Box
      sx={(theme) => ({
        flex: 1,
        transition: 'width var(--Transition-duration)',
        transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'background.body',
      })}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100dvh',
          px: 2,
        }}
      >
        <Box
          component="main"
          sx={{
            my: 'auto',
            py: 2,
            pb: 5,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            maxWidth: '100%',
            minWidth: '50%',
            mx: 'auto',
            borderRadius: 'sm',
            '& form': {
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            },
            [`& .${formLabelClasses.asterisk}`]: {
              visibility: 'hidden',
            },
          }}
        >
          <div>
            <Typography level="h3">Bienvenue!</Typography>
          </div>
          <Stack spacing={1}>
            <Typography level="body1">
              Cette section sera bientôt disponible. <br />
              En attendant, cliquez sur l'îcone "Département" dans le menu de
              gauche pour accéder aux fonctionnalités de votre département.
            </Typography>
            <Typography level="body1">
              Merci de faire partie de la communauté!
            </Typography>
            <Typography level="body2">L'équipe FivePD MTL</Typography>
          </Stack>
        </Box>
        <Box component="footer" sx={{ py: 3 }}>
          <Typography level="body3" textAlign="center">
            © FivePD MTL {new Date().getFullYear()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
