import { Alert, Button, Chip, Stack, Typography, useTheme } from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import { departments } from 'data';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apply } from 'translations';
import { useLocalStorage } from 'usehooks-ts';

const exampleDpt = departments['sq'];

type DepartmentTileProps = {
  department: typeof exampleDpt;
};

export default function DepartmentTile({ department }: DepartmentTileProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isHovering, setIsHovering] = useState(false);

  const [lang] = useLocalStorage<'fr' | 'en'>('lang', 'fr');

  return (
    <div
      className="department-tile"
      style={{
        backgroundImage: department.image
          ? `url("${department.image}")`
          : undefined,
        flex:
          department.acceptsApplications && isHovering
            ? '1.25 1 0px'
            : '1 1 0px',
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Stack
        justifyContent="space-between"
        sx={{
          background: department.acceptsApplications
            ? 'rgba(0, 0, 0, 0.5)'
            : 'rgba(0, 0, 0, 0.8)',
          ':hover': {
            background: department.acceptsApplications
              ? 'rgba(0, 0, 0, 0)'
              : 'rgba(0, 0, 0, 0.8)',
          },
          p: 3,
          height: { md: '50vh', sm: 'auto' },
          transition: '0.2s',
          color: '#fff',
        }}
        textAlign="left"
        direction={{ xs: 'column' }}
        spacing={2}
      >
        <Stack spacing={4}>
          <Stack direction="row" justifyContent="center">
            <Chip>
              {lang === 'en'
                ? `${department.minimumAge} years old +`
                : `${department.minimumAge} ans +`}
            </Chip>
          </Stack>
          <Stack alignItems="center" spacing={1}>
            <Typography
              level={smallScreen ? 'h6' : 'h2'}
              fontWeight="bold"
              lineHeight={1}
              textAlign="center"
            >
              {department.describer[lang]}
            </Typography>
            <Typography
              level={smallScreen ? 'body1' : 'h4'}
              lineHeight={1}
              textAlign="center"
            >
              {department.name[lang]}
            </Typography>
          </Stack>
        </Stack>

        {!department.acceptsApplications ? (
          <Alert color="warning">
            <Typography textAlign="center" width="100%" level={'body2'}>
              {apply[lang].applicationsTempClosed}
            </Typography>
          </Alert>
        ) : (
          <Button
            variant="solid"
            color="primary"
            sx={{
              // background: grey[900],
              // ':hover': { background: red[600] },
              py: smallScreen ? 0 : 1,
              px: smallScreen ? 4 : 0,
            }}
            onClick={(e) => {
              navigate(`/app/nolink/apply/${department.sign.toLowerCase()}`);
            }}
          >
            {lang === 'en' ? 'Apply' : 'Appliquer'}
          </Button>
        )}
      </Stack>
    </div>
  );
}
