import { combineReducers } from 'redux';
// reducers
import applicationReducer from './slices/application';
import applicationsReducer from './slices/applications';
import departmentReducer from './slices/department';
import departmentsReducer from './slices/departments';
import departmentsCertifications from './slices/departmentsCertifications';
import departmentsOrganizationalUnits from './slices/departmentsOrganizationalUnits';
import departmentsRanks from './slices/departmentsRanks';
import departmentsUsersReducer from './slices/departmentsUsers';
import departmentsVehicles from './slices/departmentsVehicles';
import ranksReducer from './slices/ranks';
import userReducer from './slices/user';

console.log('Imported cert reducer', departmentsCertifications);
console.log('Imported dOUs reducer', departmentsOrganizationalUnits);

const rootReducer = combineReducers({
  user: userReducer,
  applications: applicationsReducer,
  application: applicationReducer,
  departments: departmentsReducer,
  department: departmentReducer,
  departmentsUsers: departmentsUsersReducer,
  departmentsVehicles: departmentsVehicles,
  departmentsCertifications: departmentsCertifications,
  departmentsRanks: departmentsRanks,
  departmentsOrganizationalUnits: departmentsOrganizationalUnits,
  ranks: ranksReducer,
});

export default rootReducer;
