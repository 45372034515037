import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Alert, Button, Stack } from '@mui/joy';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import { reopenApplicationWithId } from 'api/departments/applications';
import { Fragment, useCallback, useState } from 'react';

type ApplicationReopenModalProps = {
  open: boolean;
  onClose: () => void;
  application: any;
  onRefresh: (application: any) => void;
};

export default function ApplicationReopenModal({
  open,
  onClose,
  application,
  onRefresh,
}: ApplicationReopenModalProps) {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleReopen = useCallback(async () => {
    try {
      setIsLoading(true);
      const refreshedApplication = await reopenApplicationWithId(
        application.objectId
      );
      setIsLoading(false);
      onRefresh(refreshedApplication);
    } catch (error: any) {
      setIsLoading(false);
      setError(error);
    }
  }, [application, onRefresh]);

  return (
    <Fragment>
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Typography
            id="alert-dialog-modal-title"
            component="h2"
            startDecorator={<WarningRoundedIcon />}
          >
            Ouvrir la candidature à nouveau
          </Typography>
          <Divider />
          <Stack spacing={2} mt={2} mb={2}>
            {error && <Alert color="danger">{JSON.stringify(error)}</Alert>}
            <Stack spacing={1}>
              <Typography level="body1">
                Voulez-vous vraiment ouvrir à nouveau la candidature de{' '}
                <strong>
                  {application.firstName} {application.lastName}
                </strong>{' '}
                ?
              </Typography>
              <Typography textColor="text.tertiary" level="body2">
                Le ticket sera ouvert à nouveau dans Discord, nous vous invitons
                à aller y indiquer pourquoi vous avez décidé d'ouvrir ce ticket
                à nouveau.
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
          >
            <Button variant="plain" color="danger" onClick={onClose}>
              Annuler
            </Button>
            <Button
              variant="solid"
              color="primary"
              onClick={handleReopen}
              loading={isLoading}
            >
              Confirmer la réouverture
            </Button>
          </Stack>
        </ModalDialog>
      </Modal>
    </Fragment>
  );
}
