import { faArrowRightFromBracket } from '@fortawesome/pro-duotone-svg-icons';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListSubheader from '@mui/joy/ListSubheader';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import useAuth from 'hooks/useAuth';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { closeSidebar } from '../../../utils';

export default function CitizenSidebar() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickLink = (path: string) => {
    closeSidebar();
    navigate(`/app/citizen/${path}`);
  };

  return (
    <React.Fragment>
      <Box
        className="SecondSidebar-overlay"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          bgcolor: 'background.body',
          opacity: 'calc(var(--SideNavigation-slideIn, 0) - 0.2)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Sheet
        className="SecondSidebar"
        sx={{
          position: {
            xs: 'fixed',
            lg: 'sticky',
          },
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'none',
          },
          borderRight: '1px solid',
          borderColor: 'divider',
          transition: 'transform 0.4s',
          height: '100dvh',
          top: 0,
          p: 2,
          py: 3,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <List
          sx={{
            '--ListItem-radius': '8px',
            '--ListItem-minHeight': '32px',
            '--List-gap': '4px',
          }}
        >
          <ListSubheader role="presentation" sx={{ color: 'text.primary' }}>
            Citoyen
          </ListSubheader>
          <ListItem>
            <ListItemButton
              selected={location.pathname.includes('/me')}
              variant={location.pathname.includes('/me') ? 'soft' : 'plain'}
              onClick={() => handleClickLink('me')}
            >
              <ListItemDecorator>
                <FontAwesomeIcon icon={faUser} />
              </ListItemDecorator>
              <ListItemContent>Moi</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
        <Box sx={{ pl: 1, mt: 'auto', display: 'flex', alignItems: 'center' }}>
          <div>
            <Typography fontWeight="lg" level="body2">
              {user?.username}
            </Typography>
            <Typography level="body2">
              {user?.agentFile
                ? `${user.agentFile.lastName}, ${user.agentFile.firstName}`
                : 'Aucun dossier attaché'}
            </Typography>
          </div>
          <IconButton variant="plain" sx={{ ml: 'auto' }} onClick={logout}>
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
          </IconButton>
        </Box>
      </Sheet>
    </React.Fragment>
  );
}
