import { Stack } from '@mui/material';
import React from 'react';

const ViewContainer = ({ children }: React.PropsWithChildren) => {
  return (
    <Stack style={{ flex: '1 1 0px', overflowY: 'scroll' }}>{children}</Stack>
  );
};

export default ViewContainer;
