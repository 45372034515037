import {
  faDiscord,
  faFacebook,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faArrowRightFromBracket } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, GlobalStyles, Stack, Typography } from '@mui/joy';
import { formLabelClasses } from '@mui/joy/FormLabel';
import { useColorScheme } from '@mui/joy/styles';
import { departments } from 'data';
import useAuth from 'hooks/useAuth';
import { capitalize } from 'lodash';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { apply } from 'translations';
import { useLocalStorage } from 'usehooks-ts';
import logoBlack from '../../../../assets/images/logos/fivepdmtl_black.svg';

type DepartmentPendingApplicationViewProps = {
  application: any;
};

export default function DepartmentPendingApplicationView({
  application,
}: DepartmentPendingApplicationViewProps) {
  const { setMode } = useColorScheme();
  const { logout } = useAuth();

  const [lang] = useLocalStorage<'fr' | 'en'>('lang', 'fr');

  const dptShortCode = application.department
    .shortName as keyof typeof departments;

  useEffect(() => {
    setMode('dark');
  }, [setMode]);

  return (
    <Box>
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '40vw', // must be `vw` only
            '--Form-maxWidth': '700px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          flex: 1,
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          backdropFilter: 'blur(4px)',
          backgroundColor: 'background.body',
        })}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              startDecorator={
                <img src={logoBlack} alt="FivePD MTL" width={30} height={30} />
              }
              fontWeight="lg"
            >
              FivePD MTL
            </Typography>
            <Box
              sx={{
                pl: 1,
                mt: 'auto',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button
                variant="plain"
                sx={{ ml: 'auto' }}
                onClick={logout}
                endDecorator={
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                }
              >
                Se déconnecter
              </Button>
            </Box>
          </Box>
          {application ? (
            <Box
              component="main"
              sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                },
                [`& .${formLabelClasses.asterisk}`]: {
                  visibility: 'hidden',
                },
              }}
            >
              <Stack spacing={3}>
                <div>
                  <Typography level="h4">
                    Application #{application.ticketNumber}
                  </Typography>
                  <Typography level="h6">
                    {capitalize(departments[dptShortCode].determiner[lang])}{' '}
                    {departments[dptShortCode].name[lang]}{' '}
                    {apply[lang].pendingAppCurrentlyEvaluating}
                  </Typography>
                  <Typography level="body3">
                    {apply[lang].pendingAppDelay}
                  </Typography>
                </div>
                <Stack spacing={1}>
                  <Typography level="body1">
                    {apply[lang].pendingAppWhileWaitDiscord}
                  </Typography>
                  <Stack direction="row" justifyContent="start">
                    <Button
                      onClick={() =>
                        window.open(
                          `https://discord.com/channels/775186000560390175/${application.ticketChannelId}`
                        )
                      }
                      startDecorator={<FontAwesomeIcon icon={faDiscord} />}
                    >
                      {apply[lang].pendingAppBtnDiscord}
                    </Button>
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <Typography level="body1">
                    {apply[lang].pendingAppFollowUsSocials}
                  </Typography>
                  <Stack direction="row" justifyContent="start">
                    <Button
                      color="neutral"
                      variant="plain"
                      onClick={() =>
                        window.open(`https://www.youtube.com/@FIVEPDMTL`)
                      }
                      startDecorator={<FontAwesomeIcon icon={faYoutube} />}
                    >
                      YouTube
                    </Button>
                    <Button
                      color="neutral"
                      variant="plain"
                      onClick={() =>
                        window.open(`https://www.facebook.com/FivePDMTL/`)
                      }
                      startDecorator={<FontAwesomeIcon icon={faFacebook} />}
                    >
                      Facebook
                    </Button>
                    <Button
                      color="neutral"
                      variant="plain"
                      onClick={() =>
                        window.open(`https://www.instagram.com/fivepdmtl`)
                      }
                      startDecorator={<FontAwesomeIcon icon={faInstagram} />}
                    >
                      Instagram
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          ) : (
            <Navigate to="/" />
          )}
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body3" textAlign="center">
              © FivePD MTL {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
