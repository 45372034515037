import { Card } from '@mui/joy';
import AgentFileCertificationsTable from './AgentsCertificates/AgentFileCertificationsTable';

type AgentFileCertificationsCardProps = {
  certifications?: any[];
  isLoading?: boolean;
};

export default function AgentFileCertificationsCard({
  certifications,
  isLoading,
}: AgentFileCertificationsCardProps) {
  return (
    <Card variant="plain">
      <AgentFileCertificationsTable
        certifications={certifications}
        isLoading={isLoading}
      />
    </Card>
  );
}
