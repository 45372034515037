import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Button, Stack } from '@mui/joy';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import * as React from 'react';

type AlertModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
  closeButtonLabel?: string;
};

export default function AlertModal({
  open,
  onClose,
  title,
  message,
  closeButtonLabel,
}: AlertModalProps) {
  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Typography
            id="alert-dialog-modal-title"
            component="h2"
            startDecorator={<WarningRoundedIcon />}
          >
            {title}
          </Typography>
          <Divider />
          <Typography
            id="alert-dialog-modal-description"
            textColor="text.tertiary"
          >
            {message}
          </Typography>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
          >
            <Button variant="soft" color="danger" onClick={onClose}>
              {closeButtonLabel || 'Ok'}
            </Button>
          </Stack>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
