/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faArrowDown,
  faFilter,
  faMagnifyingGlass,
  faRotate,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, CircularProgress, Stack, Tooltip } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Link from '@mui/joy/Link';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { colorForAgentFileStatus, iconForAgentFileStatus } from 'utils/strings';
import { FPRank } from '../../../../@types/department';
import TableFooter from '../common/TableFooter';

type Order = 'asc' | 'desc';

type AgentFilesTableProps = {
  order: Order;
  setOrder: (order: Order) => void;
  agentFiles: any[];
  isLoading: boolean;
  ranks?: FPRank[] | null;
  departmentsOrganizationalUnits?: any[] | null;
  currentPage: number;
  setCurrentPage: (cb: (oldValue: any) => number) => void;
  agentRankFilterId?: string;
  setAgentRankFilterId: (id: string) => void;
  ouFilterId?: string;
  setOuFilterId: (id: string) => void;
  onRowClick: (id: string) => void;
  rowsPerPage: number;
  total: number;
};

export default function AgentFilesTable({
  order,
  setOrder,
  agentFiles,
  isLoading,
  ranks,
  departmentsOrganizationalUnits,
  currentPage,
  setCurrentPage,
  agentRankFilterId,
  setAgentRankFilterId,
  ouFilterId,
  setOuFilterId,
  onRowClick,
  rowsPerPage,
  total,
}: AgentFilesTableProps) {
  const handleNextPage = () => {
    setCurrentPage((page) => page + 1);
  };
  const handlePreviousPage = () => {
    setCurrentPage((page) => page - 1);
  };

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Grade</FormLabel>
        <Select
          placeholder="Filtrer par grade"
          slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
          onChange={(e, value) => {
            if (e && value) setAgentRankFilterId(value);
          }}
          value={agentRankFilterId}
        >
          {ranks?.map((rank) => (
            <Option key={rank.objectId} value={rank.objectId}>
              {rank.name}
            </Option>
          ))}
        </Select>
      </FormControl>

      <FormControl size="sm">
        <FormLabel>Unité organisationnelle</FormLabel>
        <Select
          placeholder="Filtrer par unité organisationnelle"
          onChange={(e, value) => {
            if (e && value) setOuFilterId(value);
          }}
          value={ouFilterId}
        >
          {departmentsOrganizationalUnits?.map((ou) => (
            <Option key={ou.id} value={ou.path}>
              {ou.name}
            </Option>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: {
            xs: 'flex',
            sm: 'none',
          },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setFiltersOpen(true)}
        >
          <FontAwesomeIcon icon={faFilter} />
        </IconButton>
        <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setFiltersOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: {
            xs: 'none',
            sm: 'flex',
          },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '120px',
              md: '160px',
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Rechercher un agent</FormLabel>
          <Input
            placeholder="Rechercher"
            startDecorator={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          />
        </FormControl>

        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'md',
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
            <CircularProgress />
          </Stack>
        ) : (
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': (theme) =>
                theme.vars.palette.background.level1,
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': (theme) =>
                theme.vars.palette.background.level1,
            }}
          >
            <thead>
              <tr>
                <th style={{ width: '75px', padding: 12, textAlign: 'center' }}>
                  Statut
                </th>
                <th style={{ padding: 12 }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                    fontWeight="lg"
                    endDecorator={<FontAwesomeIcon icon={faArrowDown} />}
                    sx={{
                      '& svg': {
                        transition: '0.2s',
                        transform:
                          order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                      },
                    }}
                  >
                    Nom
                  </Link>
                </th>
                <th style={{ width: 120, padding: 12 }}>Matricule</th>
                <th style={{ padding: 12 }}>Poste</th>
                <th style={{ padding: 12 }}>Unité org.</th>
              </tr>
            </thead>

            <tbody>
              {agentFiles.map((file) => (
                <tr
                  key={file.objectId}
                  onClick={() => onRowClick(file.objectId)}
                  style={{ cursor: 'pointer' }}
                >
                  <td style={{ textAlign: 'center' }}>
                    <Chip
                      variant="soft"
                      size="md"
                      color={colorForAgentFileStatus(file?.status)}
                    >
                      <FontAwesomeIcon
                        icon={iconForAgentFileStatus(file.status)}
                      />
                    </Chip>
                  </td>
                  <td style={{ paddingLeft: '12px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <Typography
                          fontWeight="lg"
                          level="body3"
                          textColor="text.primary"
                        >
                          <strong>{file.lastName}</strong>, {file.firstName}
                        </Typography>
                        <Typography level="body3">
                          {file.currentRank?.name || 'Rang inexistant'}
                        </Typography>
                      </div>
                      {file.synced && (
                        <Tooltip title="Ce dossier est lié à un compte Discord">
                          <FontAwesomeIcon icon={faRotate} />
                        </Tooltip>
                      )}
                    </Box>
                  </td>
                  <td>
                    <Typography fontWeight="md">{file.callSign}</Typography>
                  </td>
                  <td>
                    {file.position || (
                      <span style={{ color: '#666' }}>
                        {file.currentRank?.name || 'Rang inexistant'}
                      </span>
                    )}
                  </td>
                  <td>{file.organizationalNodeName || '--'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Sheet>
      <TableFooter
        currentPage={currentPage}
        total={total}
        rowsPerPage={rowsPerPage}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        loading={isLoading}
      />
    </React.Fragment>
  );
}
