import { faTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
  Typography,
} from '@mui/joy';

type PickDeptContextModalProps = {
  open: boolean;
  currentContext: string;
  onChangeContext: (id: string) => void;
  onClose: () => void;
};

export default function PickDeptContextModal({
  open,
  currentContext,
  onChangeContext,
  onClose,
}: PickDeptContextModalProps) {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose()}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="md"
        sx={{
          minWidth: '25%',
          maxWidth: '35%',
        }}
      >
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<FontAwesomeIcon icon={faTriangleExclamation} />}
        >
          Changement de contexte admin
        </Typography>
        <Divider />
        <Stack spacing={1} sx={{ mt: 2 }}>
          <FormControl>
            <FormLabel>Nouveau contexte</FormLabel>
            <Select
              value={currentContext}
              onChange={(e, value) => {
                if (e && value) onChangeContext(value);
              }}
              sx={{ width: '100%' }}
            >
              <Option value="TLZQZVw6sZ">Sûreté du Québec</Option>
              <Option value="C4CwnNiln6">Staff FivePD MTL</Option>
              <Option value="8Abc5cDMdZ">Services préhospitaliers</Option>
              <Option value="UnRyhq5nba">
                Ministère des Transports du Québec
              </Option>
              <Option value="nnfVW1C8e9">Service de Sécurité Incendie</Option>
            </Select>
          </FormControl>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="center"
          spacing={2}
        >
          <Button variant="soft" color="danger" onClick={() => onClose()}>
            Annuler
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
