import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  iconButtonClasses,
} from '@mui/joy';

type TableFooterProps = {
  currentPage: number;
  rowsPerPage: number;
  total: number;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  loading?: boolean;
};

export default function TableFooter({
  currentPage,
  rowsPerPage,
  total,
  handleNextPage,
  handlePreviousPage,
  loading,
}: TableFooterProps) {
  return (
    <>
      <Box
        className="Pagination-mobile"
        sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}
      >
        <IconButton
          aria-label="previous page"
          variant="outlined"
          color="neutral"
          size="sm"
          onClick={handlePreviousPage}
          disabled={currentPage <= 1 || loading}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </IconButton>
        {loading ? (
          <LinearProgress />
        ) : (
          <Stack>
            <Typography level="body2" mx="auto">
              Page {currentPage} sur {Math.ceil(total / rowsPerPage)}
            </Typography>
            <Typography level="body4" mx="auto">
              {rowsPerPage} lignes par page
            </Typography>
          </Stack>
        )}
        <IconButton
          aria-label="next page"
          variant="outlined"
          color="neutral"
          size="sm"
          onClick={handleNextPage}
          disabled={currentPage >= Math.ceil(total / rowsPerPage) || loading}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </IconButton>
      </Box>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 4,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="plain"
          color="neutral"
          startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={handlePreviousPage}
          disabled={currentPage <= 1 || loading}
        >
          Précédent
        </Button>

        <Box sx={{ flex: 1 }} />
        {loading ? (
          <LinearProgress color="neutral" variant="plain" />
        ) : (
          <Stack>
            <Typography level="body2" mx="auto">
              Page {currentPage} sur {Math.ceil(total / rowsPerPage)}
            </Typography>
            <Typography level="body4" mx="auto">
              {rowsPerPage} lignes par page
            </Typography>
          </Stack>
        )}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="plain"
          color="neutral"
          endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
          onClick={handleNextPage}
          disabled={currentPage >= Math.ceil(total / rowsPerPage) || loading}
        >
          Suivant
        </Button>
      </Box>
    </>
  );
}
