import { Box } from '@mui/joy';
import { Outlet } from 'react-router-dom';
import Header from '../Main/Header';
import CitizenSidebar from '../Main/Sidebars/CitizenSidebar';

export default function CitizenController() {
  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <Header />
      <CitizenSidebar />
      <Outlet />
    </Box>
  );
}
