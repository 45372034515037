import { faBadgeCheck } from '@fortawesome/pro-duotone-svg-icons';
import {
  faChevronRight,
  faExclamationTriangle,
  faFileCertificate,
  faGun,
  faHouse,
  faStickyNote,
  faSync,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Edit, MoreVert } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Card,
  CardOverflow,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuItem,
  Stack,
  Tab,
  TabList,
  Tabs,
  Typography,
} from '@mui/joy';
import {
  fetchAgentFile,
  fetchAgentFileCertifications,
} from 'api/departments/agentFiles';
import config from 'config';
import AgentFileAddNoteModal from 'pages/app/components/File/AgentFileAddNoteModal';
import AgentFileAddWarningModal from 'pages/app/components/File/AgentFileAddWarningModal';
import AgentFileCertificationsCard from 'pages/app/components/File/AgentFileCertificationsCard';
import AgentFileDutyLogsTableModal from 'pages/app/components/File/AgentFileDutyLogsTableModal';
import AgentFileEditModal from 'pages/app/components/File/AgentFileEditModal';
import AgentFileFileDocumentsCard from 'pages/app/components/File/AgentFileFileDocumentsCard';
import AgentFileHistoryList from 'pages/app/components/File/AgentFileHistoryList';
import AgentFileInfoSheet from 'pages/app/components/File/AgentFileInfoSheet';
import AgentFileServiceTimeCard from 'pages/app/components/File/AgentFileServiceTimeCard';
import AgentsCertificationsCenterModal from 'pages/app/components/File/AgentsCertificates/AgentsCertificationsCenterModal';
import AgentFileAddWeaponModal from 'pages/app/components/File/Weapons/AgentFileAddWeaponModal';
import AgentFileWeaponsGrid from 'pages/app/components/File/Weapons/AgentFileWeaponsGrid';
import AlertModal from 'pages/app/components/common/AlertModal';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDepartmentsOrganizationalUnits } from 'reducers/slices/departmentsOrganizationalUnits';
import { getDepartmentsRanks } from 'reducers/slices/departmentsRanks';
import { RootState, dispatch, useSelector } from 'reducers/store';
import axios from 'utils/axios';
import {
  colorForAgentFileStatus,
  labelForAgentFileStatus,
} from 'utils/strings';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flex: '1 1 0px' }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function FileViewer() {
  const navigate = useNavigate();
  const { agentId } = useParams();

  const { ranks, isLoading: ranksLoading } = useSelector(
    (state: RootState) => state.departmentsRanks
  );

  const { departmentsOrganizationalUnits, isLoading: ousLoading } = useSelector(
    (state: RootState) => state.departmentsOrganizationalUnits
  );

  useEffect(() => {
    dispatch(getDepartmentsRanks());
    dispatch(getDepartmentsOrganizationalUnits());
  }, []);

  const [agentFile, setAgentFile] = useState<any>(null);
  const [agentMenuOpen, setAgentMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>('');

  const handleAgentMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setAgentMenuOpen(true);
  };

  const handleAgentMenuClose = () => {
    setAgentMenuOpen(false);
  };

  const [agentFileCertifications, setAgentFileCertifications] = useState<any[]>(
    []
  );
  const [isLoadingCertifications, setIsLoadingCertifications] = useState(true);

  const handleFetchAgentFileCertifications = useCallback(async () => {
    if (agentFile) {
      setIsLoadingCertifications(true);
      try {
        const certifications = await fetchAgentFileCertifications(
          agentFile.objectId
        );
        setAgentFileCertifications(certifications);
      } catch (error: any) {
        console.error(error);
      }
    }
    setIsLoadingCertifications(false);
  }, [agentFile]);

  useEffect(() => {
    handleFetchAgentFileCertifications();
  }, [handleFetchAgentFileCertifications]);

  const [certificationsCenterModalOpen, setCertificationsCenterModalOpen] =
    useState(false);
  const [addNoteModalOpen, setAddNoteModalOpen] = useState(false);
  const [addWarningModalOpen, setAddWarningModalOpen] = useState(false);
  const [editAgentModalOpen, setEditAgentModalOpen] = useState(false);
  const [isDutyLogsModalOpen, setIsDutyLogsModalOpen] = useState(false);
  const [assignWeaponModalOpen, setAssignWeaponModalOpen] = useState(false);

  const handleCertificationsCenterModalClose = () => {
    setCertificationsCenterModalOpen(false);
  };

  const handleCertificationsCenterModalOpen = () => {
    setCertificationsCenterModalOpen(true);
  };

  const handleAddNoteModalClose = () => {
    setAddNoteModalOpen(false);
  };

  const handleAddNoteModalOpen = () => {
    setAddNoteModalOpen(true);
  };

  const handleAddWarningModalClose = () => {
    setAddWarningModalOpen(false);
  };

  const handleAddWarningModalOpen = () => {
    setAddWarningModalOpen(true);
  };

  const handleEditAgentModalClose = (updatedFile?: any) => {
    if (updatedFile) {
      setAgentFile(updatedFile);
    }
    setEditAgentModalOpen(false);
  };

  const handleEditAgentModalOpen = () => {
    setEditAgentModalOpen(true);
  };

  const handleDutyLogsModalClose = () => {
    setIsDutyLogsModalOpen(false);
  };

  const handleDutyLogsModalOpen = () => {
    setIsDutyLogsModalOpen(true);
  };

  const handleAssignWeaponModalClose = () => {
    setAssignWeaponModalOpen(false);
  };

  const handleAssignWeaponModalOpen = () => {
    setAssignWeaponModalOpen(true);
  };

  const getFile = useCallback(async () => {
    if (agentId) {
      setIsLoading(true);
      try {
        const agentFile = await fetchAgentFile(agentId);
        setAgentFile(agentFile);
      } catch (error: any) {
        setError(error.message);
      }
    }
    setIsLoading(false);
  }, [agentId]);

  useEffect(() => {
    if (agentId) {
      if (agentId) getFile();
    }
  }, [getFile, agentId]);

  const [loadingSync, setLoadingSync] = useState(false);
  const handleAgentSync = useCallback(async () => {
    setLoadingSync(true);
    await axios.post(
      `${config.api_url}/departments/agents/files/${agentId}/sync`
    );
    setLoadingSync(false);
  }, [agentId]);

  const [selectedTab, setSelectedTab] = useState<string>('0');

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | number | boolean
  ) => {
    setSelectedTab(String(value));
  };

  const agentFileTabs = [
    {
      tabTitle: 'Identification',
      component: <AgentFileInfoSheet agentFile={agentFile} />,
    },
    {
      tabTitle: 'Certifications',
      component: (
        <AgentFileCertificationsCard
          certifications={agentFileCertifications}
          isLoading={isLoadingCertifications}
        />
      ),
    },
    {
      tabTitle: 'Armurerie',
      component: <AgentFileWeaponsGrid agentFile={agentFile} />,
    },
    {
      tabTitle: 'Historique',
      component: <AgentFileHistoryList agentFile={agentFile} />,
    },
  ];

  return (
    <Box
      component="main"
      className="MainContent"
      sx={(theme) => ({
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
          sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100dvh',
        gap: 1,
        bgcolor: 'background.body',
      })}
    >
      {isLoading || ranksLoading || ousLoading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          flex={1}
          width="100%"
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<FontAwesomeIcon icon={faChevronRight} />}
              sx={{
                '--Breadcrumbs-gap': '1rem',
                '--Icon-fontSize': '16px',
                fontWeight: 'lg',
                color: 'neutral.400',
                px: 0,
              }}
            >
              <Link
                underline="none"
                color="neutral"
                fontSize="inherit"
                onClick={() => navigate('/app')}
                aria-label="Home"
              >
                <FontAwesomeIcon icon={faHouse} />
              </Link>
              <Link
                underline="hover"
                color="neutral"
                fontSize="inherit"
                onClick={() => navigate('/app')}
              >
                Tableau de bord
              </Link>
              <Link
                underline="hover"
                color="neutral"
                fontSize="inherit"
                onClick={() => navigate('/app/department/files')}
              >
                Dossiers
              </Link>
              <Typography fontSize="inherit" variant="soft" color="primary">
                {agentFile
                  ? `${agentFile?.firstName} ${agentFile?.lastName}`
                  : 'Dossier'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 1,
              gap: 1,
              flexWrap: 'wrap',
              '& > *': {
                minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                flexGrow: 1,
              },
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography level="h1" fontSize="xl4">
                {agentFile
                  ? `${agentFile?.firstName} ${agentFile?.lastName}`
                  : 'Dossier'}
              </Typography>

              <Typography level="h3" sx={{ color: '#5e5e5e' }}>
                #{agentFile?.callSign}
              </Typography>
            </Stack>
            <Box sx={{ flex: 999 }} />
            <Box sx={{ display: 'flex', gap: 1, '& > *': { flexGrow: 1 } }}>
              <Stack
                direction="row"
                spacing={2}
                divider={<Divider orientation="vertical" />}
              >
                <Stack>
                  <Chip
                    variant="soft"
                    startDecorator={<FontAwesomeIcon icon={faBadgeCheck} />}
                    size="lg"
                    color={colorForAgentFileStatus(agentFile?.status)}
                  >
                    {labelForAgentFileStatus(agentFile?.status)}
                  </Chip>
                </Stack>
                <IconButton
                  id="positioned-demo-button"
                  aria-controls={
                    agentMenuOpen ? 'positioned-demo-menu' : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={agentMenuOpen ? 'true' : undefined}
                  variant="outlined"
                  color="neutral"
                  onClick={handleAgentMenuOpen}
                >
                  <MoreVert />
                </IconButton>
              </Stack>
              <Menu
                id="positioned-demo-menu"
                anchorEl={anchorEl}
                open={agentMenuOpen}
                onClose={handleAgentMenuClose}
                aria-labelledby="positioned-demo-button"
                placement="bottom-end"
              >
                <MenuItem onClick={handleAddNoteModalOpen}>
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <FontAwesomeIcon icon={faStickyNote} />
                  </ListItemDecorator>
                  Ajouter une note
                </MenuItem>
                <MenuItem onClick={handleAddWarningModalOpen}>
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </ListItemDecorator>
                  Ajouter un avertissement
                </MenuItem>
                <ListDivider />
                <MenuItem onClick={handleAssignWeaponModalOpen}>
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <FontAwesomeIcon icon={faGun} />
                  </ListItemDecorator>{' '}
                  Assigner une arme
                </MenuItem>
                <MenuItem onClick={handleCertificationsCenterModalOpen}>
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <FontAwesomeIcon icon={faFileCertificate} />
                  </ListItemDecorator>{' '}
                  Gérer les certifications
                </MenuItem>
                <ListDivider />
                <MenuItem onClick={handleAgentSync} disabled={loadingSync}>
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <FontAwesomeIcon icon={faSync} />
                  </ListItemDecorator>{' '}
                  Synchroniser
                </MenuItem>
                <MenuItem onClick={handleEditAgentModalOpen}>
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <Edit />
                  </ListItemDecorator>{' '}
                  Modifier le dossier
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid xs={12} md={9}>
              <Card variant="outlined">
                <CardOverflow
                  variant="soft"
                  sx={{
                    display: 'flex',
                    gap: 1.5,
                    py: 0.5,
                    px: 'var(--Card-padding)',
                    bgcolor: 'background.level1',
                    justifyContent: 'start',
                    mb: 2,
                  }}
                >
                  <Tabs
                    variant="plain"
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="certifications tabs"
                    sx={{
                      borderRadius: 0,
                    }}
                  >
                    <TabList sx={{ borderRadius: 0 }}>
                      {agentFileTabs.map((tab, tabIndex) => {
                        return (
                          <Tab key={tabIndex} value={`${tabIndex}`}>
                            {tab.tabTitle}
                          </Tab>
                        );
                      })}
                    </TabList>
                  </Tabs>
                </CardOverflow>
                <Stack spacing={2} sx={{ mb: 2 }}>
                  {agentFileTabs.map((tab, tabIndex) => {
                    return (
                      <TabPanel
                        value={selectedTab}
                        index={`${tabIndex}`}
                        key={tabIndex}
                      >
                        {tab.component}
                      </TabPanel>
                    );
                  })}
                </Stack>
              </Card>
            </Grid>
            <Grid xs={12} md={3}>
              <Stack spacing={2}>
                <AgentFileServiceTimeCard
                  agentFile={agentFile}
                  onOpenJournal={handleDutyLogsModalOpen}
                />
                <AgentFileFileDocumentsCard files={agentFile?.files} />
              </Stack>
            </Grid>
          </Grid>
          {/* Modals here */}
          {agentFile && (
            <AgentFileEditModal
              open={editAgentModalOpen}
              onClose={handleEditAgentModalClose}
              agentFile={agentFile}
              ranks={ranks}
              organizationalNodes={departmentsOrganizationalUnits}
            />
          )}
          <AgentFileAddWarningModal
            agentId={agentId}
            onClose={(refreshFile?: boolean) => {
              if (refreshFile) getFile();
              handleAddWarningModalClose();
            }}
            open={addWarningModalOpen}
          />
          <AgentFileAddNoteModal
            agentId={agentId}
            onClose={(refreshFile?: boolean) => {
              if (refreshFile) getFile();
              handleAddNoteModalClose();
            }}
            open={addNoteModalOpen}
          />
          <AgentFileDutyLogsTableModal
            open={isDutyLogsModalOpen}
            onClose={handleDutyLogsModalClose}
            agentFile={agentFile}
          />
          <AgentsCertificationsCenterModal
            open={certificationsCenterModalOpen}
            onClose={handleCertificationsCenterModalClose}
            agentFile={agentFile}
            certifications={agentFileCertifications}
            isLoading={isLoadingCertifications}
            onRefresh={handleFetchAgentFileCertifications}
          />
          <AgentFileAddWeaponModal
            open={assignWeaponModalOpen}
            onClose={handleAssignWeaponModalClose}
            agentFile={agentFile}
          />
          <AlertModal
            open={!!error}
            onClose={() => navigate(-1)}
            title="Erreur"
            message={error}
            closeButtonLabel="Retour"
          />
        </>
      )}
    </Box>
  );
}
