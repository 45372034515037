import {
  faAdd,
  faChevronRight,
  faHouse,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Breadcrumbs, Button, Link, Typography } from '@mui/joy';
import CertificationCreateModal from 'pages/app/components/Certification/CertificationCreateModal';
import CertificationsTable from 'pages/app/components/Certification/CertificationsTable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDepartmentsCertifications } from 'reducers/slices/departmentsCertifications';
import {
  RootState,
  useDispatch,
  useSelector,
} from '../../../../reducers/store';
import ColorSchemeToggle from '../../components/Main/ColorSchemeToggle';

export default function CertificationsList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { certifications, isLoading } = useSelector(
    (state: RootState) => state.departmentsCertifications
  );

  const [createCertificateModalOpen, setCreateCertificateModalOpen] =
    useState(false);

  // Modal opening handlers
  const handleCreateCertificateModalOpen = () => {
    setCreateCertificateModalOpen(true);
  };

  const handleCreateCertificateModalClose = (refreshCertificates?: boolean) => {
    setCreateCertificateModalOpen(false);
    if (refreshCertificates) dispatch(getDepartmentsCertifications());
  };

  useEffect(() => {
    dispatch(getDepartmentsCertifications());
  }, [dispatch]);

  const handleRowClick = (id: string) => {
    navigate(`/app/department/certifications/${id}`);
  };

  return (
    <Box
      component="main"
      className="MainContent"
      sx={(theme) => ({
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
          sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100dvh',
        gap: 1,
        bgcolor: 'background.body',
      })}
    >
      <CertificationCreateModal
        open={createCertificateModalOpen}
        onClose={handleCreateCertificateModalClose}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<FontAwesomeIcon icon={faChevronRight} />}
          sx={{
            '--Breadcrumbs-gap': '1rem',
            '--Icon-fontSize': '16px',
            fontWeight: 'lg',
            color: 'neutral.400',
            px: 0,
          }}
        >
          <Link
            underline="none"
            color="neutral"
            fontSize="inherit"
            onClick={() => navigate('/')}
            aria-label="Home"
          >
            <FontAwesomeIcon icon={faHouse} />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            fontSize="inherit"
            onClick={() => navigate('/app')}
          >
            Tableau de bord
          </Link>
          <Typography fontSize="inherit" variant="soft" color="primary">
            Certifications
          </Typography>
        </Breadcrumbs>
        <ColorSchemeToggle
          sx={{ ml: 'auto', display: { xs: 'none', md: 'inline-flex' } }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          my: 1,
          gap: 1,
          flexWrap: 'wrap',
          '& > *': {
            minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
            flexGrow: 1,
          },
        }}
      >
        <Typography level="h1" fontSize="xl4">
          Certifications
        </Typography>
        <Box sx={{ flex: 999 }} />
        <Box sx={{ display: 'flex', gap: 1, '& > *': { flexGrow: 1 } }}>
          <Button
            variant="outlined"
            color="neutral"
            startDecorator={<FontAwesomeIcon icon={faAdd} />}
            onClick={handleCreateCertificateModalOpen}
            disabled={isLoading}
          >
            Ajouter une certification
          </Button>
        </Box>
      </Box>
      <CertificationsTable
        certifications={certifications}
        order={'asc'}
        setOrder={() => {}}
        isLoading={isLoading}
        currentPage={1}
        setCurrentPage={() => {}}
        onRowClick={handleRowClick}
      />
    </Box>
  );
}
