import {
  faArrowRightFromBracket,
  faSitemap,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faAwardSimple,
  faCarGarage,
  faClipboard,
  faFileInvoiceDollar,
  faFolder,
  faFolders,
  faMedal,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListDivider } from '@mui/joy';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListSubheader from '@mui/joy/ListSubheader';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import useAuth from 'hooks/useAuth';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { closeSidebar } from '../../../utils';

export default function DepartmentSidebar() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickLink = (path: string) => {
    closeSidebar();
    navigate(`/app/department/${path}`);
  };

  const sidebarItems = [
    {
      sectionTitle: '',
      items: [
        {
          label: 'Mon dossier',
          icon: faFolder,
          path: 'me',
        },
      ],
    },
    {
      sectionTitle: 'Agents',
      items: [
        {
          label: 'Dossiers',
          icon: faFolders,
          path: 'files',
        },
        {
          label: 'Applications',
          icon: faClipboard,
          path: 'applications',
        },
      ],
    },
    {
      sectionTitle: 'Gestion',
      items: [
        {
          label: 'Dépenses',
          icon: faFileInvoiceDollar,
          path: 'expenses',
        },
        {
          label: 'Véhicules',
          icon: faCarGarage,
          path: 'vehicles',
        },
      ],
    },
    {
      sectionTitle: 'Configuration',
      items: [
        {
          label: 'Grades',
          icon: faAwardSimple,
          path: 'ranks',
        },
        {
          label: 'Unités organisationnelles',
          icon: faSitemap,
          path: 'orgunits',
        },
        {
          label: 'Certifications',
          icon: faMedal,
          path: 'certifications',
        },
      ],
    },
  ];

  const canManageRessource = React.useCallback(
    (ressource: string) => {
      return (
        (ressource === 'me' ||
          user?.isAdmin ||
          user?.agentFile?.currentRank?.managedResources?.indexOf(ressource) !==
            -1) &&
        user?.agentFile?.currentDepartment?.manageableRessources?.indexOf(
          ressource
        ) !== -1
      );
    },
    [user]
  );

  return (
    <React.Fragment>
      <Box
        className="SecondSidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          bgcolor: 'background.body',
          opacity: 'calc(var(--SideNavigation-slideIn, 0) - 0.2)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Sheet
        className="SecondSidebar"
        sx={{
          position: {
            xs: 'fixed',
            lg: 'sticky',
          },
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
            lg: 'none',
          },
          borderRight: '1px solid',
          borderColor: 'divider',
          transition: 'transform 0.4s',
          zIndex: 9999,
          height: '100dvh',
          top: 0,
          p: 2,
          py: 3,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <List
          sx={{
            '--ListItem-radius': '8px',
            '--ListItem-minHeight': '32px',
            '--List-gap': '4px',
          }}
        >
          <ListSubheader
            role="presentation"
            sx={{
              color: 'text.primary',
              fontWeight: 'bold',
              fontSize: 14,
            }}
          >
            {user?.agentFile?.currentDepartment?.name}
          </ListSubheader>
          {sidebarItems.map((section) => {
            const availableItems = section.items.filter((item) =>
              canManageRessource(item.path)
            );
            return (
              availableItems.length > 0 && (
                <React.Fragment key={section.sectionTitle}>
                  <ListDivider />
                  {section.sectionTitle && (
                    <ListSubheader
                      role="presentation"
                      sx={{ color: 'text.primary' }}
                    >
                      {section.sectionTitle}
                    </ListSubheader>
                  )}
                  {availableItems.map((item) => {
                    return (
                      canManageRessource(item.path) && (
                        <ListItem>
                          <ListItemButton
                            selected={location.pathname.includes(
                              `/${item.path}`
                            )}
                            variant={
                              location.pathname.includes(`/${item.path}`)
                                ? 'soft'
                                : 'plain'
                            }
                            onClick={() => handleClickLink(`${item.path}`)}
                          >
                            <ListItemDecorator>
                              <FontAwesomeIcon icon={item.icon} />
                            </ListItemDecorator>
                            <ListItemContent>{item.label}</ListItemContent>
                          </ListItemButton>
                        </ListItem>
                      )
                    );
                  })}
                </React.Fragment>
              )
            );
          })}
        </List>
        <Box sx={{ pl: 1, mt: 'auto', display: 'flex', alignItems: 'center' }}>
          <div>
            <Typography fontWeight="lg" level="body2">
              {user?.username}
            </Typography>
            <Typography level="body2">
              {user?.agentFile
                ? `${user.agentFile.lastName}, ${user.agentFile.firstName}`
                : 'Aucun dossier attaché'}
            </Typography>
          </div>
          <IconButton variant="plain" sx={{ ml: 'auto' }} onClick={logout}>
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
          </IconButton>
        </Box>
      </Sheet>
    </React.Fragment>
  );
}
