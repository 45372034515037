import Box from '@mui/joy/Box';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Main/Header';
import MainSidebar from './components/Main/Sidebars/MainSidebar';
import useScript from './useScript';

const useEnhancedEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export default function DashboardApp() {
  const status = useScript(`https://unpkg.com/feather-icons`);

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== 'undefined') {
      // @ts-ignore
      feather.replace();
    }
  }, [status]);

  return (
    <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
      <Header />
      <MainSidebar />
      <Outlet />
    </Box>
  );
}
