import AddIcon from '@mui/icons-material/Add';
import {
  Alert,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Textarea,
} from '@mui/joy';
import { useCallback, useState } from 'react';
import config from '../../../../config';
import axios from '../../../../utils/axios';
// Slices
import { Modal, ModalClose, ModalDialog, Typography } from '@mui/joy';
import { Stack } from '@mui/system';

type AgentFileAddWarningModalProps = {
  open: boolean;
  onClose: (refreshFile?: boolean) => void;
  agentId?: string;
};

export default function AgentFileAddWarningModal({
  open,
  onClose,
  agentId,
}: AgentFileAddWarningModalProps) {
  const [warning, setWarning] = useState<string>('');
  const [uploadFiles, setUploadFile] = useState<File | File[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadFile(Array.from(e.target.files));
    }
  };

  const handleCreateAddWarning = useCallback(async () => {
    setIsUpdating(true);
    try {
      let fileDocumentIds: string[] = [];

      if (uploadFiles) {
        const files = Array.isArray(uploadFiles) ? uploadFiles : [uploadFiles];
        for (const file of files) {
          console.log(file);
          var bodyFormData = new FormData();
          bodyFormData.append('file', file, file.name);

          const { data } = await axios.post(
            `${config.api_url}/upload/file`,
            bodyFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          fileDocumentIds.push(data.objectId);
        }
      }

      await axios.post(
        `${config.api_url}/departments/agents/files/${agentId}/warnings`,
        {
          warning,
          fileDocumentIds,
        }
      );
      onClose(true);
    } catch (error) {
      console.error(error);
      setError(error as string);
    }
    setIsUpdating(false);
  }, [onClose, agentId, warning, uploadFiles]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="lg"
        sx={{
          minWidth: '50%',
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        {error && (
          <Alert variant="soft" color="danger">
            {error}
          </Alert>
        )}
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<AddIcon />}
        >
          Contenu de l'avertissement
        </Typography>
        <Divider />
        <Stack spacing={1} sx={{ py: 1, overflow: 'scroll' }}>
          <FormControl>
            <Textarea
              placeholder="Contenu de l'avertissement"
              minRows={5}
              value={warning}
              variant="plain"
              onChange={(e) => {
                setWarning(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Attacher des fichiers</FormLabel>
            <input type="file" onChange={handleFileChange} multiple />
          </FormControl>
        </Stack>
        <Divider />
        <Stack
          spacing={2}
          direction="row"
          justifyContent="end"
          sx={{ paddingTop: '24px' }}
        >
          <Button
            color="danger"
            variant="soft"
            onClick={() => onClose()}
            disabled={isUpdating}
          >
            Annuler
          </Button>
          <Button
            variant="solid"
            color="primary"
            loading={isUpdating}
            onClick={handleCreateAddWarning}
            disabled={!agentId}
          >
            Ajouter l'avertissement
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
