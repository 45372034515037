import {
  Alert,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import { useCallback, useState } from 'react';
import config from '../../../../../config';
import axios from '../../../../../utils/axios';
// Slices
import { faEdit } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack } from '@mui/system';

type AgentFileAddWeaponModalProps = {
  open: boolean;
  onClose: (updatedFile?: any) => void;
  agentFile: any;
};

export default function AgentFileAddWeaponModal({
  open,
  onClose,
  agentFile,
}: AgentFileAddWeaponModalProps) {
  const [weaponName, setWeaponName] = useState<string>('');
  const [weaponSerial, setWeaponSerial] = useState<string>('');

  const [error, setError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleAssignWeapon = useCallback(async () => {
    setIsUpdating(true);
    try {
      const { data } = await axios.post(
        `${config.api_url}/departments/agents/files/${agentFile.objectId}/weapons`,
        {
          weaponName,
          weaponSerial,
        }
      );

      onClose(data);
    } catch (error) {
      console.error(error);
      setError(error as string);
    }
    setIsUpdating(false);
  }, [onClose, weaponName, weaponSerial, agentFile]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="lg"
        sx={{
          minWidth: '50%',
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<FontAwesomeIcon icon={faEdit} />}
        >
          Assigner une arme
        </Typography>
        <Divider />
        <Stack spacing={2} sx={{ p: 2 }}>
          {error && (
            <Alert variant="soft" color="danger">
              {JSON.stringify(error)}
            </Alert>
          )}
          <FormControl>
            <FormLabel>Type d'arme</FormLabel>
            <Select
              placeholder="Sélectionner une arme"
              slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
              onChange={(e, value) => {
                if (e && value) setWeaponName(value);
              }}
              value={weaponName}
            >
              {['Glock 17', 'SIG516'].map((weapon) => (
                <Option key={weapon} value={weapon}>
                  {weapon}
                </Option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel># Série</FormLabel>
            <Input
              value={weaponSerial}
              onChange={(e) => {
                setWeaponSerial(e.target.value.toUpperCase());
              }}
            />
            <FormHelperText>
              Le numéro de série doit être composé de X caractères, obtenu à
              l'armurerie du poste.
            </FormHelperText>
          </FormControl>
        </Stack>
        <Divider />
        <Stack
          spacing={2}
          direction="row"
          justifyContent="end"
          sx={{ paddingTop: '24px' }}
        >
          <Button
            color="danger"
            variant="soft"
            onClick={() => onClose()}
            disabled={isUpdating}
          >
            Annuler
          </Button>
          <Button
            variant="solid"
            color="primary"
            loading={isUpdating}
            onClick={handleAssignWeapon}
          >
            Assigner
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
