/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faFilter,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Stack } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Link from '@mui/joy/Link';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import { capitalize } from 'lodash';
import * as React from 'react';
import { certificationTypeNameForType } from 'utils/strings';
import { FPAvailableCertification } from '../../../../@types/department';

type Order = 'asc' | 'desc';

type CertificationsTableProps = {
  order: Order;
  setOrder: (order: Order) => void;
  certifications?: FPAvailableCertification[] | null;
  isLoading: boolean;
  currentPage: number;
  setCurrentPage: (cb: (oldValue: any) => number) => void;
  onRowClick: (id: string) => void;
};

export default function CertificationsTable({
  order,
  setOrder,
  certifications,
  isLoading,
  currentPage,
  setCurrentPage,
  onRowClick,
}: CertificationsTableProps) {
  const handleNextPage = () => {
    setCurrentPage((page) => page + 1);
  };
  const handlePreviousPage = () => {
    setCurrentPage((page) => page - 1);
  };

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const renderFilters = () => <React.Fragment></React.Fragment>;
  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: {
            xs: 'flex',
            sm: 'none',
          },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setFiltersOpen(true)}
        >
          <FontAwesomeIcon icon={faFilter} />
        </IconButton>
        <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setFiltersOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: {
            xs: 'none',
            sm: 'flex',
          },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '120px',
              md: '160px',
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Rechercher un certificat</FormLabel>
          <Input
            placeholder="Rechercher"
            startDecorator={<FontAwesomeIcon icon={faMagnifyingGlass} />}
          />
        </FormControl>

        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'md',
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
            <CircularProgress />
          </Stack>
        ) : (
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': (theme) =>
                theme.vars.palette.background.level1,
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': (theme) =>
                theme.vars.palette.background.level1,
            }}
          >
            <thead>
              <tr>
                <th style={{ width: 140, padding: 12 }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                    fontWeight="lg"
                    endDecorator={<FontAwesomeIcon icon={faArrowDown} />}
                    sx={{
                      '& svg': {
                        transition: '0.2s',
                        transform:
                          order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                      },
                    }}
                  >
                    Code
                  </Link>
                </th>
                <th style={{ padding: 12 }}>Description</th>
                <th style={{ padding: 12 }}>Type</th>
              </tr>
            </thead>

            <tbody>
              {certifications?.map((certification) => (
                <tr
                  key={certification.objectId}
                  onClick={() => onRowClick(certification.objectId)}
                  style={{ cursor: 'pointer' }}
                >
                  <td>
                    <Typography fontWeight="md">
                      {certification.code}
                    </Typography>
                  </td>
                  <td>
                    <Typography fontWeight="md">
                      {certification.description}
                    </Typography>
                  </td>
                  <td>
                    <Typography fontWeight="md">
                      {capitalize(
                        certificationTypeNameForType(
                          certification.certificationType
                        )
                      )}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Sheet>
      <Box
        className="Pagination-mobile"
        sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}
      >
        <IconButton
          aria-label="previous page"
          variant="outlined"
          color="neutral"
          size="sm"
          onClick={handlePreviousPage}
          disabled={currentPage <= 1}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </IconButton>
        <Typography level="body2" mx="auto">
          Page {currentPage} sur 10
        </Typography>
        <IconButton
          aria-label="next page"
          variant="outlined"
          color="neutral"
          size="sm"
          onClick={handleNextPage}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </IconButton>
      </Box>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 4,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="plain"
          color="neutral"
          startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={handlePreviousPage}
          disabled={currentPage <= 1}
        >
          Précédent
        </Button>

        <Box sx={{ flex: 1 }} />
        <Typography level="body2" mx="auto">
          Page {currentPage} sur 10
        </Typography>
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="plain"
          color="neutral"
          endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
          onClick={handleNextPage}
        >
          Suivant
        </Button>
      </Box>
    </React.Fragment>
  );
}
