import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import { useColorScheme } from '@mui/joy/styles';
import config from 'config';
import { departments } from 'data';
import useAuth from 'hooks/useAuth';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { apply } from 'translations';
import { useLocalStorage } from 'usehooks-ts';
import axios from 'utils/axios';
import ApplyHeader from './common/ApplyHeader';

type DepartmentType = typeof departments;
type DptApplicationForm = {
  firstName: string;
  lastName: string;
  age: number;
  hasExperience: boolean;
  experience: string;
  expectations: string;
  distinctions: string;
  aboutYou: string;
};

/**
 * This template uses [`Inter`](https://fonts.google.com/specimen/Inter?query=inter) font.
 */
export default function DepartmentApplyController() {
  const navigate = useNavigate();

  const { departmentShortName } = useParams<{
    [x: string]: keyof DepartmentType;
  }>();

  const { setMode } = useColorScheme();
  const { refreshUser } = useAuth();

  const [lang] = useLocalStorage<'fr' | 'en'>('lang', 'fr');

  useEffect(() => {
    setMode('dark');
  }, [setMode]);

  const [formData, setFormData] = useState<DptApplicationForm>({
    firstName: '',
    lastName: '',
    age: 16,
    hasExperience: false,
    experience: '',
    expectations: '',
    distinctions: '',
    aboutYou: '',
  });

  const handleUpdateFormData = (
    field: keyof DptApplicationForm,
    value: any
  ) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<any>();

  const handleApply = useCallback(async () => {
    setSubmitting(true);
    try {
      await axios.post(
        `${config.api_url}/departments/apply/${departmentShortName}`,
        {
          form: formData,
        }
      );
      await refreshUser();
      setSubmitting(false);
      navigate('/app');
    } catch (err) {
      setSubmitting(false);
      setError(err);
    }
  }, [formData, departmentShortName, navigate, refreshUser]);

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100dvh',
        width:
          'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
        maxWidth: '100%',
        px: 2,
      }}
    >
      <ApplyHeader />
      <Box
        component="main"
        sx={{
          my: 'auto',
          p: 5,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '100%',
          mx: 'auto',
          borderRadius: 'sm',
          backdropFilter: 'blur(10px)',
        }}
      >
        {departmentShortName ? (
          <Stack spacing={2}>
            <Stack>
              <Typography level="h5">
                {apply[lang].youveChosenTitle}{' '}
                {departments[departmentShortName].determiner[lang]}{' '}
                <strong>{departments[departmentShortName].name[lang]}</strong>
              </Typography>
              <Typography level="body1">
                {apply[lang].pleaseFillForm}
              </Typography>
              <Typography level="body2">
                {apply[lang].pleaseFillFormRP}
              </Typography>
            </Stack>
            <Stack spacing={2}>
              {error && <Alert color="warning">{JSON.stringify(error)}</Alert>}
              <Stack direction="row" spacing={2}>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel>{apply[lang].formFirstName}</FormLabel>
                  <Input
                    placeholder={apply[lang].formFirstName}
                    value={formData.firstName}
                    onChange={(e) => {
                      handleUpdateFormData('firstName', e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel>{apply[lang].formLastName}</FormLabel>
                  <Input
                    placeholder={apply[lang].formLastName}
                    value={formData.lastName}
                    onChange={(e) => {
                      handleUpdateFormData('lastName', e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl sx={{ width: 100 }}>
                  <FormLabel>{apply[lang].formAge}</FormLabel>
                  <Input
                    placeholder=""
                    value={formData.age === 0 ? '' : String(formData.age)}
                    onChange={(e) => {
                      handleUpdateFormData(
                        'age',
                        isNaN(parseInt(e.target.value.replace(/\D/g, '')))
                          ? 0
                          : parseInt(e.target.value.replace(/\D/g, ''))
                      );
                    }}
                  />
                </FormControl>
              </Stack>
              <FormControl>
                <FormLabel>{apply[lang].formRPExperience}</FormLabel>
                <RadioGroup
                  value={formData.hasExperience}
                  onChange={(e) =>
                    handleUpdateFormData(
                      'hasExperience',
                      e.target.value === 'true'
                    )
                  }
                  name="radio-buttons-group"
                  sx={{ my: 1 }}
                >
                  <Radio
                    value="true"
                    label={apply[lang].formRPExperienceTrue}
                  />
                  <Radio
                    value="false"
                    label={apply[lang].formRPExperienceFalse}
                  />
                </RadioGroup>
              </FormControl>
              {formData.hasExperience && (
                <FormControl>
                  <FormLabel>{apply[lang].formRPExperienceExplain}</FormLabel>
                  <Textarea
                    placeholder={apply[lang].formRPExperienceExplainPlaceholder}
                    value={formData.experience}
                    onChange={(e) => {
                      handleUpdateFormData('experience', e.target.value);
                    }}
                    minRows={3}
                  />
                </FormControl>
              )}
              <FormControl>
                <FormLabel>{apply[lang].formRPExpectations}</FormLabel>
                <Textarea
                  placeholder={apply[lang].formRPExpectationsPlaceholder}
                  value={formData.expectations}
                  onChange={(e) => {
                    handleUpdateFormData('expectations', e.target.value);
                  }}
                  minRows={3}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{apply[lang].formRPWhatMakesYouStandOut}</FormLabel>
                <Textarea
                  placeholder={
                    apply[lang].formRPWhatMakesYouStandOutPlaceholder
                  }
                  value={formData.distinctions}
                  onChange={(e) => {
                    handleUpdateFormData('distinctions', e.target.value);
                  }}
                  minRows={3}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{apply[lang].formRPTellUsAboutYourself}</FormLabel>
                <Textarea
                  placeholder={apply[lang].formRPTellUsAboutYourselfPlaceholder}
                  value={formData.aboutYou}
                  onChange={(e) => {
                    handleUpdateFormData('aboutYou', e.target.value);
                  }}
                  minRows={3}
                />
              </FormControl>
              <Stack direction="row" justifyContent="end">
                <Button onClick={handleApply} loading={submitting}>
                  {apply[lang].formBtnSendMyApplication}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Navigate to="/app/nolink/apply" />
        )}
      </Box>
      <Box component="footer" sx={{ py: 3 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="center">
            <Button
              variant="plain"
              color="neutral"
              onClick={() => navigate(-1)}
              startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
            >
              {apply[lang].btnBack}
            </Button>
          </Stack>
          <Typography level="body3" textAlign="center">
            © FivePD MTL {new Date().getFullYear()}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}
