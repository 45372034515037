import { CSSProperties } from 'react';
import {
  ai,
  ipiq,
  mtq,
  rcmp,
  sq,
  us,
} from '../../assets/images/departments/logos';

export default function UserDepartmentLogo({
  sx,
  agentFile,
}: {
  sx?: CSSProperties;
  agentFile: any;
}) {
  const departmentLogoForAgentFile = (department: string) => {
    switch (department) {
      case 'sq':
        return sq;
      case 'mtq':
        return mtq;
      case 'rcmp':
        return rcmp;
      case 'ssi':
        return ipiq;
      case 'us':
        return us;
      case 'ai':
        return ai;
      default:
        return sq;
    }
  };

  return agentFile && agentFile?.currentDepartment ? (
    <img
      src={departmentLogoForAgentFile(agentFile?.currentDepartment?.shortName)}
      style={{ ...sx }}
      alt="file department logo"
    />
  ) : (
    <> </>
  );
}
