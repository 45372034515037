import AddIcon from '@mui/icons-material/Add';
import {
  Alert,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
} from '@mui/joy';
import { useCallback, useState } from 'react';
import config from '../../../../../config';
import axios from '../../../../../utils/axios';
// Slices
import { Modal, ModalClose, ModalDialog, Typography } from '@mui/joy';
import { Stack } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import { JoyDatePicker } from '../../common/JoyDatePicker';

type MyExpensesAddExpenseModalProps = {
  open: boolean;
  onClose: (newExpense?: any) => void;
};

export default function MyExpensesAddExpenseModal({
  open,
  onClose,
}: MyExpensesAddExpenseModalProps) {
  const [amount, setAmount] = useState<number>(0);
  const [category, setCategory] = useState<string>('');
  const [expDate, setExpDate] = useState<Dayjs | null>(dayjs());
  const [uploadFiles, setUploadFile] = useState<File | File[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadFile(Array.from(e.target.files));
    }
  };

  const handleCreateExpense = useCallback(async () => {
    setIsUpdating(true);
    try {
      let attachmentsIds: string[] = [];

      if (uploadFiles) {
        const files = Array.isArray(uploadFiles) ? uploadFiles : [uploadFiles];
        for (const file of files) {
          console.log(file);
          var bodyFormData = new FormData();
          bodyFormData.append('file', file, file.name);

          const { data } = await axios.post(
            `${config.api_url}/upload/file`,
            bodyFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          attachmentsIds.push(data.objectId);
        }
      }

      const { data: newExpense } = await axios.post(
        `${config.api_url}/me/file/expenses`,
        {
          amount,
          attachmentsIds,
          expenseCategory: category,
          expenseDate: expDate?.toDate(),
        }
      );

      onClose(newExpense);
    } catch (error) {
      console.error(error);
      setError(error as string);
    }
    setIsUpdating(false);
  }, [onClose, amount, uploadFiles, category, expDate]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="lg"
        sx={{
          minWidth: '50%',
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        {error && (
          <Alert variant="soft" color="danger">
            {error}
          </Alert>
        )}
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<AddIcon />}
        >
          Ajouter une dépense
        </Typography>
        <Divider />
        <Stack spacing={1} sx={{ py: 1, overflow: 'scroll' }}>
          <FormControl>
            <FormLabel>Montant</FormLabel>
            <Input
              type="number"
              value={amount}
              endDecorator="$"
              onChange={(e) => setAmount(Number(e.target.value))}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Catégorie de dépense</FormLabel>
            <Select
              placeholder="Sélectionner une catégorie"
              value={category}
              onChange={(e, value) => {
                if (e && value) setCategory(value);
              }}
            >
              <Option value="fuel">Essence</Option>
              <Option value="repairs">Réparations</Option>
              <Option value="foor">Nourriture</Option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Date de la dépense</FormLabel>
            <JoyDatePicker value={expDate} onChange={(e) => setExpDate(e)} />
          </FormControl>

          <FormControl>
            <FormLabel>Justification</FormLabel>
            <input type="file" onChange={handleFileChange} multiple />
            <FormHelperText>
              Une photo de la facture ou du reçu, ou quelconque autre fichier
              qui justifie cette dépense.
            </FormHelperText>
          </FormControl>
        </Stack>
        <Divider />
        <Stack
          spacing={2}
          direction="row"
          justifyContent="end"
          sx={{ paddingTop: '24px' }}
        >
          <Button
            color="danger"
            variant="soft"
            onClick={() => onClose()}
            disabled={isUpdating}
          >
            Annuler
          </Button>
          <Button
            variant="solid"
            color="primary"
            loading={isUpdating}
            onClick={handleCreateExpense}
          >
            Ajouter la dépense
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
