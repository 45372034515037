import { ipiq, mtq, sq, us } from 'assets/images/departments/logos';

const background = (dpt: string) => {
  return `https://storage.googleapis.com/fivepd-mtl-site-assets/departments/${dpt}.jpg`;
};

const departments = {
  sq: {
    name: {
      en: 'Sûreté du Québec',
      fr: 'Sûreté du Québec',
    },
    describer: {
      en: 'Provincial Police',
      fr: 'Police provinciale',
    },
    image: background('sq'),
    logo: sq,
    needsBackdrop: false,
    minimumAge: 18,
    language: 'fr',
    determiner: { fr: 'la ', en: 'the ' },
    acceptsApplications: true,
    sign: 'sq',
  },
  mtq: {
    name: {
      en: 'Ministry of Transportation of Quebec',
      fr: 'Ministère des Transports du Québec',
    },
    describer: {
      en: 'Department of Transportation',
      fr: 'Ministère des Transports',
    },
    image: background('mtq'),
    logo: mtq,
    needsBackdrop: true,
    minimumAge: 16,
    language: 'fr',
    determiner: { fr: 'le ', en: 'the ' },
    acceptsApplications: true,
    sign: 'mtq',
  },
  ssi: {
    name: {
      en: 'SIM, SSI Saint-Lin-Laurentides or SSI Mont-Tremblant',
      fr: 'SIM, SSI Saint-Lin-Laurentides ou SSI Mont-Tremblant',
    },
    describer: {
      en: 'Fire department',
      fr: 'Service de Sécurité Incendie',
    },
    image: background('sim'),
    logo: ipiq,
    needsBackdrop: false,
    minimumAge: 16,
    language: 'fr',
    determiner: { fr: 'le ', en: 'the ' },
    acceptsApplications: true,
    sign: 'ssi',
  },
  us: {
    name: {
      en: 'Services préhospitaliers Laurentides-Lanaudière',
      fr: 'Services préhospitaliers Laurentides-Lanaudière',
    },
    describer: {
      en: 'Paramedics',
      fr: 'Paramédics',
    },
    image: background('us'),
    logo: us,
    needsBackdrop: false,
    minimumAge: 16,
    language: 'fr',
    determiner: { fr: '', en: '' },
    acceptsApplications: true,
    sign: 'us',
  },
  // rcmp: {
  //   name: {
  //     en: 'Royal Canadian Mounted Police',
  //     fr: 'Gendarmerie royale du Canada',
  //   },
  //   describer: {
  //     en: 'Federal Police',
  //     fr: 'Police fédérale',
  //   },
  //   image: background('rcmp'),
  //   logo: rcmp,
  //   needsBackdrop: false,
  //   minimumAge: 18,
  //   language: 'en',
  //   determiner: { fr: 'la ', en: 'the ' },
  //   acceptsApplications: false,
  //   sign: 'rcmp',
  // },
};

export default departments;
