import { Stack, Typography } from '@mui/joy';
import { labelForAgentFileStatus } from 'utils/strings';
import AgentFileIDCard from './AgentFileIDCard';

type AgentFileInfoSheetProps = {
  agentFile?: any;
};

function AgentInfoItem({ label, value }: { label: string; value: string }) {
  return (
    <Stack>
      <Typography
        level="body3"
        fontWeight="bold"
        sx={{ textTransform: 'uppercase', lineHeight: 1.1 }}
      >
        {label}
      </Typography>
      <Typography level="body1" sx={{ lineHeight: 1.1 }}>
        {value || 'N/A'}
      </Typography>
    </Stack>
  );
}

export default function AgentFileInfoSheet({
  agentFile,
}: AgentFileInfoSheetProps) {
  return (
    <Stack direction="row" spacing={2}>
      <AgentFileIDCard agentFile={agentFile} />

      <Stack sx={{ flex: 1 }}>
        <Stack spacing={2}>
          <AgentInfoItem
            label="Statut"
            value={labelForAgentFileStatus(agentFile?.status)}
          />
          <AgentInfoItem
            label="Grade"
            value={
              agentFile?.currentRank
                ? agentFile?.currentRank?.name
                : 'Rang inexistant'
            }
          />
          <AgentInfoItem label="Matricule" value={agentFile?.callSign} />
          <AgentInfoItem
            label="Date d'entrée"
            value={
              agentFile?.entryDate?.iso
                ? new Date(agentFile?.entryDate?.iso)?.toLocaleDateString(
                    'fr-CA'
                  )
                : 'N/D'
            }
          />
          <AgentInfoItem
            label="Unité organisationnelle"
            value={agentFile?.organizationalNodeName}
          />
          <AgentInfoItem label="Poste" value={agentFile?.position} />
        </Stack>
      </Stack>
    </Stack>
  );
}
