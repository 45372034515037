import { faClock, faList } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardOverflow, Link, Stack, Typography } from '@mui/joy';

type AgentFileServiceTimeCardProps = {
  agentFile?: any;
  onOpenJournal?: () => void;
};

export default function AgentFileServiceTimeCard({
  agentFile,
  onOpenJournal,
}: AgentFileServiceTimeCardProps) {
  return (
    <Card variant="outlined">
      <CardOverflow
        variant="soft"
        sx={{
          display: 'flex',
          gap: 1.5,
          py: 0.5,
          px: 'var(--Card-padding)',
          bgcolor: 'background.level1',
          justifyContent: 'start',
          mb: 2,
        }}
      >
        <Typography level="body3">Heures de service</Typography>
      </CardOverflow>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <FontAwesomeIcon icon={faClock} />
        <Typography level="body1" sx={{ textAlign: 'center' }}>
          {Math.round(
            ((agentFile?.totalDutyTime || 0) / 60 + Number.EPSILON) * 100
          ) / 100}{' '}
          heures de service
        </Typography>
      </Stack>
      <CardOverflow
        variant="soft"
        sx={{
          display: 'flex',
          gap: 1.5,
          py: 0.5,
          px: 'var(--Card-padding)',
          bgcolor: 'background.level1',
          justifyContent: 'end',
          mt: 2,
        }}
      >
        <Link
          href="#"
          onClick={onOpenJournal}
          level="body3"
          underline="none"
          startDecorator={<FontAwesomeIcon icon={faList} color="white" />}
          sx={{
            fontWeight: 'md',
            color: 'text.secondary',
            '&:hover': { color: 'primary.plainColor' },
          }}
        >
          Voir journal
        </Link>
      </CardOverflow>
    </Card>
  );
}
