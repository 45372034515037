import { createSlice } from '@reduxjs/toolkit';
import { FPVehicle } from '../../@types/department';
// utils
import config from '../../config';
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type DepartmentsVehiclesState = {
  isLoading: boolean;
  error: boolean;
  vehicles?: FPVehicle[] | null;
};

const initialState: DepartmentsVehiclesState = {
  isLoading: false,
  error: false,
  vehicles: null,
};

const slice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // EMPTY PROFILE
    emptyDepartmentsVehicles(state) {
      state.isLoading = false;
      state.vehicles = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getDepartmentsVehiclesSuccess(state, action) {
      state.isLoading = false;
      state.vehicles = action.payload;
    },
  },
});

// Actions
// ----------------------------------------------------------------------

export function getDepartmentsVehicles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get(
        `${config.api_url}/departments/ressources/vehicles`
      );
      dispatch(slice.actions.getDepartmentsVehiclesSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function emptyDepartmentsVehicles() {
  return () => {
    dispatch(slice.actions.emptyDepartmentsVehicles());
  };
}

// Reducer
export default slice.reducer;
