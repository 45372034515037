import { createSlice } from '@reduxjs/toolkit';
import { FPRank } from '../../@types/department';
// utils
import config from '../../config';
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type DepartmentsState = {
  isLoading: boolean;
  error: boolean;
  ranks?: FPRank[] | null;
};

const initialState: DepartmentsState = {
  isLoading: false,
  error: false,
  ranks: null,
};

const slice = createSlice({
  name: 'ranks',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // EMPTY PROFILE
    emptyRanks(state) {
      state.isLoading = false;
      state.ranks = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getRanksSuccess(state, action) {
      state.isLoading = false;
      state.ranks = action.payload;
    },
  },
});

// Actions
// ----------------------------------------------------------------------

export function getRanks(departmentId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get(
        `${config.api_url}/departments/${departmentId}/ranks`
      );
      dispatch(slice.actions.getRanksSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function emptyRanks() {
  return () => {
    dispatch(slice.actions.emptyRanks());
  };
}

// Reducer
export default slice.reducer;
