import { faFile } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardOverflow, Divider, Stack, Typography } from '@mui/joy';
import config from 'config';

type AgentFileFileDocumentsCardProps = {
  files?: any[];
  isLoading?: boolean;
};

export default function AgentFileFileDocumentsCard({
  files,
  isLoading,
}: AgentFileFileDocumentsCardProps) {
  return (
    <Card variant="outlined">
      <CardOverflow
        variant="soft"
        sx={{
          display: 'flex',
          gap: 1.5,
          py: 0.5,
          px: 'var(--Card-padding)',
          bgcolor: 'background.level1',
          justifyContent: 'start',
          mb: 2,
        }}
      >
        <Typography level="body3">Fichiers</Typography>
      </CardOverflow>
      <Stack spacing={1} divider={<Divider />}>
        {files && files.length > 0 ? (
          files?.map((file) => (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ cursor: 'pointer', ':hover': { color: '#fff' } }}
              onClick={() => {
                window.open(
                  `${config.api_url}/assets/uploadedFiles/${file?.objectId}`,
                  '_blank',
                  'noreferrer'
                );
              }}
            >
              <FontAwesomeIcon icon={faFile} />
              <Typography level="body3" sx={{ color: '#fff' }}>
                <strong>{file?.name}</strong>
              </Typography>
            </Stack>
          ))
        ) : (
          <Typography level="body1" textAlign="center">
            Aucun fichier
          </Typography>
        )}
      </Stack>
    </Card>
  );
}
