import StandardHeader from 'components/common/BackdropImageContainer';

const page404 = () => {
  return (
    <StandardHeader>
      <p>Erreur 404</p>
    </StandardHeader>
  );
};

export default page404;
