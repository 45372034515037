/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Stack } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { FPOrganizationalNode } from '../../../../@types/department';

type Order = 'asc' | 'desc';

type RanksTableProps = {
  order: Order;
  setOrder: (order: Order) => void;
  organizationalNodes?: FPOrganizationalNode[];
  isLoading: boolean;
  currentPage: number;
  setCurrentPage: (cb: (oldValue: any) => number) => void;
  onRowClick: (id: string) => void;
};

export default function OrganizationalUnitsTable({
  order,
  setOrder,
  organizationalNodes,
  isLoading,
  currentPage,
  setCurrentPage,
  onRowClick,
}: RanksTableProps) {
  const handleNextPage = () => {
    setCurrentPage((page) => page + 1);
  };
  const handlePreviousPage = () => {
    setCurrentPage((page) => page - 1);
  };

  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'md',
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
            <CircularProgress />
          </Stack>
        ) : (
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': (theme) =>
                theme.vars.palette.background.level1,
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': (theme) =>
                theme.vars.palette.background.level1,
            }}
          >
            <thead>
              <tr>
                <th style={{ padding: 12 }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                    fontWeight="lg"
                    endDecorator={<FontAwesomeIcon icon={faArrowDown} />}
                    sx={{
                      '& svg': {
                        transition: '0.2s',
                        transform:
                          order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                      },
                    }}
                  >
                    Nom
                  </Link>
                </th>
                <th style={{ padding: 12 }}>Discord ID</th>
                <th style={{ padding: 12 }}>Préfix Matricule</th>
                <th style={{ padding: 12 }}>Suffix Matricule</th>
              </tr>
            </thead>

            <tbody>
              {organizationalNodes?.map((organizationalNode) => (
                <tr
                  key={organizationalNode.id}
                  onClick={() => onRowClick(organizationalNode.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <td
                    style={{
                      paddingLeft: `${
                        20 * organizationalNode.path.split('.').length
                      }px`,
                    }}
                  >
                    <Typography level="body1" textColor="text.primary">
                      {organizationalNode.name}
                    </Typography>
                  </td>
                  <td>
                    <Typography level="body1" textColor="text.primary">
                      {organizationalNode.discordRoleId}
                    </Typography>
                  </td>
                  <td>
                    <Typography level="body1" textColor="text.primary">
                      {organizationalNode.callsignPrefix}
                    </Typography>
                  </td>
                  <td>
                    <Typography level="body1" textColor="text.primary">
                      {organizationalNode.callsignSuffix}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Sheet>
      <Box
        className="Pagination-mobile"
        sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}
      >
        <IconButton
          aria-label="previous page"
          variant="outlined"
          color="neutral"
          size="sm"
          onClick={handlePreviousPage}
          disabled={currentPage <= 1}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </IconButton>
        <Typography level="body2" mx="auto">
          Page {currentPage} sur 10
        </Typography>
        <IconButton
          aria-label="next page"
          variant="outlined"
          color="neutral"
          size="sm"
          onClick={handleNextPage}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </IconButton>
      </Box>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 4,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="plain"
          color="neutral"
          startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={handlePreviousPage}
          disabled={currentPage <= 1}
        >
          Précédent
        </Button>

        <Box sx={{ flex: 1 }} />
        <Typography level="body2" mx="auto">
          Page {currentPage} sur 10
        </Typography>
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="plain"
          color="neutral"
          endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
          onClick={handleNextPage}
        >
          Suivant
        </Button>
      </Box>
    </React.Fragment>
  );
}
