import {
  faAdd,
  faChevronRight,
  faHouse,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Breadcrumbs, Button, Link, Typography } from '@mui/joy';
import OrganizationalUnitsTable from 'pages/app/components/OrganizationalUnit/OrganizationalUnitsTable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDepartmentsOrganizationalUnits } from 'reducers/slices/departmentsOrganizationalUnits';
import { FPOrganizationalNode } from '../../../../@types/department';
import {
  RootState,
  useDispatch,
  useSelector,
} from '../../../../reducers/store';
import ColorSchemeToggle from '../../components/Main/ColorSchemeToggle';
import OrganizationalUnitCreateModal from '../../components/OrganizationalUnit/OrganizationalUnitCreateModal';
import OrganizationalUnitEditModal from '../../components/OrganizationalUnit/OrganizationalUnitEditModal';

export default function OrganizationalUnitsList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [organizationalUnitsOrder, setOrganizationalUnitsOrder] = useState<
    'asc' | 'desc'
  >('asc');
  const [organizationalUnitsPage, setOrganizationalUnitsPage] = useState(1);

  const { departmentsOrganizationalUnits, isLoading } = useSelector(
    (state: RootState) => state.departmentsOrganizationalUnits
  );

  const [selectedOrganizationalNode, setSelectedOrganizationalUnit] =
    useState<FPOrganizationalNode | null>(null);
  const [editOrganizationalUnitModalOpen, setOrganizationalUnitModalOpen] =
    useState(false);
  const [
    createOrganizationalUnitModalOpen,
    setCreateOrganizationalUnitModalOpen,
  ] = useState(false);

  // Modal opening handlers
  const handleEditOrganizationalUnitModalOpen = (
    organizationalUnit: FPOrganizationalNode
  ) => {
    setSelectedOrganizationalUnit(organizationalUnit);
    setOrganizationalUnitModalOpen(true);
  };

  const handleEditRankModalClose = (refreshOrganizationalUnits?: boolean) => {
    setSelectedOrganizationalUnit(null);
    setOrganizationalUnitModalOpen(false);
    if (refreshOrganizationalUnits)
      dispatch(getDepartmentsOrganizationalUnits());
  };

  const handleCreateRankModalOpen = () => {
    setCreateOrganizationalUnitModalOpen(true);
  };

  const handleCreateRankModalClose = (refreshOrganizationalUnits?: boolean) => {
    setCreateOrganizationalUnitModalOpen(false);
    if (refreshOrganizationalUnits)
      dispatch(getDepartmentsOrganizationalUnits());
  };

  useEffect(() => {
    dispatch(getDepartmentsOrganizationalUnits());
  }, [dispatch]);

  const handleRowClick = (id: string) => {
    handleEditOrganizationalUnitModalOpen(
      departmentsOrganizationalUnits?.find(
        (organizationalUnit) => organizationalUnit.id === id
      ) as FPOrganizationalNode
    );
  };

  return (
    <Box
      component="main"
      className="MainContent"
      sx={(theme) => ({
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
          sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100dvh',
        gap: 1,
        bgcolor: 'background.body',
      })}
    >
      <OrganizationalUnitEditModal
        open={editOrganizationalUnitModalOpen}
        onClose={handleEditRankModalClose}
        organizationalNode={selectedOrganizationalNode}
      />
      <OrganizationalUnitCreateModal
        open={createOrganizationalUnitModalOpen}
        onClose={handleCreateRankModalClose}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<FontAwesomeIcon icon={faChevronRight} />}
          sx={{
            '--Breadcrumbs-gap': '1rem',
            '--Icon-fontSize': '16px',
            fontWeight: 'lg',
            color: 'neutral.400',
            px: 0,
          }}
        >
          <Link
            underline="none"
            color="neutral"
            fontSize="inherit"
            onClick={() => navigate('/app')}
            aria-label="Home"
          >
            <FontAwesomeIcon icon={faHouse} />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            fontSize="inherit"
            onClick={() => navigate('/app')}
          >
            Tableau de bord
          </Link>
          <Typography fontSize="inherit" variant="soft" color="primary">
            Unités organisationelles
          </Typography>
        </Breadcrumbs>
        <ColorSchemeToggle
          sx={{ ml: 'auto', display: { xs: 'none', md: 'inline-flex' } }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          my: 1,
          gap: 1,
          flexWrap: 'wrap',
          '& > *': {
            minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
            flexGrow: 1,
          },
        }}
      >
        <Typography level="h1" fontSize="xl4">
          Unités organisationelles
        </Typography>
        <Box sx={{ flex: 999 }} />
        <Box sx={{ display: 'flex', gap: 1, '& > *': { flexGrow: 1 } }}>
          <Button
            variant="outlined"
            color="neutral"
            startDecorator={<FontAwesomeIcon icon={faAdd} />}
            onClick={handleCreateRankModalOpen}
            disabled={isLoading}
          >
            Créer une unité organisationelle
          </Button>
        </Box>
      </Box>
      <OrganizationalUnitsTable
        order={organizationalUnitsOrder}
        setOrder={setOrganizationalUnitsOrder}
        isLoading={isLoading}
        organizationalNodes={departmentsOrganizationalUnits || []}
        currentPage={organizationalUnitsPage}
        setCurrentPage={setOrganizationalUnitsPage}
        onRowClick={handleRowClick}
      />
    </Box>
  );
}
