// Slices
import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/joy';
import { CSSProperties } from '@mui/styled-engine';
import { iconForCertificationStatus } from 'utils/iconography';

type AgentFileCertificationsTableProps = {
  certifications?: any[];
  isLoading?: boolean;
  sx?: CSSProperties;
  small?: boolean;
};

export default function AgentFileCertificationsTable({
  certifications,
  isLoading,
  sx,
  small,
}: AgentFileCertificationsTableProps) {
  return (
    <Box sx={sx}>
      {isLoading ? (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : certifications && certifications.length > 0 ? (
        <Stack divider={<Divider />}>
          {certifications &&
            certifications.map((certificate) => (
              <Stack
                direction={'row'}
                justifyContent="space-between"
                key={certificate.objectId}
                sx={{ p: 1 }}
              >
                <Stack>
                  <Typography fontWeight="bold">
                    {certificate.certificationBase.code}
                  </Typography>
                  <Typography>
                    {certificate.certificationBase.description}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  {iconForCertificationStatus(certificate.status)}
                  {!small && (
                    <Typography>
                      {new Date(certificate.date.iso).toLocaleDateString(
                        'fr-CA'
                      )}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            ))}
        </Stack>
      ) : (
        <Typography textAlign="center" sx={{ p: 1 }}>
          Aucune certification
        </Typography>
      )}
    </Box>
  );
}
