import {
  faBuildingUser,
  faHouse,
  faLifeRing,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '@mui/joy/Avatar';
import Divider from '@mui/joy/Divider';
import GlobalStyles from '@mui/joy/GlobalStyles';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Sheet from '@mui/joy/Sheet';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import axios from 'utils/axios';
import logo from '../../../../../assets/images/logos/logo_icon.svg';
import PickDeptContextModal from '../../common/PickDeptContextModal';
import DeptLogo from '../DeptLogo';

export default function MainSidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const { user, refreshUser } = useAuth();

  const [deptContextPickModalOpen, setDeptContextPickModalOpen] =
    useState(false);

  const [departmentContext, setDepartmentContext] = useLocalStorage<string>(
    'departmentContext',
    ''
  );

  const setContextDepartment = (deptId: string | null) => {
    console.log('Setting dept context...: ', deptId);
    if (deptId) {
      setDepartmentContext(deptId);
      axios.defaults.headers.common['x-department'] = deptId;
    } else {
      setDepartmentContext('');
      delete axios.defaults.headers.common['x-department'];
    }
    navigate('/app');
    refreshUser();
    setDeptContextPickModalOpen(false);
  };

  return (
    <Sheet
      className="FirstSidebar"
      variant="soft"
      color="primary"
      invertedColors
      sx={{
        position: {
          xs: 'fixed',
          md: 'sticky',
        },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--FirstSidebar-width)',
        top: 0,
        p: 1.5,
        py: 3,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={{
          ':root': {
            '--FirstSidebar-width': '68px',
          },
        }}
      />
      <DeptLogo
        sx={{ cursor: user?.isAdmin ? 'pointer' : 'default' }}
        onClick={() => {
          if (user?.isAdmin) {
            setDeptContextPickModalOpen(true);
          }
        }}
      />
      <PickDeptContextModal
        open={deptContextPickModalOpen}
        onClose={() => setDeptContextPickModalOpen(false)}
        onChangeContext={setContextDepartment}
        currentContext={departmentContext}
      />
      <List sx={{ '--ListItem-radius': '8px', '--List-gap': '12px' }}>
        <ListItem>
          <ListItemButton
            sx={{ justifyContent: 'center' }}
            selected={location.pathname.includes('/citizen')}
            variant={location.pathname.includes('/citizen') ? 'solid' : 'plain'}
            color="primary"
            onClick={() => {
              navigate('/app/citizen');
            }}
          >
            <FontAwesomeIcon icon={faHouse} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            sx={{ justifyContent: 'center' }}
            selected={location.pathname.includes('/department')}
            variant={
              location.pathname.includes('/department') ? 'solid' : 'plain'
            }
            color="primary"
            onClick={() => {
              navigate('/app/department');
            }}
          >
            <FontAwesomeIcon icon={faBuildingUser} />
          </ListItemButton>
        </ListItem>
      </List>
      <List
        sx={{
          mt: 'auto',
          flexGrow: 0,
          '--ListItem-radius': '8px',
          '--List-gap': '8px',
        }}
      >
        <ListItem>
          <ListItemButton sx={{ justifyContent: 'center' }}>
            <FontAwesomeIcon icon={faLifeRing} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <Avatar variant="plain" src={logo} />
    </Sheet>
  );
}
