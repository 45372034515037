import LoadingStack from 'components/common/LoadingStack';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Login from '../pages/app/views/AuthControllers/Login';
// hooks
import useAuth from '../hooks/useAuth';
// pages

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard() {
  const { isAuthenticated, isLoading, isInitialized, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (isLoading || !isInitialized) return <LoadingStack />;

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (!user?.agentFile) return <Navigate to={'/app/nolink'} />;

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <Outlet />;
}
