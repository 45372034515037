import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, Typography } from '@mui/joy';
import { useState } from 'react';
import { User } from '../../../../../@types/user';
import AgentFileCertificationAddModal from './AgentFileCertificationAddModal';
import AgentFileCertificationsTable from './AgentFileCertificationsTable';

interface AgentsCertificationsCenterCertificatesProps {
  agentFile?: User;
  certifications?: any[];
  isLoading?: boolean;
  onRefresh?: () => void;
}

export default function AgentsCertificationsCenterCertificates({
  agentFile,
  certifications,
  isLoading,
  onRefresh,
}: AgentsCertificationsCenterCertificatesProps) {
  const [agentsCertificationAddModalOpen, setAgentsCertificationAddModalOpen] =
    useState(false);

  const handleAgentsCertificationAddModalOpen = () => {
    setAgentsCertificationAddModalOpen(true);
  };

  const handleAddedCertificationFromModal = () => {
    setAgentsCertificationAddModalOpen(false);
    onRefresh && onRefresh();
  };

  return (
    <Stack spacing={2}>
      <AgentFileCertificationAddModal
        open={agentsCertificationAddModalOpen}
        onClose={handleAddedCertificationFromModal}
        agentFile={agentFile}
      />
      <Stack>
        <Typography level="h6">Certifications</Typography>
        <Typography level="body2">
          Ici sont listés toutes les certifications et les permis détenus par la
          personne. Vous pouvez ajouter des certifications manuellement ou
          rendre un examen disponible via l'onglet "Examens" pour que la
          personne puisse le passer et obtenir la certification automatiquement.
        </Typography>
      </Stack>
      <Stack sx={{ overflowY: 'scroll', maxHeight: '50vh' }}>
        <AgentFileCertificationsTable
          certifications={certifications}
          isLoading={isLoading}
          sx={{
            border: '1px solid #fff5',
            padding: '10px',
            borderRadius: 1,
            background: '#202020',
          }}
        />
      </Stack>
      <Stack direction="row" justifyContent="end" alignItems="center">
        <Button
          variant="solid"
          startDecorator={<AddIcon />}
          onClick={handleAgentsCertificationAddModalOpen}
        >
          Ajouter
        </Button>
      </Stack>
    </Stack>
  );
}
