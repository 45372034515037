import { createSlice } from '@reduxjs/toolkit';
// utils
import { User } from '../../@types/user';
import config from '../../config';
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type DepartmentsUsersState = {
  isLoading: boolean;
  error: boolean;
  users?: User[] | null;
};

const initialState: DepartmentsUsersState = {
  isLoading: false,
  error: false,
  users: null,
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // EMPTY PROFILE
    emptyDepartmentsUsers(state) {
      state.isLoading = false;
      state.users = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getDepartmentsSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },
  },
});

// Actions
// ----------------------------------------------------------------------

export function getDepartmentsUsers(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get(
        `${config.api_url}/departments/${id}/members`
      );
      dispatch(slice.actions.getDepartmentsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function emptyDepartmentsUsers() {
  return () => {
    dispatch(slice.actions.emptyDepartmentsUsers());
  };
}

// Reducer
export default slice.reducer;
