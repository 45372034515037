import { faChevronRight, faHouse } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Breadcrumbs, Link, Typography } from '@mui/joy';
import { fetchApplications } from 'api/departments/applications';
import ApplicationsTable from 'pages/app/components/Applications/ApplicationsTable';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ColorSchemeToggle from '../../components/Main/ColorSchemeToggle';

export default function ApplicationsList() {
  const navigate = useNavigate();

  const [applications, setApplications] = useState<any[]>([]);
  const [applicationsOrder, setApplicationsOrder] = useState<'asc' | 'desc'>(
    'desc'
  );
  const [applicationsPage, setApplicationsPage] = useState(1);
  const [applicationsRowPerPage] = useState(15);
  const [applicationsTotal, setApplicationsTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleRowClick = (id: string) => {
    navigate(`/app/department/applications/${id}`);
  };

  const fetchApps = useCallback(async () => {
    setIsLoading(true);
    const { total, list } = await fetchApplications(
      applicationsPage,
      applicationsRowPerPage,
      applicationsOrder,
      'createdAt'
    );
    setApplications(list);
    setApplicationsTotal(total);
    setIsLoading(false);
  }, [applicationsPage, applicationsRowPerPage, applicationsOrder]);

  useEffect(() => {
    fetchApps();
  }, [fetchApps]);

  return (
    <Box
      component="main"
      className="MainContent"
      sx={(theme) => ({
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
          sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100dvh',
        gap: 1,
        bgcolor: 'background.body',
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<FontAwesomeIcon icon={faChevronRight} />}
          sx={{
            '--Breadcrumbs-gap': '1rem',
            '--Icon-fontSize': '16px',
            fontWeight: 'lg',
            color: 'neutral.400',
            px: 0,
          }}
        >
          <Link
            underline="none"
            color="neutral"
            fontSize="inherit"
            onClick={() => navigate('/app')}
            aria-label="Home"
          >
            <FontAwesomeIcon icon={faHouse} />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            fontSize="inherit"
            onClick={() => navigate('/app')}
          >
            Tableau de bord
          </Link>
          <Typography fontSize="inherit" variant="soft" color="primary">
            Applications
          </Typography>
        </Breadcrumbs>
        <ColorSchemeToggle
          sx={{ ml: 'auto', display: { xs: 'none', md: 'inline-flex' } }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          my: 1,
          gap: 1,
          flexWrap: 'wrap',
          '& > *': {
            minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
            flexGrow: 1,
          },
        }}
      >
        <Typography level="h1" fontSize="xl4">
          Applications
        </Typography>
        <Box sx={{ flex: 999 }} />
      </Box>
      <ApplicationsTable
        applications={applications}
        order={applicationsOrder}
        setOrder={setApplicationsOrder}
        isLoading={isLoading}
        currentPage={applicationsPage}
        setCurrentPage={setApplicationsPage}
        onRowClick={handleRowClick}
        rowsPerPage={applicationsRowPerPage}
        total={applicationsTotal}
      />
    </Box>
  );
}
