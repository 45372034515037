import config from '../../config';
import axios from '../../utils/axios';

export async function fetchApplications(
  page: number,
  limit: number,
  order: 'asc' | 'desc' = 'asc',
  orderBy: string = 'lastName'
): Promise<{ total: number; list: any[] }> {
  const { data } = await axios.get(
    `${config.api_url}/departments/applications?page=${page}&limit=${limit}&orderBy=createdAt&order=${order}&orderBy=${orderBy}`
  );
  return data;
}

export async function fetchApplication(id: string): Promise<any> {
  const { data } = await axios.get(
    `${config.api_url}/departments/applications/${id}`
  );
  return data;
}

export async function acceptApplicationWithId(id: string): Promise<any> {
  const { data } = await axios.post(
    `${config.api_url}/departments/applications/${id}/accept`
  );
  return data;
}

export async function denyApplicationWithId(
  id: string,
  reason: string
): Promise<any> {
  const { data } = await axios.post(
    `${config.api_url}/departments/applications/${id}/deny`
  );
  return data;
}

export async function reopenApplicationWithId(id: string): Promise<any> {
  const { data } = await axios.post(
    `${config.api_url}/departments/applications/${id}/reopen`
  );
  return data;
}
