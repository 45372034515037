import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import { CertificationStatus } from '../@types/user';

export const iconForCertificationStatus = (status: CertificationStatus) => {
  switch (status) {
    case 'passed':
      return <CheckCircleRoundedIcon sx={{ color: '#4caf50' }} />;
    case 'failed':
      return <CancelRoundedIcon sx={{ color: '#f44336' }} />;
    case 'toComplete':
      return <WatchLaterRoundedIcon sx={{ color: '#ff9800' }} />;
    default:
      return <></>;
  }
};
