import { faTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Divider,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';

type ConfirmModalProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
  description?: string;
  confirmText?: string;
  showLoading?: boolean;
};

export default function ConfirmModal({
  open,
  onCancel,
  onConfirm,
  title,
  description,
  confirmText,
  showLoading,
}: ConfirmModalProps) {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onCancel()}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="md"
        sx={{
          minWidth: '25%',
          maxWidth: '35%',
        }}
      >
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<FontAwesomeIcon icon={faTriangleExclamation} />}
        >
          {title || 'Confirmation'}
        </Typography>
        <Divider />
        <Stack spacing={1} sx={{ mt: 2 }}>
          <Typography id="alert-dialog-modal-description" level="body1">
            {description || 'Êtes-vous sûr de vouloir continuer ?'}
          </Typography>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="center"
          spacing={2}
        >
          <Button variant="soft" color="danger" onClick={() => onCancel()}>
            Annuler
          </Button>
          <Button
            variant="solid"
            color="primary"
            onClick={onConfirm}
            loading={showLoading}
          >
            {confirmText || 'Confirmer'}
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
