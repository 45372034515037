/* eslint-disable jsx-a11y/anchor-is-valid */
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, CircularProgress, Stack } from '@mui/joy';
import Link from '@mui/joy/Link';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import {
  colorForApplicationStatus,
  iconForApplicationStatus,
  labelForApplicationStatus,
  stringForDuration,
} from 'utils/strings';
import TableFooter from '../common/TableFooter';

type Order = 'asc' | 'desc';

type ApplicationsTableProps = {
  order: Order;
  setOrder: (order: Order) => void;
  applications: any[];
  isLoading: boolean;
  currentPage: number;
  setCurrentPage: (cb: (oldValue: any) => number) => void;
  onRowClick: (id: string) => void;
  rowsPerPage: number;
  total: number;
};

export default function ApplicationsTable({
  order,
  setOrder,
  applications,
  isLoading,
  currentPage,
  setCurrentPage,
  onRowClick,
  rowsPerPage,
  total,
}: ApplicationsTableProps) {
  const handleNextPage = () => {
    setCurrentPage((page) => page + 1);
  };
  const handlePreviousPage = () => {
    setCurrentPage((page) => page - 1);
  };

  //   const [filtersOpen, setFiltersOpen] = React.useState(false);
  //   const renderFilters = () => <React.Fragment></React.Fragment>;
  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'md',
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
            <CircularProgress />
          </Stack>
        ) : (
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': (theme) =>
                theme.vars.palette.background.level1,
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': (theme) =>
                theme.vars.palette.background.level1,
            }}
          >
            <thead>
              <tr>
                <th style={{ padding: 12 }}>#</th>
                <th style={{ padding: 12 }}>Nom</th>
                <th style={{ padding: 12 }}>Statut</th>
                <th style={{ padding: 12 }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                    fontWeight="lg"
                    endDecorator={<FontAwesomeIcon icon={faArrowDown} />}
                    sx={{
                      '& svg': {
                        transition: '0.2s',
                        transform:
                          order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                      },
                    }}
                  >
                    Ouvert depuis
                  </Link>
                </th>
              </tr>
            </thead>

            <tbody>
              {applications?.map((application) => (
                <tr
                  key={application.objectId}
                  onClick={() => onRowClick(application.objectId)}
                  style={{ cursor: 'pointer' }}
                >
                  <td style={{ paddingLeft: '12px' }}>
                    <Typography fontWeight="md">
                      {application.ticketNumber}
                    </Typography>
                  </td>
                  <td style={{ paddingLeft: '12px' }}>
                    <Typography
                      fontWeight="md"
                      textColor={
                        application.status === 'pending'
                          ? 'text.primary'
                          : 'text.secondary'
                      }
                    >
                      <strong>{application.lastName}</strong>,{' '}
                      {application.firstName}
                    </Typography>
                  </td>
                  <td>
                    <Chip
                      size="md"
                      color={colorForApplicationStatus(application.status)}
                      startDecorator={
                        <FontAwesomeIcon
                          icon={iconForApplicationStatus(application.status)}
                        />
                      }
                      variant={
                        application.status === 'pending' ? 'soft' : 'plain'
                      }
                    >
                      {labelForApplicationStatus(application.status)}
                    </Chip>
                  </td>
                  <td>
                    <Typography fontWeight="md">
                      {stringForDuration(
                        Math.floor(
                          (new Date().valueOf() -
                            new Date(application.createdAt).valueOf()) /
                            1000
                        )
                      )}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Sheet>
      <TableFooter
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        total={total}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        loading={isLoading}
      />
    </React.Fragment>
  );
}
