import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
  Alert,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Textarea,
} from '@mui/joy';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import { denyApplicationWithId } from 'api/departments/applications';
import { Fragment, useCallback, useState } from 'react';

type ApplicationDenyModalProps = {
  open: boolean;
  onClose: () => void;
  application: any;
  onRefresh: (application: any) => void;
};

export default function ApplicationDenyModal({
  open,
  onClose,
  application,
  onRefresh,
}: ApplicationDenyModalProps) {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState('');

  const handleDeny = useCallback(async () => {
    try {
      setIsLoading(true);
      const refreshedApplication = await denyApplicationWithId(
        application.objectId,
        reason
      );
      setIsLoading(false);
      onRefresh(refreshedApplication);
    } catch (error: any) {
      setIsLoading(false);
      setError(error);
    }
  }, [application, reason, onRefresh]);

  return (
    <Fragment>
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Typography
            id="alert-dialog-modal-title"
            component="h2"
            startDecorator={<WarningRoundedIcon />}
          >
            Refuser la candidature
          </Typography>
          <Divider />
          <Stack spacing={2} mt={2} mb={2}>
            {error && <Alert color="danger">{JSON.stringify(error)}</Alert>}
            <Stack spacing={1}>
              <Typography level="body1">
                Voulez-vous vraiment annuler la candidature de{' '}
                <strong>
                  {application.firstName} {application.lastName}
                </strong>{' '}
                ?
              </Typography>
              <Typography textColor="text.tertiary" level="body2">
                L'applicant devra recommencer le processus pour appliquer de
                nouveau.
              </Typography>
            </Stack>
            <FormControl>
              <FormLabel htmlFor="reason">Raison du refus</FormLabel>
              <Textarea
                id="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                minRows={3}
              />
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
          >
            <Button variant="plain" color="danger" onClick={onClose}>
              Annuler
            </Button>
            <Button
              variant="solid"
              color="primary"
              onClick={handleDeny}
              loading={isLoading}
            >
              Confirmer le refus
            </Button>
          </Stack>
        </ModalDialog>
      </Modal>
    </Fragment>
  );
}
