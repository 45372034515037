import { Box, Button, Divider, Stack, Typography } from '@mui/joy';
import { useColorScheme } from '@mui/joy/styles';
import { useEffect } from 'react';
import { apply, rules } from 'translations';
import { useLocalStorage } from 'usehooks-ts';
import ApplyHeader from './common/ApplyHeader';

type RulesViewProps = {
  onAccept: () => void;
  onDecline: () => void;
};

export default function RulesView({ onAccept, onDecline }: RulesViewProps) {
  const { setMode } = useColorScheme();

  const [lang] = useLocalStorage<'fr' | 'en'>('lang', 'fr');

  useEffect(() => {
    setMode('dark');
  }, [setMode]);

  const { serverwide } = rules[lang];

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100dvh',
        width:
          'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
        maxWidth: '100%',
        px: 2,
      }}
    >
      <ApplyHeader />
      <Box
        component="main"
        sx={{
          my: 'auto',
          p: 5,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '75%',
          mx: 'auto',
          borderRadius: 'sm',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Stack spacing={2} divider={<Divider />}>
          <Stack>
            <Typography level="h3">
              {apply[lang].rulesAcceptanceTitle}
            </Typography>
            <Typography level="h5">
              {apply[lang].rulesAcceptanceText}
            </Typography>
          </Stack>
          <Stack sx={{ height: '50vh', overflowY: 'scroll' }}>
            <Stack spacing={2}>
              {serverwide.map((section, index) => {
                return (
                  <Stack key={index}>
                    <Stack direction="row" spacing={1}>
                      <Typography level="h5" color="neutral">
                        {index + 1}
                      </Typography>
                      <Typography level="h5">{section.sectionName}</Typography>
                    </Stack>
                    {section.articles.map((article, index) => {
                      return (
                        <Stack key={index} sx={{ ml: 2 }}>
                          <Stack direction="row" spacing={1}>
                            <Typography level="body1" color="neutral">
                              {index + 1}
                            </Typography>
                            <Typography level="body1">{article}</Typography>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Button variant="plain" color="danger" onClick={onDecline}>
              {apply[lang].btnActionDecline}
            </Button>
            <Button variant="solid" color="primary" onClick={onAccept}>
              {apply[lang].btnActionAccept}
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Box component="footer" sx={{ py: 3 }}>
        <Stack spacing={2}>
          <Typography level="body3" textAlign="center">
            © FivePD MTL {new Date().getFullYear()}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}
