import { CircularProgress, Sheet, Stack, Typography } from '@mui/joy';

// Beauregard est un espèce de malade dans les zones de villages il roule 90 km/h

export default function LoadingStack() {
  return (
    <Stack
      flex="1 1 0px"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ background: '#111', color: '#fff' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: '25%',
          textAlign: 'center',
          p: 3,
          background: '#202020',
          color: '#fff',
        }}
      >
        <Stack spacing={2} justifyContent="center" alignContent="center">
          <Typography level="h6">Chargement...</Typography>
          <Stack direction="row" justifyContent="center">
            <CircularProgress />
          </Stack>
        </Stack>
      </Sheet>
    </Stack>
  );
}
