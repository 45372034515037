import AuthGuard from 'guards/AuthGuard';
import UnlinkedAuthGuard from 'guards/UnlinkedAuthGuard';
import MeLayout from 'layouts/Me';
import Home from 'pages/Home';
import AuthResult from 'pages/app/AuthResult';
import DashboardApp from 'pages/app/DashboardApp';
import CitizenController from 'pages/app/components/Contexts/CitizenController';
import DepartmentController from 'pages/app/components/Contexts/DepartmentController';
import ApplicationViewer from 'pages/app/views/Applications/ApplicationViewer';
import ApplicationsList from 'pages/app/views/Applications/ApplicationsList';
import ApplyController from 'pages/app/views/Applications/ApplyController';
import DepartmentApplyController from 'pages/app/views/Applications/DepartmentApplyController';
import LinkController from 'pages/app/views/Applications/LinkController';
import NoLinkController from 'pages/app/views/AuthControllers/NoLinkController';
import CitizenHome from 'pages/app/views/Citizen/CitizenHomeView';
import NotFound from 'pages/app/views/NotFound';
import CertificationsList from 'pages/app/views/certifications/CertificationsList';
import FileViewer from 'pages/app/views/files/FileViewer';
import FilesList from 'pages/app/views/files/FilesList';
import MyFileView from 'pages/app/views/files/MyFileView';
import OrganizationalUnitsList from 'pages/app/views/organizationalUnits/OrganizationalUnitsList';
import RanksList from 'pages/app/views/ranks/RanksList';
import Page404 from 'pages/server-errors/404';
import { Navigate, useRoutes } from 'react-router-dom';
// guards

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: 'app',
      element: <MeLayout />,
      children: [
        {
          path: 'nolink',
          element: <UnlinkedAuthGuard />,
          children: [
            {
              path: '',
              element: <NoLinkController />,
            },
            {
              path: 'apply/:departmentShortName',
              element: <DepartmentApplyController />,
            },
            {
              path: 'apply',
              element: <ApplyController />,
            },
            {
              path: 'link',
              element: <LinkController />,
            },
          ],
        },
        {
          path: '',
          element: <AuthGuard />,
          children: [
            {
              path: '',
              element: <DashboardApp />,
              children: [
                {
                  path: 'department',
                  element: <DepartmentController />,
                  children: [
                    {
                      path: 'me',
                      children: [{ path: '', element: <MyFileView /> }],
                    },
                    {
                      path: 'files',
                      children: [
                        { path: ':agentId', element: <FileViewer /> },
                        { path: '', element: <FilesList /> },
                      ],
                    },
                    {
                      path: 'certifications',
                      children: [{ path: '', element: <CertificationsList /> }],
                    },
                    {
                      path: 'applications',
                      children: [
                        { path: '', element: <ApplicationsList /> },
                        {
                          path: ':applicationId',
                          element: <ApplicationViewer />,
                        },
                      ],
                    },
                    { path: 'ranks', element: <RanksList /> },
                    { path: 'orgunits', element: <OrganizationalUnitsList /> },
                    {
                      path: '',
                      element: <Navigate to="/app/department/me" />,
                    },
                    { path: '*', element: <NotFound /> },
                  ],
                },
                {
                  path: 'citizen',
                  element: <CitizenController />,
                  children: [
                    {
                      path: 'me',
                      element: <CitizenHome />,
                    },
                    {
                      path: '',
                      element: <Navigate to="/app/citizen/me" />,
                    },
                    { path: '*', element: <NotFound /> },
                  ],
                },
                {
                  path: '',
                  element: <Navigate to="/app/citizen" />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '',
      element: <MeLayout />,
      children: [
        { path: '', element: <Home /> },
        { path: 'authResult', element: <AuthResult /> },
        { path: '*', element: <Navigate to="/" /> },
      ],
    },
    { path: '*', element: <Page404 /> },
  ]);
}
