import { AnyAction, createSlice, ThunkDispatch } from '@reduxjs/toolkit';
import { FPRank } from '../../@types/department';
// utils
import config from '../../config';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

type DepartmentsRanksState = {
  isLoading: boolean;
  error: boolean;
  ranks?: FPRank[] | null;
};

const initialState: DepartmentsRanksState = {
  isLoading: false,
  error: false,
  ranks: null,
};

const slice = createSlice({
  name: 'ranks',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // EMPTY PROFILE
    emptyDepartmentsRanks(state) {
      state.isLoading = false;
      state.ranks = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getDepartmentsRanksSuccess(state, action) {
      console.log('Success!');
      state.isLoading = false;
      state.ranks = action.payload;
    },
  },
});

// Actions
// ----------------------------------------------------------------------

export function getDepartmentsRanks() {
  return async (dispatch: ThunkDispatch<any, undefined, AnyAction>) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get(`${config.api_url}/departments/ranks`);
      dispatch(slice.actions.getDepartmentsRanksSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function emptyDepartmentsRanks() {
  return (dispatch: ThunkDispatch<any, undefined, AnyAction>) => {
    dispatch(slice.actions.emptyDepartmentsRanks());
  };
}

// Reducer
export default slice.reducer;
