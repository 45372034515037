import {
  Alert,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import { useCallback, useState } from 'react';
import config from '../../../../config';
import axios from '../../../../utils/axios';
// Slices
import { faEdit } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import useAuth from 'hooks/useAuth';
import { FPOrganizationalNode, FPRank } from '../../../../@types/department';
import ConfirmModal from '../common/ConfirmModal';
import { JoyDatePicker } from '../common/JoyDatePicker';

type AgentFileEditModalProps = {
  open: boolean;
  onClose: (updatedFile?: any) => void;
  ranks?: FPRank[] | null;
  organizationalNodes?: FPOrganizationalNode[] | null;
  agentFile: any;
};

export default function AgentFileEditModal({
  open,
  onClose,
  ranks,
  organizationalNodes,
  agentFile,
}: AgentFileEditModalProps) {
  const { user } = useAuth();

  const [firstName, setFirstName] = useState<string>(agentFile.firstName);
  const [lastName, setLastName] = useState<string>(agentFile.lastName);
  const [rankId, setRankId] = useState<string>(
    agentFile.currentRank ? agentFile.currentRank.objectId : ''
  );
  const [status, setStatus] = useState<string>(agentFile.status);
  const [organizationalNodePath, setOrganizationalNodePath] = useState<string>(
    agentFile.organizationalNodePath
  );
  const [position, setPosition] = useState<string>(agentFile.position);
  const [citizenId, setCitizenId] = useState<string>(agentFile.citizenId);
  const [error, setError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [entryDate, setEntryDate] = useState<Dayjs | null>(
    agentFile.entryDate ? dayjs(agentFile.entryDate.iso) : dayjs()
  );
  const [isTrainer, setIsTrainer] = useState<boolean>(
    agentFile.isTrainer || false
  );

  // Confirm modal
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState('');
  const [confirmModalMessage, setConfirmModalMessage] = useState('');

  const handleConfirmModalCancel = () => {
    setOpenConfirmModal(false);
  };

  const handleConfirmModalConfirm = () => {
    handleEditAgentFile();
  };

  const handleEditAgentFile = useCallback(async () => {
    setIsUpdating(true);
    try {
      const { data } = await axios.put(
        `${config.api_url}/departments/agents/files/${agentFile.objectId}`,
        {
          firstName: firstName === agentFile.firstName ? undefined : firstName,
          lastName: lastName === agentFile.lastName ? undefined : lastName,
          rankId:
            agentFile.currentRank && rankId === agentFile.currentRank.objectId
              ? undefined
              : rankId,
          organizationalNodePath:
            organizationalNodePath === agentFile.organizationalNodePath
              ? undefined
              : organizationalNodePath,
          position: position === agentFile.position ? undefined : position,
          citizenId: citizenId === agentFile.citizenId ? undefined : citizenId,
          entryDate:
            agentFile.entryDate &&
            agentFile.entryDate.iso &&
            entryDate?.toISOString() === agentFile.entryDate.iso
              ? undefined
              : entryDate?.toISOString(),
          isTrainer: isTrainer === agentFile.isTrainer ? undefined : isTrainer,
          status: status === agentFile.status ? undefined : status,
        }
      );

      setOpenConfirmModal(false);
      onClose(data);
    } catch (error) {
      console.error(error);
      setError(error as string);
    }
    setIsUpdating(false);
  }, [
    onClose,
    firstName,
    lastName,
    rankId,
    organizationalNodePath,
    position,
    citizenId,
    agentFile,
    entryDate,
    status,
    isTrainer,
  ]);

  const handleEditAgentFilePreflight = useCallback(async () => {
    console.log({
      organizationalNodePath,
      supervisorNodePath: user?.agentFile?.organizationalNodePath,
    });
    if (
      organizationalNodePath.indexOf(
        user?.agentFile?.organizationalNodePath
      ) !== 0
    ) {
      setConfirmModalTitle(`Unité organizationnelle hors d'accès`);
      setConfirmModalMessage(
        "Vous êtes sur le point de déplacer ce dossier dans une unité organisationnelle à laquelle nous n'avez pas accès. Il ne vous sera plus possible d'y accéder. Vous aurez besoin d'un superviseur ayant accès à l'unité organisationnelle cible pour regagner l'accès. Êtes-vous sûr de vouloir continuer?"
      );
      setOpenConfirmModal(true);
    } else {
      handleEditAgentFile();
    }
  }, [organizationalNodePath, user, handleEditAgentFile]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="lg"
        sx={{
          minWidth: '50%',
        }}
      >
        <ConfirmModal
          title={confirmModalTitle}
          description={confirmModalMessage}
          open={openConfirmModal}
          onCancel={handleConfirmModalCancel}
          onConfirm={handleConfirmModalConfirm}
          confirmText="Confirmer"
          showLoading={isUpdating}
        />
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<FontAwesomeIcon icon={faEdit} />}
        >
          Modifier le dossier
        </Typography>
        <Divider />
        <Stack spacing={2} sx={{ p: 2 }}>
          {error && (
            <Alert variant="soft" color="danger">
              {JSON.stringify(error)}
            </Alert>
          )}
          <Stack direction="row" spacing={2}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Nom de famille</FormLabel>
              <Input
                placeholder="Nom de famille"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Prénom</FormLabel>
              <Input
                placeholder="Prénom"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <FormControl sx={{ flex: 0.75 }}>
              <FormLabel>Date de début de service</FormLabel>
              <JoyDatePicker
                value={entryDate}
                onChange={(e) => setEntryDate(e)}
              />
            </FormControl>
            <FormControl sx={{ flex: 0.25 }}>
              <FormLabel>Statut</FormLabel>
              <Select
                placeholder="Statut"
                slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                onChange={(e, value) => {
                  if (e && value) setStatus(value);
                }}
                value={status}
              >
                <Option key="active" value="active">
                  Actif
                </Option>
                <Option key="inactive" value="inactive">
                  Inactif
                </Option>
                <Option key="terminated" value="terminated">
                  Renvoyé
                </Option>
                <Option key="suspended" value="suspended">
                  Suspendu
                </Option>
                <Option key="departed" value="departed">
                  Quitté
                </Option>
              </Select>
            </FormControl>
          </Stack>
          <FormControl>
            <FormLabel>Grade</FormLabel>
            <Select
              placeholder="Sélectionner un grade"
              slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
              onChange={(e, value) => {
                if (e && value) setRankId(value);
              }}
              value={rankId}
            >
              {ranks?.map((rank) => (
                <Option key={rank.objectId} value={rank.objectId}>
                  {rank.name}
                </Option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Unité organisationnelle</FormLabel>
            <Select
              placeholder="Sélectionner une unité organisationnelle"
              slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
              onChange={(e, value) => {
                if (e && value) setOrganizationalNodePath(value);
              }}
              value={organizationalNodePath}
            >
              {organizationalNodes?.map((organizationalNode) => (
                <Option
                  key={organizationalNode.id}
                  value={organizationalNode.path}
                  sx={{
                    pl: `${20 * organizationalNode.path.split('.').length}px`,
                  }}
                >
                  {organizationalNode.name}
                </Option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Poste / Position</FormLabel>
            <Input
              placeholder="Directeur, Sergent de relève, etc..."
              value={position}
              onChange={(e) => {
                setPosition(e.target.value);
              }}
            />
            <FormHelperText>
              Le poste / position sert à préciser le rôle d'un gradé dans le
              département. Par example, à la Sûreté du Québec, un Capitaine peux
              avoir le rôle de Directeur d'une division. On inscrirait donc
              "Directeur de la division ABC" ici.
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>Numéro d'assurance social</FormLabel>
            <Input
              placeholder="NAS"
              value={citizenId}
              onChange={(e) => {
                setCitizenId(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Cet agent est-il formateur?</FormLabel>
            <Checkbox
              label="Oui, cet agent est formateur"
              checked={isTrainer}
              onChange={(e) => {
                setIsTrainer(e.target.checked);
              }}
            />
          </FormControl>
        </Stack>
        <Divider />
        <Stack
          spacing={2}
          direction="row"
          justifyContent="end"
          sx={{ paddingTop: '24px' }}
        >
          <Button
            color="danger"
            variant="soft"
            onClick={() => onClose()}
            disabled={isUpdating}
          >
            Annuler
          </Button>
          <Button
            variant="solid"
            color="primary"
            loading={isUpdating}
            onClick={handleEditAgentFilePreflight}
          >
            Mettre à jour
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
