import { createSlice } from '@reduxjs/toolkit';
// utils
import { FPDepartment } from '../../@types/department';
import config from '../../config';
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type DepartmentState = {
  isLoading: boolean;
  error: boolean;
  department?: FPDepartment | null;
};

const initialState: DepartmentState = {
  isLoading: false,
  error: false,
  department: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // EMPTY PROFILE
    emptyApplication(state) {
      state.isLoading = false;
      state.department = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getApplicationSuccess(state, action) {
      state.isLoading = false;
      state.department = action.payload;
    },
  },
});

// Actions
// ----------------------------------------------------------------------

export function getDepartment(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get(`${config.api_url}/departments/${id}`);
      dispatch(slice.actions.getApplicationSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function emptyApplication() {
  return () => {
    dispatch(slice.actions.emptyApplication());
  };
}

// Reducer
export default slice.reducer;
