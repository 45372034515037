import { faUser } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Stack, Typography } from '@mui/joy';
import UserDepartmentLogo from 'components/departments/UserDepartmentLogo';

type AgentFileIDCardProps = {
  agentFile?: any;
};

export default function AgentFileIDCard({ agentFile }: AgentFileIDCardProps) {
  return (
    <Card variant="outlined" sx={{ background: '#fff' }}>
      <Stack spacing={2} alignItems="center">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: '203px', height: '254px', background: '#999' }}
        >
          <FontAwesomeIcon icon={faUser} size="6x" />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '203px' }}
        >
          <Stack>
            <Typography
              level="h6"
              fontWeight="bold"
              textTransform={'uppercase'}
              sx={{ color: '#111', lineHeight: 1 }}
            >
              {agentFile?.lastName},
            </Typography>
            <Typography
              level="h6"
              fontWeight="bold"
              textTransform={'uppercase'}
              sx={{ color: '#111', lineHeight: 1 }}
            >
              {agentFile?.firstName}
            </Typography>
            <Typography
              level="body3"
              fontWeight="bold"
              sx={{ color: '#111', lineHeight: 1 }}
            >
              {agentFile?.currentRank
                ? agentFile?.currentRank?.name
                : 'Rang inexistant'}
            </Typography>
          </Stack>
          <UserDepartmentLogo
            sx={{
              width: '40px',
              padding: '5px',
            }}
            agentFile={agentFile}
          />
        </Stack>

        <Typography
          sx={{
            fontFamily: `'Libre Barcode 39 Text', cursive`,
            color: '#111',
            textAlign: 'center',
            fontSize: '2rem',
          }}
        >
          {agentFile?.citizenId}
        </Typography>
      </Stack>
    </Card>
  );
}
