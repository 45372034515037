import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Alert, Button, Stack } from '@mui/joy';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import { acceptApplicationWithId } from 'api/departments/applications';
import { Fragment, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type ApplicationAcceptModalProps = {
  open: boolean;
  onClose: () => void;
  application: any;
};

export default function ApplicationAcceptModal({
  open,
  onClose,
  application,
}: ApplicationAcceptModalProps) {
  const navigate = useNavigate();

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleAccept = useCallback(async () => {
    try {
      setIsLoading(true);
      const resultFile = await acceptApplicationWithId(application.objectId);
      setIsLoading(false);
      navigate(`/app/department/files/${resultFile.objectId}`);
    } catch (error: any) {
      setIsLoading(false);
      setError(error);
    }
  }, [application, navigate]);

  return (
    <Fragment>
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Typography
            id="alert-dialog-modal-title"
            component="h2"
            startDecorator={<WarningRoundedIcon />}
          >
            Accepter la candidature
          </Typography>
          <Divider />
          {error && <Alert color="danger">{JSON.stringify(error)}</Alert>}
          <Typography
            id="alert-dialog-modal-description"
            textColor="text.tertiary"
          >
            Voulez-vous vraiment accepter la candidature de{' '}
            <strong>
              {application.firstName} {application.lastName}
            </strong>{' '}
            ?<br />
            - Un dossier sera ouvert automatiquement.
            <br />- Le canal de discussion du ticket sera fermé, il ne sera plus
            possible d'y écrire.
          </Typography>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
          >
            <Button variant="plain" color="danger" onClick={onClose}>
              Annuler
            </Button>
            <Button
              variant="solid"
              color="primary"
              onClick={handleAccept}
              loading={isLoading}
            >
              Confirmer l'acceptation
            </Button>
          </Stack>
        </ModalDialog>
      </Modal>
    </Fragment>
  );
}
