import {
  Alert,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import { useCallback, useState } from 'react';
import config from '../../../../config';
import axios from '../../../../utils/axios';
// Slices
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import useAuth from 'hooks/useAuth';
import { FPOrganizationalNode, FPRank } from '../../../../@types/department';
import ConfirmModal from '../common/ConfirmModal';
import { JoyDatePicker } from '../common/JoyDatePicker';

type AgentFileCreateModalProps = {
  open: boolean;
  onClose: (refreshRanks?: boolean) => void;
  ranks?: FPRank[] | null;
  organizationalNodes?: FPOrganizationalNode[] | null;
};

export default function AgentFileCreateModal({
  open,
  onClose,
  ranks,
  organizationalNodes,
}: AgentFileCreateModalProps) {
  const { user } = useAuth();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [rankId, setRankId] = useState<string>('');
  const [organizationalNodePath, setOrganizationalNodePath] =
    useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [isTrainer, setIsTrainer] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [entryDate, setEntryDate] = useState<Dayjs | null>(dayjs());

  // Confirm modal
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState('');
  const [confirmModalMessage, setConfirmModalMessage] = useState('');

  const handleConfirmModalCancel = () => {
    setOpenConfirmModal(false);
  };

  const handleConfirmModalConfirm = () => {
    handleCreateAgentFile();
  };

  const handleCreateAgentFile = useCallback(async () => {
    setIsUpdating(true);
    try {
      await axios.post(`${config.api_url}/departments/agents/files`, {
        params: {
          firstName,
          lastName,
          rankId,
          organizationalNodePath,
          position,
          entryDate: entryDate?.toISOString(),
          isTrainer,
        },
      });
      setOpenConfirmModal(false);
      onClose(true);
    } catch (error) {
      console.error(error);
      setError(error as string);
    }
    setIsUpdating(false);
  }, [
    onClose,
    firstName,
    lastName,
    rankId,
    organizationalNodePath,
    position,
    entryDate,
    isTrainer,
  ]);

  const handleCreateAgentFilePreflight = useCallback(async () => {
    if (
      organizationalNodePath.indexOf(
        user?.agentFile?.organizationalNodePath
      ) !== 0
    ) {
      setConfirmModalTitle(`Unité organizationnelle hors d'accès`);
      setConfirmModalMessage(
        "Vous êtes sur le point de créer ce dossier dans une unité organisationnelle à laquelle nous n'avez pas accès. Il ne vous sera pas possible d'y accéder après sa création. Vous aurez besoin d'un superviseur ayant accès à l'unité organisationnelle cible pour regagner l'accès. Êtes-vous sûr de vouloir continuer?"
      );
      setOpenConfirmModal(true);
    } else {
      handleCreateAgentFile();
    }
  }, [organizationalNodePath, user, handleCreateAgentFile]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="lg"
        sx={{
          minWidth: '50%',
        }}
      >
        <ConfirmModal
          title={confirmModalTitle}
          description={confirmModalMessage}
          open={openConfirmModal}
          onCancel={handleConfirmModalCancel}
          onConfirm={handleConfirmModalConfirm}
          confirmText="Confirmer"
          showLoading={isUpdating}
        />
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        {error && (
          <Alert variant="soft" color="danger">
            {error}
          </Alert>
        )}
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<AddIcon />}
        >
          Nouveau dossier
        </Typography>
        <Divider />
        <Stack spacing={2} sx={{ p: 2 }}>
          <Stack direction="row" spacing={2}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Nom de famille</FormLabel>
              <Input
                placeholder="Nom de famille"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Prénom</FormLabel>
              <Input
                placeholder="Prénom"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </FormControl>
          </Stack>
          <FormControl>
            <FormLabel>Date de début de service</FormLabel>
            <JoyDatePicker
              value={entryDate}
              onChange={(e) => setEntryDate(e)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Grade</FormLabel>
            <Select
              placeholder="Sélectionner un grade"
              slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
              onChange={(e, value) => {
                if (e && value) setRankId(value);
              }}
              value={rankId}
            >
              {ranks?.map((rank) => (
                <Option key={rank.objectId} value={rank.objectId}>
                  {rank.name}
                </Option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Unité organisationnelle</FormLabel>
            <Select
              placeholder="Sélectionner une unité organisationnelle"
              slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
              onChange={(e, value) => {
                if (e && value) setOrganizationalNodePath(value);
              }}
              value={organizationalNodePath}
            >
              {organizationalNodes?.map((organizationalNode) => (
                <Option
                  key={organizationalNode.id}
                  value={organizationalNode.path}
                  sx={{
                    pl: `${20 * organizationalNode.path.split('.').length}px`,
                  }}
                >
                  {organizationalNode.name}
                </Option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Poste / Position</FormLabel>
            <Input
              placeholder="Directeur, Sergent de relève, etc..."
              value={position}
              onChange={(e) => {
                setPosition(e.target.value);
              }}
            />
            <FormHelperText>
              Le poste / position sert à préciser le rôle d'un gradé dans le
              département. Par example, à la Sûreté du Québec, un Capitaine peux
              avoir le rôle de Directeur d'une division. On inscrirait donc
              "Directeur de la division ABC" ici.
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>Cet agent est-il formateur?</FormLabel>
            <Checkbox
              label="Oui, cet agent est formateur"
              checked={isTrainer}
              onChange={(e) => {
                setIsTrainer(e.target.checked);
              }}
            />
          </FormControl>
        </Stack>
        <Divider />
        <Stack
          spacing={2}
          direction="row"
          justifyContent="end"
          sx={{ paddingTop: '24px' }}
        >
          <Button
            color="danger"
            variant="soft"
            onClick={() => onClose()}
            disabled={isUpdating}
          >
            Annuler
          </Button>
          <Button
            variant="solid"
            color="primary"
            loading={isUpdating}
            onClick={handleCreateAgentFilePreflight}
          >
            Ouvrir le dossier
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
