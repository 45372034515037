import { createSlice } from '@reduxjs/toolkit';
// utils
import { Application } from '../../@types/application';
import config from '../../config';
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type ApplicationsState = {
  isLoading: boolean;
  error: boolean;
  applications?: Application[] | null;
};

const initialState: ApplicationsState = {
  isLoading: false,
  error: false,
  applications: null,
};

const slice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // EMPTY PROFILE
    emptyApplications(state) {
      state.isLoading = false;
      state.applications = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getApplicationsSuccess(state, action) {
      state.isLoading = false;
      state.applications = action.payload;
    },
  },
});

// Actions
// ----------------------------------------------------------------------

export function getApplications(authToken: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await axios.get(`${config.api_url}/applications`);
      dispatch(slice.actions.getApplicationsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function emptyApplications() {
  return () => {
    dispatch(slice.actions.emptyApplications());
  };
}

// Reducer
export default slice.reducer;
