import {
  faAdd,
  faChevronRight,
  faHouse,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Breadcrumbs, Button, Link, Stack, Typography } from '@mui/joy';
import { fetchAgentFiles } from 'api/departments/agentFiles';
import AgentFileCreateModal from 'pages/app/components/File/AgentFileCreateModal';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDepartmentsOrganizationalUnits } from 'reducers/slices/departmentsOrganizationalUnits';
import { getDepartmentsRanks } from '../../../../reducers/slices/departmentsRanks';
import {
  RootState,
  useDispatch,
  useSelector,
} from '../../../../reducers/store';
import AgentFilesTable from '../../components/File/AgentFilesTable';
import ColorSchemeToggle from '../../components/Main/ColorSchemeToggle';

export default function FilesList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [agentsFiles, setAgentsFiles] = useState<any[]>([]);
  const [agentFilesOrder, setAgentFilesOrder] = useState<'asc' | 'desc'>('asc');
  const [agentFilesPage, setAgentFilesPage] = useState(1);
  const [agentFilesRowsPerPage] = useState(25);
  const [agentFilesTotal, setAgentFilesTotal] = useState(0);
  const [agentRankFilterId, setAgentRankFilterId] = useState<string>();
  const [ouFilterId, setOuFilterId] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  const { ranks, isLoading: ranksLoading } = useSelector(
    (state: RootState) => state.departmentsRanks
  );

  const { departmentsOrganizationalUnits, isLoading: ousLoading } = useSelector(
    (state: RootState) => state.departmentsOrganizationalUnits
  );

  useEffect(() => {
    dispatch(getDepartmentsRanks());
    dispatch(getDepartmentsOrganizationalUnits());
  }, [dispatch]);

  const [createAgentFileModalOpen, setCreateAgentFileModalOpen] =
    useState(false);

  // Modal opening handlers
  const handlecreateAgentFileModalOpen = () => {
    setCreateAgentFileModalOpen(true);
  };

  const handleCreateAgentFileModalClose = (refreshFiles?: boolean) => {
    setCreateAgentFileModalOpen(false);
    if (refreshFiles) fetchFiles();
  };

  const handleRowClick = (id: string) => {
    navigate(`/app/department/files/${id}`);
  };

  const fetchFiles = useCallback(async () => {
    setIsLoading(true);
    const { total, list } = await fetchAgentFiles(
      agentFilesPage,
      agentFilesRowsPerPage,
      agentRankFilterId,
      ouFilterId,
      agentFilesOrder
    );
    setAgentsFiles(list);
    setAgentFilesTotal(total);
    setIsLoading(false);
  }, [
    agentFilesPage,
    agentFilesRowsPerPage,
    agentRankFilterId,
    ouFilterId,
    agentFilesOrder,
  ]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  return (
    <Box
      component="main"
      className="MainContent"
      sx={(theme) => ({
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
          sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100dvh',
        gap: 1,
        bgcolor: 'background.body',
      })}
    >
      <AgentFileCreateModal
        open={createAgentFileModalOpen}
        onClose={handleCreateAgentFileModalClose}
        ranks={ranks}
        organizationalNodes={departmentsOrganizationalUnits}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<FontAwesomeIcon icon={faChevronRight} />}
          sx={{
            '--Breadcrumbs-gap': '1rem',
            '--Icon-fontSize': '16px',
            fontWeight: 'lg',
            color: 'neutral.400',
            px: 0,
          }}
        >
          <Link
            underline="none"
            color="neutral"
            fontSize="inherit"
            onClick={() => navigate('/app')}
            aria-label="Home"
          >
            <FontAwesomeIcon icon={faHouse} />
          </Link>
          <Link
            underline="hover"
            color="neutral"
            fontSize="inherit"
            onClick={() => navigate('/app')}
          >
            Tableau de bord
          </Link>
          <Typography fontSize="inherit" variant="soft" color="primary">
            Dossiers
          </Typography>
        </Breadcrumbs>
        <ColorSchemeToggle
          sx={{ ml: 'auto', display: { xs: 'none', md: 'inline-flex' } }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          my: 1,
          gap: 1,
          flexWrap: 'wrap',
          '& > *': {
            minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
            flexGrow: 1,
          },
        }}
      >
        <Stack>
          <Typography level="h1" fontSize="xl4">
            Dossiers
          </Typography>
          <Typography level="body2">{agentFilesTotal} dossiers</Typography>
        </Stack>
        <Box sx={{ flex: 999 }} />
        <Box sx={{ display: 'flex', gap: 1, '& > *': { flexGrow: 1 } }}>
          <Button
            variant="outlined"
            color="neutral"
            startDecorator={<FontAwesomeIcon icon={faAdd} />}
            onClick={handlecreateAgentFileModalOpen}
            disabled={isLoading || ranksLoading || ousLoading}
          >
            Ouvrir un dossier
          </Button>
        </Box>
      </Box>
      <AgentFilesTable
        agentFiles={agentsFiles}
        order={agentFilesOrder}
        setOrder={setAgentFilesOrder}
        isLoading={isLoading}
        ranks={ranks}
        departmentsOrganizationalUnits={departmentsOrganizationalUnits}
        currentPage={agentFilesPage}
        setCurrentPage={setAgentFilesPage}
        agentRankFilterId={agentRankFilterId}
        setAgentRankFilterId={setAgentRankFilterId}
        ouFilterId={ouFilterId}
        setOuFilterId={setOuFilterId}
        onRowClick={handleRowClick}
        rowsPerPage={agentFilesRowsPerPage}
        total={agentFilesTotal}
      />
    </Box>
  );
}
