import config from '../../../config';
import axios from '../../../utils/axios';

export async function listMyExpenses(
  page: number,
  limit: number,
  order: 'asc' | 'desc' = 'desc'
): Promise<{ total: number; list: any[]; totalPending: number }> {
  const { data } = await axios.get(
    `${config.api_url}/me/file/expenses?page=${page}&limit=${limit}&orderBy=createdAt&order=${order}`
  );
  return data;
}

export async function addExpense(
  amount: number,
  justificationFilePath: string,
  expenseCategory: string
) {
  const { data } = await axios.post(`${config.api_url}/me/file/expenses`, {
    amount,
    justificationFilePath,
    expenseCategory,
  });

  return data;
}
