// Slices
import { faList } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CircularProgress,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Table,
  Typography,
} from '@mui/joy';
import { fetchAgentFileDutyLogs } from 'api/departments/agentFiles';
import { useCallback, useEffect, useState } from 'react';

type AgentFileDutyLogsTableModalProps = {
  agentFile?: any;
  open: boolean;
  onClose: () => void;
};

export default function AgentFileDutyLogsTableModal({
  agentFile,
  open,
  onClose,
}: AgentFileDutyLogsTableModalProps) {
  const [fileDutyLogs, setFileDutyLogs] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getLogs = useCallback(async () => {
    if (agentFile) {
      setIsLoading(true);
      const logs = await fetchAgentFileDutyLogs(agentFile.objectId);
      setFileDutyLogs(logs);
      setIsLoading(false);
    }
  }, [agentFile]);

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => onClose()}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="alert-dialog-modal-title"
        aria-describedby="alert-dialog-modal-description"
        size="lg"
        sx={{
          minWidth: '50%',
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.body',
          }}
        />
        <Typography
          id="alert-dialog-modal-title"
          component="h2"
          startDecorator={<FontAwesomeIcon icon={faList} color="white" />}
        >
          Journal de service
        </Typography>
        <Divider />
        {isLoading ? (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        ) : fileDutyLogs && fileDutyLogs.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Début</th>
                <th>Fin</th>
                <th>Durée (heures)</th>
                <th>Département</th>
              </tr>
            </thead>
            <tbody>
              {fileDutyLogs &&
                fileDutyLogs.map((dutyLog) => (
                  <tr key={dutyLog.objectId}>
                    <td>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography>
                          {new Date(dutyLog.dutyStart.iso).toLocaleString(
                            'fr-CA',
                            {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                            }
                          )}
                        </Typography>
                      </Stack>
                    </td>
                    <td>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography>
                          {new Date(dutyLog.dutyEnd.iso).toLocaleString(
                            'fr-CA',
                            {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                            }
                          )}
                        </Typography>
                      </Stack>
                    </td>
                    <td>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography>
                          {Math.round(
                            (dutyLog.dutyDuration / 60 + Number.EPSILON) * 100
                          ) / 100}
                        </Typography>
                      </Stack>
                    </td>
                    <td>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography>{dutyLog.dutyJobName}</Typography>
                      </Stack>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          <Typography textAlign="center" sx={{ p: 1 }}>
            Aucun journal de service
          </Typography>
        )}
      </ModalDialog>
    </Modal>
  );
}
