import {
  faChevronRight,
  faEnvelope,
  faFileInvoiceDollar,
  faHouse,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Stack,
  Tab,
  TabList,
  Tabs,
  Typography,
} from '@mui/joy';
import useAuth from 'hooks/useAuth';
import MyExpensesTable from 'pages/app/components/File/Me/MyExpensesTable';
import AlertModal from 'pages/app/components/common/AlertModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flex: '1 1 0px' }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function MyFileView() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [error] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>('0');

  if (!user) return <></>;

  const { agentFile } = user;

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | number | boolean
  ) => {
    setSelectedTab(String(value));
  };

  const myFileTabs = [
    {
      tabTitle: 'Mon compte de dépenses',
      icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
      component: <MyExpensesTable />,
    },
    {
      tabTitle: 'Mes courriels',
      icon: <FontAwesomeIcon icon={faEnvelope} />,
      component: <> </>,
    },
  ];

  return (
    <Box
      component="main"
      className="MainContent"
      sx={(theme) => ({
        px: {
          xs: 2,
          md: 6,
        },
        pt: {
          xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
          sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
          md: 3,
        },
        pb: {
          xs: 2,
          sm: 2,
          md: 3,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100dvh',
        gap: 1,
        bgcolor: 'background.body',
      })}
    >
      {false ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          flex={1}
          width="100%"
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<FontAwesomeIcon icon={faChevronRight} />}
              sx={{
                '--Breadcrumbs-gap': '1rem',
                '--Icon-fontSize': '16px',
                fontWeight: 'lg',
                color: 'neutral.400',
                px: 0,
              }}
            >
              <Link
                underline="none"
                color="neutral"
                fontSize="inherit"
                onClick={() => navigate('/app')}
                aria-label="Home"
              >
                <FontAwesomeIcon icon={faHouse} />
              </Link>
              <Link
                underline="hover"
                color="neutral"
                fontSize="inherit"
                onClick={() => navigate('/app')}
              >
                Tableau de bord
              </Link>
              <Typography fontSize="inherit" variant="soft" color="primary">
                Mon Dossier
              </Typography>
            </Breadcrumbs>
          </Box>
          <Grid container spacing={2}>
            <Grid xs={12} md={3}>
              <Stack spacing={2} divider={<Divider />}>
                <Stack direction="column" spacing={0} alignItems="start">
                  <Typography level="h1" fontSize="xl4">
                    {agentFile
                      ? `${agentFile?.firstName} ${agentFile?.lastName}`
                      : 'Dossier'}
                  </Typography>

                  <Typography level="h6" sx={{ color: '#5e5e5e' }}>
                    #{agentFile?.callSign}
                  </Typography>
                </Stack>
                <Tabs
                  variant="plain"
                  orientation="vertical"
                  value={selectedTab}
                  onChange={handleTabChange}
                  aria-label="me tabs"
                  sx={{
                    borderRadius: 'sm',
                    background: 'transparent',
                    width: '100%',
                  }}
                >
                  <TabList sx={{ background: 'transparent', width: '100%' }}>
                    {myFileTabs.map((tab, tabIndex) => {
                      return (
                        <Tab key={tabIndex} value={`${tabIndex}`}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            {tab.icon}
                            <span>{tab.tabTitle}</span>
                          </Stack>
                        </Tab>
                      );
                    })}
                  </TabList>
                </Tabs>
              </Stack>
            </Grid>
            <Grid xs={12} md={9}>
              <Stack spacing={1}>
                <Stack spacing={2} sx={{ mb: 2 }}>
                  {myFileTabs.map((tab, tabIndex) => {
                    return (
                      <TabPanel
                        value={selectedTab}
                        index={`${tabIndex}`}
                        key={tabIndex}
                      >
                        {tab.component}
                      </TabPanel>
                    );
                  })}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          {/* Modals here */}
          <AlertModal
            open={!!error}
            onClose={() => navigate(-1)}
            title="Erreur"
            message={error}
            closeButtonLabel="Retour"
          />
        </>
      )}
    </Box>
  );
}
