import { Dayjs } from 'dayjs';

import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, FormLabel, Input, InputProps } from '@mui/joy';
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from '@mui/x-date-pickers';
import {
  unstable_useDateField as useDateField,
  UseDateFieldProps,
} from '@mui/x-date-pickers/DateField';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { forwardRef } from 'react';

interface JoyFieldProps extends InputProps {
  label?: React.ReactNode;
  InputProps?: {
    ref?: React.Ref<any>;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
  };
}

type JoyFieldComponent = ((
  props: JoyFieldProps & React.RefAttributes<HTMLDivElement>
) => JSX.Element) & { propTypes?: any };

const JoyField = forwardRef(
  (props: JoyFieldProps, inputRef: React.Ref<HTMLInputElement>) => {
    const {
      disabled,
      id,
      label,
      InputProps: { ref: containerRef } = {},
      ...other
    } = props;

    return (
      <FormControl
        disabled={disabled}
        id={id}
        sx={{ flexGrow: 1 }}
        ref={containerRef}
      >
        <FormLabel>{label}</FormLabel>
        <Input
          disabled={disabled}
          slotProps={{ input: { ref: inputRef } }}
          endDecorator={<FontAwesomeIcon icon={faCalendarAlt} />}
          {...other}
        />
      </FormControl>
    );
  }
) as JoyFieldComponent;

interface JoyDateFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      FieldSection,
      DateValidationError
    > {}

const JoyDateField = (props: JoyDateFieldProps) => {
  const {
    inputRef: externalInputRef,
    slots,
    slotProps,
    ...textFieldProps
  } = props;

  const response = useDateField<Dayjs, typeof textFieldProps>({
    props: textFieldProps,
    inputRef: externalInputRef,
  });

  return <JoyField {...response} />;
};

export const JoyDatePicker = (props: DatePickerProps<Dayjs>) => (
  <DatePicker
    slots={{ field: JoyDateField, ...props.slots }}
    format="YYYY / MM / DD"
    {...props}
  />
);
