import { Typography } from '@mui/joy';
import Backdrop from 'components/common/BackdropImageContainer';

const NotFound = () => {
  return (
    <Backdrop>
      <Typography level="h4">Inexistant</Typography>
      <Typography level="body1">
        Il semble que cette section n'existe pas.
      </Typography>
    </Backdrop>
  );
};

export default NotFound;
