import {
  faBadgeCheck,
  faCircleQuestion,
  faCircleXmark,
  faClock,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faCheck,
  faPause,
  faPersonToDoor,
  faQuestion,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { CertificationType } from '../@types/department';

type ApplicationStatus = 'pending' | 'accepted' | 'rejected';
type AgentFileStatus =
  | 'active'
  | 'terminated'
  | 'suspended'
  | 'departed'
  | 'inactive';

type ExpenseStatus = 'pending' | 'accepted' | 'rejected';

export const ressourceTypeLabel = (r: string) => {
  switch (r) {
    case 'vehicles':
      return 'Véhicules';
    case 'certifications':
      return 'Certifications';
    case 'files':
      return 'Dossiers';
    default:
      return 'Inconnu';
  }
};

export const certificationTypeNameForType = (type: CertificationType) => {
  switch (type) {
    case 'initial':
      return 'certificat';
    case 'renewal':
      return 'reconduction de certificat';
    default:
      return '--';
  }
};

export const labelForAgentField = (field: string) => {
  switch (field) {
    case 'firstName':
      return 'Prénom';
    case 'lastName':
      return 'Nom';
    case 'position':
      return 'Poste';
    case 'currentRank':
      return 'Grade';
    case 'citizenId':
      return 'NAS';
    case 'organizationalUnit':
      return 'Unité organisationnelle';
    case 'organizationalNodePath':
      return 'Unité organisationnelle';
    case 'entryDate':
      return 'Date de début de service';
    case 'status':
      return 'Statut';
    default:
      return 'Inconnu';
  }
};

export const labelForAgentFileStatus = (status: AgentFileStatus) => {
  switch (status) {
    case 'active':
      return 'Actif';
    case 'terminated':
      return 'Mis à pied';
    case 'suspended':
      return 'Suspendu';
    case 'departed':
      return 'Quitté';
    case 'inactive':
      return 'Inactif';
    default:
      return 'Inconnu';
  }
};

export const colorForAgentFileStatus = (status: AgentFileStatus) => {
  switch (status) {
    case 'active':
      return 'success';
    case 'terminated':
      return 'danger';
    case 'suspended':
      return 'warning';
    case 'departed':
      return 'danger';
    case 'inactive':
      return 'warning';
    default:
      return 'neutral';
  }
};

export const iconForAgentFileStatus = (status: AgentFileStatus) => {
  switch (status) {
    case 'active':
      return faBadgeCheck;
    case 'terminated':
      return faCircleXmark;
    case 'suspended':
      return faTriangleExclamation;
    case 'departed':
      return faPersonToDoor;
    case 'inactive':
      return faPause;
    default:
      return faQuestion;
  }
};

export const labelForApplicationStatus = (status: ApplicationStatus) => {
  switch (status) {
    case 'pending':
      return 'En attente';
    case 'accepted':
      return 'Accepté';
    case 'rejected':
      return 'Rejeté';
    default:
      return 'Inconnu';
  }
};

export const colorForApplicationStatus = (status: ApplicationStatus) => {
  switch (status) {
    case 'pending':
      return 'warning';
    case 'accepted':
      return 'success';
    case 'rejected':
      return 'neutral';
    default:
      return 'neutral';
  }
};

export const iconForApplicationStatus = (status: ApplicationStatus) => {
  switch (status) {
    case 'pending':
      return faClock;
    case 'accepted':
      return faBadgeCheck;
    case 'rejected':
      return faCircleXmark;
    default:
      return faCircleQuestion;
  }
};

export const labelForApplicationFormTitle = (status: string) => {
  switch (status) {
    case 'firstName':
      return 'Prénom';
    case 'lastName':
      return 'Nom';
    case 'age':
      return 'Âge';
    case 'experience':
      return 'Expérience';
    case 'expectations':
      return "Ce que je m'attends du département";
    case 'distinctions':
      return 'Ce qui me distingue';
    case 'aboutYou':
      return 'À propos de moi';
    default:
      return status;
  }
};

// Get the string representation of a duration in seconds. If more then 60 seconds, return the minutes. If more then 60 minutes, also return the hours, and so on.
export const stringForDuration = (duration: number) => {
  if (duration < 60) {
    return `${duration} secondes`;
  } else if (duration < 3600) {
    return `${Math.floor(duration / 60)} minutes`;
  } else if (duration < 86400) {
    return `${Math.floor(duration / 3600)} heures`;
  } else {
    return `${Math.floor(duration / 86400)} jours`;
  }
};

export const labelForExpenseCategory = (category: string) => {
  switch (category) {
    case 'fuel':
      return 'Essence';
    case 'repairs':
      return 'Réparations';
    case 'food':
      return 'Nourriture';
    default:
      return 'Inconnu';
  }
};

export const labelForExpenseStatus = (status: ExpenseStatus) => {
  switch (status) {
    case 'pending':
      return 'En attente';
    case 'accepted':
      return 'Accepté';
    case 'rejected':
      return 'Rejeté';
    default:
      return 'Inconnu';
  }
};

export const colorForExpenseStatus = (status: ExpenseStatus) => {
  switch (status) {
    case 'pending':
      return 'warning';
    case 'accepted':
      return 'success';
    case 'rejected':
      return 'danger';
    default:
      return 'neutral';
  }
};

export const iconForExpenseStatus = (status: ExpenseStatus) => {
  switch (status) {
    case 'pending':
      return faClock;
    case 'accepted':
      return faCheck;
    case 'rejected':
      return faCircleXmark;
    default:
      return faCircleQuestion;
  }
};

export const formatNumberForCurrency = (number: number) => {
  return number.toLocaleString('fr-CA', {
    style: 'currency',
    currency: 'CAD',
  });
};
