import { faAdd, faFile } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Chip,
  IconButton,
  Sheet,
  Stack,
  Table,
  Typography,
} from '@mui/joy';
import { listMyExpenses } from 'api/me/file/expenses';
import config from 'config';
import { useCallback, useEffect, useState } from 'react';
import {
  colorForExpenseStatus,
  formatNumberForCurrency,
  iconForExpenseStatus,
  labelForExpenseCategory,
  labelForExpenseStatus,
} from 'utils/strings';
import TableFooter from '../../common/TableFooter';
import MyExpensesAddExpenseModal from './MyExpensesAddExpenseModal';

const tablePadding = 12;

export default function MyExpensesTable() {
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [expenses, setExpenses] = useState<any[]>([]);
  const [expensesPendingAmount, setExpensesPendingAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [addExpenseModalOpen, setAddExpenseModalOpen] = useState(false);

  const handleAddExpenseModalOpen = () => {
    setAddExpenseModalOpen(true);
  };

  const handleAddExpenseModalClose = (newExpense?: any) => {
    if (newExpense) {
      setExpenses((prev) => [newExpense, ...prev]);
      setExpensesPendingAmount((prev) => prev + newExpense.amount);
    }
    setAddExpenseModalOpen(false);
  };

  const fetchExpenses = useCallback(async () => {
    setLoading(true);
    const expenses = await listMyExpenses(page - 1, rowsPerPage);
    setLoading(false);
    setTotalItems(expenses.total);
    setExpenses(expenses.list);
    setExpensesPendingAmount(expenses.totalPending || 0);
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchExpenses();
  }, [fetchExpenses]);

  return (
    <Stack spacing={2}>
      <MyExpensesAddExpenseModal
        open={addExpenseModalOpen}
        onClose={handleAddExpenseModalClose}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack>
          <Typography level="h4">Mes dépenses</Typography>
          <Typography level="body3">
            Vous pouvez soumettre vos dépenses admissibles ici pour obtenir un
            remboursement de votre département.
          </Typography>
        </Stack>
        <Button
          startDecorator={<FontAwesomeIcon icon={faAdd} />}
          variant="outlined"
          color="neutral"
          onClick={handleAddExpenseModalOpen}
        >
          Ajouter une dépense
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography level="body1">Total en attente</Typography>
          <Typography level="h4" color="neutral">
            {formatNumberForCurrency(expensesPendingAmount)}
          </Typography>
        </Stack>
      </Stack>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'md',
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          sx={{
            '--TableCell-headBackground': (theme) =>
              theme.vars.palette.background.level1,
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': (theme) =>
              theme.vars.palette.background.level1,
          }}
        >
          <thead>
            <tr>
              <th style={{ padding: tablePadding }}>Date</th>
              <th style={{ padding: tablePadding }}>Soumis le</th>
              <th style={{ padding: tablePadding }}>Montant</th>
              <th style={{ padding: tablePadding }}>Catégorie</th>
              <th style={{ padding: tablePadding }}>Statut</th>
              <th style={{ padding: tablePadding }}>Just.</th>
            </tr>
          </thead>
          <tbody>
            {expenses.map((expense) => (
              <tr key={expense.id}>
                <td style={{ paddingLeft: tablePadding }}>
                  {expense.expenseDate
                    ? new Date(expense.expenseDate).toLocaleDateString()
                    : '--'}
                </td>
                <td style={{ paddingLeft: tablePadding }}>
                  {expense.createdAt
                    ? new Date(expense.createdAt).toLocaleDateString()
                    : '--'}
                </td>
                <td style={{ paddingLeft: tablePadding }}>
                  {formatNumberForCurrency(expense.amount || 0)}
                </td>
                <td style={{ paddingLeft: tablePadding }}>
                  {labelForExpenseCategory(expense.expenseCategory)}
                </td>
                <td style={{ paddingLeft: tablePadding }}>
                  {
                    <Chip
                      color={colorForExpenseStatus(expense.status)}
                      startDecorator={
                        <FontAwesomeIcon
                          icon={iconForExpenseStatus(expense.status)}
                        />
                      }
                      variant="soft"
                    >
                      {labelForExpenseStatus(expense.status)}
                    </Chip>
                  }
                </td>
                <td style={{ paddingLeft: tablePadding }}>
                  {
                    <IconButton
                      size="sm"
                      variant="soft"
                      onClick={() => {
                        window.open(
                          `${config.api_url}/assets/uploadedFiles/${expense?.attachmentsIds?.[0]}`,
                          '_blank',
                          'noreferrer'
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faFile} />
                    </IconButton>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <TableFooter
        currentPage={page}
        total={totalItems}
        rowsPerPage={rowsPerPage}
        handleNextPage={() => setPage((prev) => prev + 1)}
        handlePreviousPage={() => setPage((prev) => prev - 1)}
        loading={loading}
      />
    </Stack>
  );
}
