import { Divider, LinearProgress, Stack } from '@mui/joy';
import { fetchAgentFileHistory } from 'api/departments/agentFiles';
import { useCallback, useEffect, useState } from 'react';
import { User } from '../../../../@types/user';
import AgentFileHistoryCard from './AgentFileHistoryCard';

type AgentFileHistoryListProps = {
  agentFile?: User;
};

export default function AgentFileHistoryList({
  agentFile,
}: AgentFileHistoryListProps) {
  const [agentFileFileHistory, setAgentFileFileHistory] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getHistory = useCallback(async () => {
    if (agentFile) {
      setIsLoading(true);
      try {
        const history = await fetchAgentFileHistory(agentFile.objectId);
        setAgentFileFileHistory(history);
      } catch (error: any) {
        console.error(error);
      }
    }
    setIsLoading(false);
  }, [agentFile]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  return isLoading ? (
    <LinearProgress />
  ) : (
    <Stack spacing={1} overflow="scroll" divider={<Divider />}>
      {agentFileFileHistory?.map((history) => (
        <AgentFileHistoryCard key={history.objectId} history={history} />
      ))}
    </Stack>
  );
}
